import AccountHeader from "../account-header";
import { React, dtos, serviceClient, observable, observer, ui, moment, Link, _, makeObservable } from "../common";
import PageTitle from "../page-title";

interface Props {
    accountId: string;
}

@observer
export default class UserActivity extends React.Component<Props> {
    
    constructor(props) {
        super(props);
        makeObservable(this);
    }
    
    render() {
        let users = [{
            name: "Patrick Greene (me)",
            status: "On Call",
            ext: "4000",
            icon: "fa fa-phone-volume",
            call: "+15556667777",
            rowClass: "blf-active",
            me: true
        },{
            name: "Una Malan",
            ext: "4001",
            icon: "fa fa-phone-slash",
            status: "Idle",
            rowClass: "blf-idle"
        },{
            name: "Micah Turnquist",
            status: "Ringing",
            ext: "4002",
            icon: "fa fa-phone-volume",
            call: "+18887776666",
            rowClass: "blf-ringing"
        },{
            name: "Andrew Forrest",
            ext: "4001",
            icon: "fa fa-phone-slash",
            status: "Idle",
            rowClass: "blf-idle"
        },{
            name: "Evelyn Bowers",
            status: "Ringing",
            ext: "4002",
            icon: "fa fa-phone-volume",
            call: "+18887776666",
            rowClass: "blf-ringing"
        },{
            name: "Jim Bachert",
            status: "On Call",
            ext: "4000",
            icon: "fa fa-phone-volume",
            call: "+15556667777",
            rowClass: "blf-active"
        }];

        return (
            <div>
                <PageTitle title="Activity" />
                <AccountHeader title="Activity" icon="fa fa-file-chart-line" />
                <ui.Table dataSource={users} rowClassName={(rec) => rec.rowClass}>
                    <ui.Table.Column dataIndex="name" title="Name" render={(tex, rec:any) => <div>
                        <i className={rec.icon} style={{marginRight:5}} />{tex}
                    </div>} />
                    <ui.Table.Column dataIndex="ext" title="Extension" />
                    <ui.Table.Column dataIndex="status" title="Status" />
                    <ui.Table.Column dataIndex="call" title="Call" />
                    <ui.Table.Column dataIndex="" render={(text, rec:any) => !rec.me && <ui.Button type="primary">Call</ui.Button>} />
                </ui.Table>
            </div>);
    }
}