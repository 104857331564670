import { React } from "./common";
import { PageHeader } from "antd";

interface Props {
    visible: boolean;
    children: any;
}

export default class AutoFocus extends React.Component<Props> {
    div = React.createRef<HTMLDivElement>();

    componentDidMount() {
        if (this.props.visible) {
            this.setFocus();
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.visible && this.props.visible != prevProps.visible) {
            this.setFocus();
        }
    }

    setFocus() {
        setTimeout(() => {
            var el = this.div.current.querySelector("input, select") as HTMLElement;
            if (el) {
                el.focus();
            }
        }, 200);
    }

    render() {
        return <div ref={this.div}>{this.props.children}</div>;
    }
}