import { React, ui, dtos, serviceClient, observer, observable, shared, _, makeObservable } from "./common";

interface Props {
    title: string;
    icon: string;
}

@observer
export default class AccountHeader extends React.Component<Props> {
    @observable test = "";
    
    constructor(props) {
        super(props);
        makeObservable(this);
    }
    
    render() {
        var min = shared.collapsed;
        if (min) return <div />;

        return (
            <ui.Row>
                <ui.Col span={18}>
                    <ui.Breadcrumb>
                        <ui.Breadcrumb.Item>{shared.accountName}</ui.Breadcrumb.Item>
                    </ui.Breadcrumb>
                    <ui.Typography.Title><i className={this.props.icon} /> {this.props.title}</ui.Typography.Title>
                </ui.Col>
            </ui.Row>
        );
    }
}