import { React, ui, dtos, observable, observer, selectFilter, serviceClient, shared, makeObservable, cache } from "../common";
import AutoFocus from "../autofocus";
import ValueEditor from "../flows/value-editor";
import AutoComplete from "../autocomplete";

interface Props {
    visible: boolean;
    defaultParentCustomerId?: string;
    onClosed: () => void;
    onCreated: (cust: dtos.CustomerInfo) => void;
}

@observer
export default class NewCustomer extends React.Component<Props> {
    @observable name = "";
    @observable parentCustomerId;
    @observable data = new dtos.Struct();
    @observable dataFields: Array<dtos.CustomerDataField> = [];
    @observable loaded = false;
    @observable referenceId = "";

    constructor(props) {
        super(props);
        makeObservable(this);
    }
    
    async componentDidMount() {
        let ss = await cache.getSystemSettings();
        this.dataFields = ss.customerFields;
        this.parentCustomerId = this.props.defaultParentCustomerId;
        this.initData();
    }

    initData() {
        this.data = new dtos.Struct();
        for (let field of this.dataFields) {
            if (!this.data[field.name]) {
                this.data[field.name] = field.defaultValue || new dtos.Value({
                    stringValue: ""
                });
            }
        }
        this.loaded = true;
    }

    async save(ev: React.FormEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        let request = new dtos.NewCustomer({
            accountId: shared.accountId,
            name: this.name,
            parentCustomerId: this.parentCustomerId,
            referenceId: this.referenceId,
            data: this.data
        });
        let newCustomer = await serviceClient.post(request);
        this.props.onCreated(newCustomer);
        this.props.onClosed();
    }

    render() {
        let { visible, onClosed } = this.props;
        return (
            <ui.Modal visible={visible} title="New Customer" onCancel={onClosed} onOk={(ev) => this.save(ev)}>
                <ui.Form layout="vertical" onSubmitCapture={(ev) => this.save(ev)}>
                    {this.loaded && <div>
                        <button type="submit" style={{ position: "absolute", top: -9999, left: -9999 }}>Submit</button>
                        <ui.Form.Item label="Name">
                            <AutoFocus visible={visible}>
                                <ui.Input value={this.name} onChange={ev => this.name = ev.target.value} />
                            </AutoFocus>
                        </ui.Form.Item>
                        <ui.Form.Item label="Parent Customer">
                            <AutoComplete type={dtos.ValueTypes.Customer} value={this.parentCustomerId} onChanged={v => this.parentCustomerId = v} placeholder="Search for a parent customer" />
                        </ui.Form.Item>
                        <ui.Form.Item label="Reference ID">
                            <ui.Input value={this.referenceId} onChange={ev => this.referenceId = ev.target.value} />
                        </ui.Form.Item>
                        {this.dataFields.map(f =>
                            <ui.Form.Item label={f.name} key={f.id}>
                                <ValueEditor accountId={shared.accountId} field={f} valueType={f.type} value={this.data[f.name]} />
                            </ui.Form.Item>)}
                    </div>}
                </ui.Form>
            </ui.Modal>
        );
    }
}