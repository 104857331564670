import { React, serviceClient, dtos, observer, observable, shared, ui, _, Link, splitIntoWords, selectFilter, makeObservable, cache, getEndpointIcon } from "../common";
import ValueEditor from "../flows/value-editor";
import TagsEditor from "../tags-editor";
import AutoComplete from "../autocomplete";

interface Props {
    customerId?: string;
    type?:dtos.EndpointTypes;
    children?: React.ReactNode;
}

@observer
export default class EndpointList extends React.Component<Props> {
    @observable endpoints: Array<dtos.EndpointInfo> = [];
    @observable pagination: any = { position: "both", total: 0 };
    @observable sort: any = {};
    @observable filter: any = {};
    @observable searching = false;
    @observable availableCountries: Array<dtos.AvailableCountryInfo> = [];
    @observable endpointFields: Array<dtos.EndpointDataField> = [];
    @observable loading = true;
    @observable pageSize = 20;
    @observable dataFilters = new dtos.Struct();

    @observable flows: Array<dtos.FlowInfo> = [];

    @observable selectedEndpointIds: Array<string> = [];
    @observable tags: Array<dtos.Tag> = [];
    @observable allTags: Array<dtos.Tag> = [];

    @observable showNumberTester = false;
    @observable numbersToTest: dtos.EndpointInfo[] = [];

    @observable ss: dtos.SystemSettingsInfo = null;

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    async componentDidMount() {
        this.availableCountries = await cache.getCountries();
        let systemSettings = await cache.getSystemSettings();
        this.ss = systemSettings;
        this.allTags = systemSettings.tags;
        this.endpointFields = systemSettings.endpointFields;
        this.refresh(1, {});
    }

    async refresh(page = this.pagination.page || 1, sort = this.sort) {
        this.loading = true;
        let request = new dtos.ListEndpoints({
            accountIds: [shared.accountId],
            customerIds: this.props.customerId ? [this.props.customerId] : (this.filter.customerId ? [this.filter.customerId] : null),
            nameFilter: this.filter.name,
            flowIds: this.filter.flowId ? [this.filter.flowId] : null,
            dataFilters: Object.keys(this.dataFilters).map(fn => `${fn}=${this.dataFilters[fn].stringValue}`),
            countPerPage: this.pageSize,
            page: page - 1,
            sortField: sort.field,
            flowState: this.filter.flowState,
            sortOrder: sort.order,
            type: this.props.type || this.filter.type,
            shallowParent: this.filter.shallowParent,
            userMode: this.filter.userMode,
            tagIds: this.tags.length > 0 ? this.tags.map(t => t.id) : null,
            sipUserName: this.filter.sipUserName,
            simplifiedPaging: true
        });
        let response = await serviceClient.get(request);
        let currentlySelected = this.endpoints.filter(ep => this.selectedEndpointIds.indexOf(ep.id) >= 0);
        this.endpoints = [...currentlySelected, ...response.items.filter(ep => this.selectedEndpointIds.indexOf(ep.id) < 0)];
        let pager = { ...this.pagination };
        pager.current = page;
        pager.total = page * this.pageSize + (response.hasMorePages ? 1 : 0)
        pager.pageSize = request.countPerPage;
        pager.showSizeChanger = true;
        pager.pageSizeOptions = ["10", "20", "50", "100", "250"];
        pager.onShowSizeChange = (c, v) => this.pageSize = v;
        this.sort = sort;
        this.pagination = pager;
        this.loading = false;

    }

    async endpointPost(request) {
        let newEndpoint = await serviceClient.post(request) as any;
        window.location.hash = "/endpoints/" + newEndpoint.id;
    }

    getEndpointDataText(cust: dtos.EndpointInfo, f: dtos.EndpointDataField) {
        var val = cust.data[f.name] as dtos.Value;
        if (!val) return <span />;
        return <ValueEditor readOnly field={f} value={val} accountId={shared.accountId} valueType={f.type} />;
    }

    async deleteEndpoints() {
        this.loading = true;
        try {
            for (let id of this.selectedEndpointIds) {
                let request = new dtos.DeleteEndpoint({
                    endpointId: id
                });
                await serviceClient.delete(request);
            }
        } finally {
            this.loading = false;
        }
        this.selectedEndpointIds = [];
        this.refresh();
    }

    checkFilterSubmit(ev: React.KeyboardEvent) {
        if (ev.which == 13) {
            this.refresh();
        }
    }

    async deleteEndpoint(ep: dtos.EndpointInfo) {
        await serviceClient.delete(new dtos.DeleteEndpoint({
            endpointId: ep.id
        }));
        let idx = this.endpoints.indexOf(ep);
        this.endpoints.splice(idx, 1);
        this.forceUpdate();
        ui.message.info(ep.displayName + " was deleted");
    }

    render() {
        const rowSelection = {
            selectedRowKeys: this.selectedEndpointIds,
            onChange: keys => this.selectedEndpointIds = keys
        };

        function getValue(rec: dtos.EndpointInfo, f: dtos.EndpointDataField) {
            rec.data[f.name] = rec.data[f.name] || new dtos.Value();
            return rec.data[f.name];
        }

        return (
            <div>
                <ui.Form layout="inline" style={{ marginBottom: 8 }}>
                    <ui.Form.Item>
                        <ui.Input onKeyPress={ev => this.checkFilterSubmit(ev)} placeholder="Filter by name" value={this.filter.name || ""} onChange={ev => this.filter.name = ev.target.value} />
                    </ui.Form.Item>
                    <ui.Form.Item>
                        <ui.Select onInputKeyDown={ev => this.checkFilterSubmit(ev)} showSearch filterOption={selectFilter} style={{ width: 175 }} dropdownMatchSelectWidth={false} placeholder="Filter by type" value={this.filter.type || undefined} onChange={v => this.filter.type = v}>
                            {Object.keys(dtos.EndpointTypes).map(t => <ui.Select.Option key={t} value={t}><i className={getEndpointIcon(t as dtos.EndpointTypes)} /> {splitIntoWords(t)}</ui.Select.Option>)}
                        </ui.Select>
                    </ui.Form.Item>
                    {this.filter.type == dtos.EndpointTypes.User && <ui.Form.Item>
                        <ui.Select onInputKeyDown={ev => this.checkFilterSubmit(ev)} showSearch filterOption={selectFilter} style={{ width: 175 }} dropdownMatchSelectWidth={false} placeholder="Filter by user mode" value={this.filter.userMode || undefined} onChange={v => this.filter.userMode = v}>
                            {Object.keys(dtos.UserModes).map(t => <ui.Select.Option key={t} value={t}>{splitIntoWords(t)}</ui.Select.Option>)}
                        </ui.Select>
                    </ui.Form.Item>}
                    {this.filter.type == dtos.EndpointTypes.User && this.filter.userMode == dtos.UserModes.Sip &&
                        <ui.Form.Item><ui.Input placeholder="SIP User Name" value={this.filter.sipUserName} onChange={ev => { this.filter.sipUserName = ev.target.value; this.forceUpdate() }} />
                        </ui.Form.Item>}
                    {!this.props.customerId && <ui.Form.Item>
                        <AutoComplete type={dtos.ValueTypes.Customer} includeNotAssigned={!shared.isCustomer()} value={this.filter.customerId} onSearch={() => this.refresh()} onChanged={v => this.filter.customerId = v} placeholder="Filter by customer" />
                    </ui.Form.Item>}
                    {this.filter.customerId && this.filter.customerId != "_" && <ui.Form.Item style={{ marginLeft: -8 }}>
                        <ui.Tooltip title="Select this option to show only direct children of the specified customer"><ui.Button type={this.filter.shallowParent ? "primary" : "default"} onClick={() => { this.filter.shallowParent = !this.filter.shallowParent; this.refresh() }}><i className="fa fa-sitemap" /></ui.Button></ui.Tooltip>
                    </ui.Form.Item>}
                    <ui.Form.Item>
                        <AutoComplete type={dtos.ValueTypes.Flow} includeNotAssigned={true} static={true} value={this.filter.flowId} onSearch={() => this.refresh()} onChanged={v => this.filter.flowId = v} placeholder="Filter by flow" />
                    </ui.Form.Item>

                    <ui.Form.Item>
                        <TagsEditor ss={this.ss} placeholder="Filter by tag" tags={this.tags} />
                    </ui.Form.Item>
                    <ui.Form.Item>
                        <ui.Button.Group>
                            <ui.Button type="primary" onClick={() => this.refresh()}>Search</ui.Button>
                            <ui.Button type="default" onClick={() => { this.filter = {}; this.tags = []; this.selectedEndpointIds = []; this.dataFilters = new dtos.Struct(); this.refresh(); }}>Clear</ui.Button>
                        </ui.Button.Group>
                    </ui.Form.Item>
                </ui.Form>

                <ui.Table className="hide-pagination-buttons" rowSelection={rowSelection} loading={this.loading} dataSource={this.endpoints} rowKey="id" pagination={this.pagination} onChange={(p, f, s) => this.refresh(p.current, s)}>
                    <ui.Table.Column title="Name" dataIndex="displayName" render={(text, rec: dtos.EndpointInfo) =>
                        <span><i style={{ marginRight: 5 }} className={getEndpointIcon(rec.type)} />
                            {!rec.doNotTouchPhoneNumber && <Link to={`/endpoints/${rec.id}`}>{rec.displayName || "(No Name)"}</Link>}
                            {rec.doNotTouchPhoneNumber && <span>{rec.displayName}</span>}
                            {rec.type == dtos.EndpointTypes.User && rec.userMode == dtos.UserModes.SoftPhone && <small>&nbsp;(Soft Phone)</small>}
                            {rec.type == dtos.EndpointTypes.User && rec.userMode == dtos.UserModes.Sip && <small>&nbsp;(SIP)</small>}
                            {rec.type == dtos.EndpointTypes.User && rec.userMode == dtos.UserModes.Flow && <small>&nbsp;(Flow)</small>}
                            {rec.type == dtos.EndpointTypes.User && rec.userMode == dtos.UserModes.DataOnly && <small>&nbsp;(Data)</small>}
                            {(rec.type == dtos.EndpointTypes.PhoneNumber || rec.type == dtos.EndpointTypes.FaxNumber) && rec.name != null && rec.name.length > 0 && <small>&nbsp;({rec.name})</small>}
                            {rec.type == dtos.EndpointTypes.PhoneNumber && rec.disableSms && <small>&nbsp;(SMS disabled)</small>}
                            {(rec.type == dtos.EndpointTypes.PhoneNumber || rec.type == dtos.EndpointTypes.FaxNumber) && rec.doNotTouchPhoneNumber && <small>&nbsp; (LOCKED)</small>}
                            {(rec.type === dtos.EndpointTypes.PhoneNumber && rec.isVirtualPhoneNumber && <small>&nbsp; (Virtual)</small>)}
                            {rec.type == dtos.EndpointTypes.FaxNumber && rec.documoId && <small>&nbsp;(Documo Fax)</small>}
                            {rec.type == dtos.EndpointTypes.FaxNumber && !rec.documoId && rec.phoneNumberSid && <small>&nbsp;(Twilio Fax)</small>}
                            {rec.type == dtos.EndpointTypes.User && rec.avatarUrl && <ui.Avatar style={{ marginLeft: 8 }} src={rec.avatarUrl} size="small" />}
                        </span>} />
                    <ui.Table.Column title="Type" dataIndex="type" render={(text, rec: dtos.EndpointInfo) => splitIntoWords(rec.type)} />
                    <ui.Table.Column dataIndex="customerBreadcrumb" title="Customer" render={(text, rec: dtos.EndpointInfo) =>
                        <div>
                            <ui.Breadcrumb>
                                {rec.customerBreadcrumb.map((bc, i) =>
                                    <ui.Breadcrumb.Item key={bc.id}>
                                        <Link to={`/customers/${bc.id}`}>{bc.name}</Link>
                                        {rec.customerId == bc.id && <a style={{ marginLeft: 5 }} onClick={() => { this.filter = { customerId: rec.customerId }; this.forceUpdate(); this.refresh(); }}><small><i className="fa fa-filter" /></small></a>}

                                    </ui.Breadcrumb.Item>)}
                            </ui.Breadcrumb>
                        </div>
                    } />

                    <ui.Table.Column title="Flow" dataIndex="voiceFlowName" render={(text, rec: dtos.EndpointInfo) => <div>
                        {rec.flowSchedule == dtos.EndpointFlowSchedules.Scheduled && <span>Scheduled</span>}
                        {rec.flowSchedule == dtos.EndpointFlowSchedules.Always && rec.flowId && <Link to={`/flows/${rec.flowId}`}>{rec.flowName}</Link>}</div>} />
                    <ui.Table.Column title="Tags" render={(text, rec: dtos.EndpointInfo) =>
                        <div>
                            {rec.tags.map(t => <ui.Tag key={t.id} color={t.color.toLowerCase()}>{t.name}</ui.Tag>)}
                        </div>} />
                    {this.endpointFields.filter(f => f.showInSearch).map(f => <ui.Table.Column key={f.name} dataIndex={f.name} title={splitIntoWords(f.name)} render={(text, rec: dtos.EndpointInfo) =>
                        <span>{this.getEndpointDataText(rec, f)}</span>} />)}
                     {this.props.children}
                </ui.Table>
            </div>
        );
    }
}