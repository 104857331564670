interface AIVoice {
    gender: string,
    localName: string,
    name: string,
    shortName: string,
    locale: string,
    styleList: string[]
}

const voices: AIVoice[] = [
    {
        "gender": "Female",
        "localName": "Adri",
        "name": "Microsoft Server Speech Text to Speech Voice (af-ZA, AdriNeural)",
        "shortName": "af-ZA-AdriNeural",
        "locale": "af-ZA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Willem",
        "name": "Microsoft Server Speech Text to Speech Voice (af-ZA, WillemNeural)",
        "shortName": "af-ZA-WillemNeural",
        "locale": "af-ZA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "መቅደስ",
        "name": "Microsoft Server Speech Text to Speech Voice (am-ET, MekdesNeural)",
        "shortName": "am-ET-MekdesNeural",
        "locale": "am-ET",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "አምሀ",
        "name": "Microsoft Server Speech Text to Speech Voice (am-ET, AmehaNeural)",
        "shortName": "am-ET-AmehaNeural",
        "locale": "am-ET",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "حمدان",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-AE, HamdanNeural)",
        "shortName": "ar-AE-HamdanNeural",
        "locale": "ar-AE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "فاطمة",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-AE, FatimaNeural)",
        "shortName": "ar-AE-FatimaNeural",
        "locale": "ar-AE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "علي",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-BH, AliNeural)",
        "shortName": "ar-BH-AliNeural",
        "locale": "ar-BH",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "ليلى",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-BH, LailaNeural)",
        "shortName": "ar-BH-LailaNeural",
        "locale": "ar-BH",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "أمينة",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-DZ, AminaNeural)",
        "shortName": "ar-DZ-AminaNeural",
        "locale": "ar-DZ",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "إسماعيل",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-DZ, IsmaelNeural)",
        "shortName": "ar-DZ-IsmaelNeural",
        "locale": "ar-DZ",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "سلمى",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-EG, SalmaNeural)",
        "shortName": "ar-EG-SalmaNeural",
        "locale": "ar-EG",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "شاكر",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-EG, ShakirNeural)",
        "shortName": "ar-EG-ShakirNeural",
        "locale": "ar-EG",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "باسل",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-IQ, BasselNeural)",
        "shortName": "ar-IQ-BasselNeural",
        "locale": "ar-IQ",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "رنا",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-IQ, RanaNeural)",
        "shortName": "ar-IQ-RanaNeural",
        "locale": "ar-IQ",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "تيم",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-JO, TaimNeural)",
        "shortName": "ar-JO-TaimNeural",
        "locale": "ar-JO",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "سناء",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-JO, SanaNeural)",
        "shortName": "ar-JO-SanaNeural",
        "locale": "ar-JO",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "فهد",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-KW, FahedNeural)",
        "shortName": "ar-KW-FahedNeural",
        "locale": "ar-KW",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "نورا",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-KW, NouraNeural)",
        "shortName": "ar-KW-NouraNeural",
        "locale": "ar-KW",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "رامي",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-LB, RamiNeural)",
        "shortName": "ar-LB-RamiNeural",
        "locale": "ar-LB",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "ليلى",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-LB, LaylaNeural)",
        "shortName": "ar-LB-LaylaNeural",
        "locale": "ar-LB",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "أحمد",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-LY, OmarNeural)",
        "shortName": "ar-LY-OmarNeural",
        "locale": "ar-LY",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "إيمان",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-LY, ImanNeural)",
        "shortName": "ar-LY-ImanNeural",
        "locale": "ar-LY",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "جمال",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-MA, JamalNeural)",
        "shortName": "ar-MA-JamalNeural",
        "locale": "ar-MA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "منى",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-MA, MounaNeural)",
        "shortName": "ar-MA-MounaNeural",
        "locale": "ar-MA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "عائشة",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-OM, AyshaNeural)",
        "shortName": "ar-OM-AyshaNeural",
        "locale": "ar-OM",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "عبدالله",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-OM, AbdullahNeural)",
        "shortName": "ar-OM-AbdullahNeural",
        "locale": "ar-OM",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "أمل",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-QA, AmalNeural)",
        "shortName": "ar-QA-AmalNeural",
        "locale": "ar-QA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "معاذ",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-QA, MoazNeural)",
        "shortName": "ar-QA-MoazNeural",
        "locale": "ar-QA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "حامد",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-SA, HamedNeural)",
        "shortName": "ar-SA-HamedNeural",
        "locale": "ar-SA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "زارية",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-SA, ZariyahNeural)",
        "shortName": "ar-SA-ZariyahNeural",
        "locale": "ar-SA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "أماني",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-SY, AmanyNeural)",
        "shortName": "ar-SY-AmanyNeural",
        "locale": "ar-SY",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "ليث",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-SY, LaithNeural)",
        "shortName": "ar-SY-LaithNeural",
        "locale": "ar-SY",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "ريم",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-TN, ReemNeural)",
        "shortName": "ar-TN-ReemNeural",
        "locale": "ar-TN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "هادي",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-TN, HediNeural)",
        "shortName": "ar-TN-HediNeural",
        "locale": "ar-TN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "صالح",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-YE, SalehNeural)",
        "shortName": "ar-YE-SalehNeural",
        "locale": "ar-YE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "مريم",
        "name": "Microsoft Server Speech Text to Speech Voice (ar-YE, MaryamNeural)",
        "shortName": "ar-YE-MaryamNeural",
        "locale": "ar-YE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "প্ৰিয়ম",
        "name": "Microsoft Server Speech Text to Speech Voice (as-IN, PriyomNeural)",
        "shortName": "as-IN-PriyomNeural",
        "locale": "as-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "যাশিকা",
        "name": "Microsoft Server Speech Text to Speech Voice (as-IN, YashicaNeural)",
        "shortName": "as-IN-YashicaNeural",
        "locale": "as-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Babək",
        "name": "Microsoft Server Speech Text to Speech Voice (az-AZ, BabekNeural)",
        "shortName": "az-AZ-BabekNeural",
        "locale": "az-AZ",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Banu",
        "name": "Microsoft Server Speech Text to Speech Voice (az-AZ, BanuNeural)",
        "shortName": "az-AZ-BanuNeural",
        "locale": "az-AZ",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Борислав",
        "name": "Microsoft Server Speech Text to Speech Voice (bg-BG, BorislavNeural)",
        "shortName": "bg-BG-BorislavNeural",
        "locale": "bg-BG",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Калина",
        "name": "Microsoft Server Speech Text to Speech Voice (bg-BG, KalinaNeural)",
        "shortName": "bg-BG-KalinaNeural",
        "locale": "bg-BG",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "নবনীতা",
        "name": "Microsoft Server Speech Text to Speech Voice (bn-BD, NabanitaNeural)",
        "shortName": "bn-BD-NabanitaNeural",
        "locale": "bn-BD",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "প্রদ্বীপ",
        "name": "Microsoft Server Speech Text to Speech Voice (bn-BD, PradeepNeural)",
        "shortName": "bn-BD-PradeepNeural",
        "locale": "bn-BD",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "তানিশা",
        "name": "Microsoft Server Speech Text to Speech Voice (bn-IN, TanishaaNeural)",
        "shortName": "bn-IN-TanishaaNeural",
        "locale": "bn-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "ভাস্কর",
        "name": "Microsoft Server Speech Text to Speech Voice (bn-IN, BashkarNeural)",
        "shortName": "bn-IN-BashkarNeural",
        "locale": "bn-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Goran",
        "name": "Microsoft Server Speech Text to Speech Voice (bs-BA, GoranNeural)",
        "shortName": "bs-BA-GoranNeural",
        "locale": "bs-BA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Vesna",
        "name": "Microsoft Server Speech Text to Speech Voice (bs-BA, VesnaNeural)",
        "shortName": "bs-BA-VesnaNeural",
        "locale": "bs-BA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Alba",
        "name": "Microsoft Server Speech Text to Speech Voice (ca-ES, AlbaNeural)",
        "shortName": "ca-ES-AlbaNeural",
        "locale": "ca-ES",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Enric",
        "name": "Microsoft Server Speech Text to Speech Voice (ca-ES, EnricNeural)",
        "shortName": "ca-ES-EnricNeural",
        "locale": "ca-ES",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Joana",
        "name": "Microsoft Server Speech Text to Speech Voice (ca-ES, JoanaNeural)",
        "shortName": "ca-ES-JoanaNeural",
        "locale": "ca-ES",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Antonín",
        "name": "Microsoft Server Speech Text to Speech Voice (cs-CZ, AntoninNeural)",
        "shortName": "cs-CZ-AntoninNeural",
        "locale": "cs-CZ",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Vlasta",
        "name": "Microsoft Server Speech Text to Speech Voice (cs-CZ, VlastaNeural)",
        "shortName": "cs-CZ-VlastaNeural",
        "locale": "cs-CZ",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Aled",
        "name": "Microsoft Server Speech Text to Speech Voice (cy-GB, AledNeural)",
        "shortName": "cy-GB-AledNeural",
        "locale": "cy-GB",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Nia",
        "name": "Microsoft Server Speech Text to Speech Voice (cy-GB, NiaNeural)",
        "shortName": "cy-GB-NiaNeural",
        "locale": "cy-GB",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Christel",
        "name": "Microsoft Server Speech Text to Speech Voice (da-DK, ChristelNeural)",
        "shortName": "da-DK-ChristelNeural",
        "locale": "da-DK",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Jeppe",
        "name": "Microsoft Server Speech Text to Speech Voice (da-DK, JeppeNeural)",
        "shortName": "da-DK-JeppeNeural",
        "locale": "da-DK",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Ingrid",
        "name": "Microsoft Server Speech Text to Speech Voice (de-AT, IngridNeural)",
        "shortName": "de-AT-IngridNeural",
        "locale": "de-AT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Jonas",
        "name": "Microsoft Server Speech Text to Speech Voice (de-AT, JonasNeural)",
        "shortName": "de-AT-JonasNeural",
        "locale": "de-AT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Jan",
        "name": "Microsoft Server Speech Text to Speech Voice (de-CH, JanNeural)",
        "shortName": "de-CH-JanNeural",
        "locale": "de-CH",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Leni",
        "name": "Microsoft Server Speech Text to Speech Voice (de-CH, LeniNeural)",
        "shortName": "de-CH-LeniNeural",
        "locale": "de-CH",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Amala",
        "name": "Microsoft Server Speech Text to Speech Voice (de-DE, AmalaNeural)",
        "shortName": "de-DE-AmalaNeural",
        "locale": "de-DE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Bernd",
        "name": "Microsoft Server Speech Text to Speech Voice (de-DE, BerndNeural)",
        "shortName": "de-DE-BerndNeural",
        "locale": "de-DE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Christoph",
        "name": "Microsoft Server Speech Text to Speech Voice (de-DE, ChristophNeural)",
        "shortName": "de-DE-ChristophNeural",
        "locale": "de-DE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Conrad",
        "name": "Microsoft Server Speech Text to Speech Voice (de-DE, ConradNeural)",
        "shortName": "de-DE-ConradNeural",
        "locale": "de-DE",
        "styleList": [
            "cheerful"
        ]
    },
    {
        "gender": "Female",
        "localName": "Elke",
        "name": "Microsoft Server Speech Text to Speech Voice (de-DE, ElkeNeural)",
        "shortName": "de-DE-ElkeNeural",
        "locale": "de-DE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Florian Mehrsprachig",
        "name": "Microsoft Server Speech Text to Speech Voice (de-DE, FlorianMultilingualNeural)",
        "shortName": "de-DE-FlorianMultilingualNeural",
        "locale": "de-DE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Gisela",
        "name": "Microsoft Server Speech Text to Speech Voice (de-DE, GiselaNeural)",
        "shortName": "de-DE-GiselaNeural",
        "locale": "de-DE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Kasper",
        "name": "Microsoft Server Speech Text to Speech Voice (de-DE, KasperNeural)",
        "shortName": "de-DE-KasperNeural",
        "locale": "de-DE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Katja",
        "name": "Microsoft Server Speech Text to Speech Voice (de-DE, KatjaNeural)",
        "shortName": "de-DE-KatjaNeural",
        "locale": "de-DE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Killian",
        "name": "Microsoft Server Speech Text to Speech Voice (de-DE, KillianNeural)",
        "shortName": "de-DE-KillianNeural",
        "locale": "de-DE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Klarissa",
        "name": "Microsoft Server Speech Text to Speech Voice (de-DE, KlarissaNeural)",
        "shortName": "de-DE-KlarissaNeural",
        "locale": "de-DE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Klaus",
        "name": "Microsoft Server Speech Text to Speech Voice (de-DE, KlausNeural)",
        "shortName": "de-DE-KlausNeural",
        "locale": "de-DE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Louisa",
        "name": "Microsoft Server Speech Text to Speech Voice (de-DE, LouisaNeural)",
        "shortName": "de-DE-LouisaNeural",
        "locale": "de-DE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Maja",
        "name": "Microsoft Server Speech Text to Speech Voice (de-DE, MajaNeural)",
        "shortName": "de-DE-MajaNeural",
        "locale": "de-DE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Ralf",
        "name": "Microsoft Server Speech Text to Speech Voice (de-DE, RalfNeural)",
        "shortName": "de-DE-RalfNeural",
        "locale": "de-DE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Seraphina Mehrsprachig",
        "name": "Microsoft Server Speech Text to Speech Voice (de-DE, SeraphinaMultilingualNeural)",
        "shortName": "de-DE-SeraphinaMultilingualNeural",
        "locale": "de-DE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Tanja",
        "name": "Microsoft Server Speech Text to Speech Voice (de-DE, TanjaNeural)",
        "shortName": "de-DE-TanjaNeural",
        "locale": "de-DE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Αθηνά",
        "name": "Microsoft Server Speech Text to Speech Voice (el-GR, AthinaNeural)",
        "shortName": "el-GR-AthinaNeural",
        "locale": "el-GR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Νέστορας",
        "name": "Microsoft Server Speech Text to Speech Voice (el-GR, NestorasNeural)",
        "shortName": "el-GR-NestorasNeural",
        "locale": "el-GR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Annette",
        "name": "Microsoft Server Speech Text to Speech Voice (en-AU, AnnetteNeural)",
        "shortName": "en-AU-AnnetteNeural",
        "locale": "en-AU",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Carly",
        "name": "Microsoft Server Speech Text to Speech Voice (en-AU, CarlyNeural)",
        "shortName": "en-AU-CarlyNeural",
        "locale": "en-AU",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Darren",
        "name": "Microsoft Server Speech Text to Speech Voice (en-AU, DarrenNeural)",
        "shortName": "en-AU-DarrenNeural",
        "locale": "en-AU",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Duncan",
        "name": "Microsoft Server Speech Text to Speech Voice (en-AU, DuncanNeural)",
        "shortName": "en-AU-DuncanNeural",
        "locale": "en-AU",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Elsie",
        "name": "Microsoft Server Speech Text to Speech Voice (en-AU, ElsieNeural)",
        "shortName": "en-AU-ElsieNeural",
        "locale": "en-AU",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Freya",
        "name": "Microsoft Server Speech Text to Speech Voice (en-AU, FreyaNeural)",
        "shortName": "en-AU-FreyaNeural",
        "locale": "en-AU",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Joanne",
        "name": "Microsoft Server Speech Text to Speech Voice (en-AU, JoanneNeural)",
        "shortName": "en-AU-JoanneNeural",
        "locale": "en-AU",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Ken",
        "name": "Microsoft Server Speech Text to Speech Voice (en-AU, KenNeural)",
        "shortName": "en-AU-KenNeural",
        "locale": "en-AU",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Kim",
        "name": "Microsoft Server Speech Text to Speech Voice (en-AU, KimNeural)",
        "shortName": "en-AU-KimNeural",
        "locale": "en-AU",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Natasha",
        "name": "Microsoft Server Speech Text to Speech Voice (en-AU, NatashaNeural)",
        "shortName": "en-AU-NatashaNeural",
        "locale": "en-AU",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Neil",
        "name": "Microsoft Server Speech Text to Speech Voice (en-AU, NeilNeural)",
        "shortName": "en-AU-NeilNeural",
        "locale": "en-AU",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Tim",
        "name": "Microsoft Server Speech Text to Speech Voice (en-AU, TimNeural)",
        "shortName": "en-AU-TimNeural",
        "locale": "en-AU",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Tina",
        "name": "Microsoft Server Speech Text to Speech Voice (en-AU, TinaNeural)",
        "shortName": "en-AU-TinaNeural",
        "locale": "en-AU",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "William",
        "name": "Microsoft Server Speech Text to Speech Voice (en-AU, WilliamNeural)",
        "shortName": "en-AU-WilliamNeural",
        "locale": "en-AU",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Clara",
        "name": "Microsoft Server Speech Text to Speech Voice (en-CA, ClaraNeural)",
        "shortName": "en-CA-ClaraNeural",
        "locale": "en-CA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Liam",
        "name": "Microsoft Server Speech Text to Speech Voice (en-CA, LiamNeural)",
        "shortName": "en-CA-LiamNeural",
        "locale": "en-CA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Abbi",
        "name": "Microsoft Server Speech Text to Speech Voice (en-GB, AbbiNeural)",
        "shortName": "en-GB-AbbiNeural",
        "locale": "en-GB",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Ada Multilingual",
        "name": "Microsoft Server Speech Text to Speech Voice (en-GB, AdaMultilingualNeural)",
        "shortName": "en-GB-AdaMultilingualNeural",
        "locale": "en-GB",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Alfie",
        "name": "Microsoft Server Speech Text to Speech Voice (en-GB, AlfieNeural)",
        "shortName": "en-GB-AlfieNeural",
        "locale": "en-GB",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Bella",
        "name": "Microsoft Server Speech Text to Speech Voice (en-GB, BellaNeural)",
        "shortName": "en-GB-BellaNeural",
        "locale": "en-GB",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Elliot",
        "name": "Microsoft Server Speech Text to Speech Voice (en-GB, ElliotNeural)",
        "shortName": "en-GB-ElliotNeural",
        "locale": "en-GB",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Ethan",
        "name": "Microsoft Server Speech Text to Speech Voice (en-GB, EthanNeural)",
        "shortName": "en-GB-EthanNeural",
        "locale": "en-GB",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Hollie",
        "name": "Microsoft Server Speech Text to Speech Voice (en-GB, HollieNeural)",
        "shortName": "en-GB-HollieNeural",
        "locale": "en-GB",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Libby",
        "name": "Microsoft Server Speech Text to Speech Voice (en-GB, LibbyNeural)",
        "shortName": "en-GB-LibbyNeural",
        "locale": "en-GB",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Maisie",
        "name": "Microsoft Server Speech Text to Speech Voice (en-GB, MaisieNeural)",
        "shortName": "en-GB-MaisieNeural",
        "locale": "en-GB",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Mia",
        "name": "Microsoft Server Speech Text to Speech Voice (en-GB, MiaNeural)",
        "shortName": "en-GB-MiaNeural",
        "locale": "en-GB",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Noah",
        "name": "Microsoft Server Speech Text to Speech Voice (en-GB, NoahNeural)",
        "shortName": "en-GB-NoahNeural",
        "locale": "en-GB",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Oliver",
        "name": "Microsoft Server Speech Text to Speech Voice (en-GB, OliverNeural)",
        "shortName": "en-GB-OliverNeural",
        "locale": "en-GB",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Olivia",
        "name": "Microsoft Server Speech Text to Speech Voice (en-GB, OliviaNeural)",
        "shortName": "en-GB-OliviaNeural",
        "locale": "en-GB",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Ollie Multilingual",
        "name": "Microsoft Server Speech Text to Speech Voice (en-GB, OllieMultilingualNeural)",
        "shortName": "en-GB-OllieMultilingualNeural",
        "locale": "en-GB",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Ryan",
        "name": "Microsoft Server Speech Text to Speech Voice (en-GB, RyanNeural)",
        "shortName": "en-GB-RyanNeural",
        "locale": "en-GB",
        "styleList": [
            "cheerful",
            "chat"
        ]
    },
    {
        "gender": "Female",
        "localName": "Sonia",
        "name": "Microsoft Server Speech Text to Speech Voice (en-GB, SoniaNeural)",
        "shortName": "en-GB-SoniaNeural",
        "locale": "en-GB",
        "styleList": [
            "cheerful",
            "sad"
        ]
    },
    {
        "gender": "Male",
        "localName": "Thomas",
        "name": "Microsoft Server Speech Text to Speech Voice (en-GB, ThomasNeural)",
        "shortName": "en-GB-ThomasNeural",
        "locale": "en-GB",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Sam",
        "name": "Microsoft Server Speech Text to Speech Voice (en-HK, SamNeural)",
        "shortName": "en-HK-SamNeural",
        "locale": "en-HK",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Yan",
        "name": "Microsoft Server Speech Text to Speech Voice (en-HK, YanNeural)",
        "shortName": "en-HK-YanNeural",
        "locale": "en-HK",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Connor",
        "name": "Microsoft Server Speech Text to Speech Voice (en-IE, ConnorNeural)",
        "shortName": "en-IE-ConnorNeural",
        "locale": "en-IE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Emily",
        "name": "Microsoft Server Speech Text to Speech Voice (en-IE, EmilyNeural)",
        "shortName": "en-IE-EmilyNeural",
        "locale": "en-IE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Aarav",
        "name": "Microsoft Server Speech Text to Speech Voice (en-IN, AaravNeural)",
        "shortName": "en-IN-AaravNeural",
        "locale": "en-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Aashi",
        "name": "Microsoft Server Speech Text to Speech Voice (en-IN, AashiNeural)",
        "shortName": "en-IN-AashiNeural",
        "locale": "en-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Ananya",
        "name": "Microsoft Server Speech Text to Speech Voice (en-IN, AnanyaNeural)",
        "shortName": "en-IN-AnanyaNeural",
        "locale": "en-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Kavya",
        "name": "Microsoft Server Speech Text to Speech Voice (en-IN, KavyaNeural)",
        "shortName": "en-IN-KavyaNeural",
        "locale": "en-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Kunal",
        "name": "Microsoft Server Speech Text to Speech Voice (en-IN, KunalNeural)",
        "shortName": "en-IN-KunalNeural",
        "locale": "en-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Neerja",
        "name": "Microsoft Server Speech Text to Speech Voice (en-IN, NeerjaNeural)",
        "shortName": "en-IN-NeerjaNeural",
        "locale": "en-IN",
        "styleList": [
            "newscast",
            "cheerful",
            "empathetic"
        ]
    },
    {
        "gender": "Male",
        "localName": "Prabhat",
        "name": "Microsoft Server Speech Text to Speech Voice (en-IN, PrabhatNeural)",
        "shortName": "en-IN-PrabhatNeural",
        "locale": "en-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Rehaan",
        "name": "Microsoft Server Speech Text to Speech Voice (en-IN, RehaanNeural)",
        "shortName": "en-IN-RehaanNeural",
        "locale": "en-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Asilia",
        "name": "Microsoft Server Speech Text to Speech Voice (en-KE, AsiliaNeural)",
        "shortName": "en-KE-AsiliaNeural",
        "locale": "en-KE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Chilemba",
        "name": "Microsoft Server Speech Text to Speech Voice (en-KE, ChilembaNeural)",
        "shortName": "en-KE-ChilembaNeural",
        "locale": "en-KE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Abeo",
        "name": "Microsoft Server Speech Text to Speech Voice (en-NG, AbeoNeural)",
        "shortName": "en-NG-AbeoNeural",
        "locale": "en-NG",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Ezinne",
        "name": "Microsoft Server Speech Text to Speech Voice (en-NG, EzinneNeural)",
        "shortName": "en-NG-EzinneNeural",
        "locale": "en-NG",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Mitchell",
        "name": "Microsoft Server Speech Text to Speech Voice (en-NZ, MitchellNeural)",
        "shortName": "en-NZ-MitchellNeural",
        "locale": "en-NZ",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Molly",
        "name": "Microsoft Server Speech Text to Speech Voice (en-NZ, MollyNeural)",
        "shortName": "en-NZ-MollyNeural",
        "locale": "en-NZ",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "James",
        "name": "Microsoft Server Speech Text to Speech Voice (en-PH, JamesNeural)",
        "shortName": "en-PH-JamesNeural",
        "locale": "en-PH",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Rosa",
        "name": "Microsoft Server Speech Text to Speech Voice (en-PH, RosaNeural)",
        "shortName": "en-PH-RosaNeural",
        "locale": "en-PH",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Luna",
        "name": "Microsoft Server Speech Text to Speech Voice (en-SG, LunaNeural)",
        "shortName": "en-SG-LunaNeural",
        "locale": "en-SG",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Wayne",
        "name": "Microsoft Server Speech Text to Speech Voice (en-SG, WayneNeural)",
        "shortName": "en-SG-WayneNeural",
        "locale": "en-SG",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Elimu",
        "name": "Microsoft Server Speech Text to Speech Voice (en-TZ, ElimuNeural)",
        "shortName": "en-TZ-ElimuNeural",
        "locale": "en-TZ",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Imani",
        "name": "Microsoft Server Speech Text to Speech Voice (en-TZ, ImaniNeural)",
        "shortName": "en-TZ-ImaniNeural",
        "locale": "en-TZ",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "AIGenerate1",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, AIGenerate1Neural)",
        "shortName": "en-US-AIGenerate1Neural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "AIGenerate2",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, AIGenerate2Neural)",
        "shortName": "en-US-AIGenerate2Neural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Amber",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, AmberNeural)",
        "shortName": "en-US-AmberNeural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Ana",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, AnaNeural)",
        "shortName": "en-US-AnaNeural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Andrew",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, AndrewNeural)",
        "shortName": "en-US-AndrewNeural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Andrew Multilingual",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, AndrewMultilingualNeural)",
        "shortName": "en-US-AndrewMultilingualNeural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Aria",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, AriaNeural)",
        "shortName": "en-US-AriaNeural",
        "locale": "en-US",
        "styleList": [
            "chat",
            "customerservice",
            "narration-professional",
            "newscast-casual",
            "newscast-formal",
            "cheerful",
            "empathetic",
            "angry",
            "sad",
            "excited",
            "friendly",
            "terrified",
            "shouting",
            "unfriendly",
            "whispering",
            "hopeful"
        ]
    },
    {
        "gender": "Female",
        "localName": "Ashley",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, AshleyNeural)",
        "shortName": "en-US-AshleyNeural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Ava",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, AvaNeural)",
        "shortName": "en-US-AvaNeural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Ava Multilingual",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, AvaMultilingualNeural)",
        "shortName": "en-US-AvaMultilingualNeural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Unknown",
        "localName": "Blue",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, BlueNeural)",
        "shortName": "en-US-BlueNeural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Brandon",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, BrandonNeural)",
        "shortName": "en-US-BrandonNeural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Brian",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, BrianNeural)",
        "shortName": "en-US-BrianNeural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Brian Multilingual",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, BrianMultilingualNeural)",
        "shortName": "en-US-BrianMultilingualNeural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Christopher",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, ChristopherNeural)",
        "shortName": "en-US-ChristopherNeural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Cora",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, CoraNeural)",
        "shortName": "en-US-CoraNeural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Davis",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, DavisNeural)",
        "shortName": "en-US-DavisNeural",
        "locale": "en-US",
        "styleList": [
            "chat",
            "angry",
            "cheerful",
            "excited",
            "friendly",
            "hopeful",
            "sad",
            "shouting",
            "terrified",
            "unfriendly",
            "whispering"
        ]
    },
    {
        "gender": "Female",
        "localName": "Elizabeth",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, ElizabethNeural)",
        "shortName": "en-US-ElizabethNeural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Emma",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, EmmaNeural)",
        "shortName": "en-US-EmmaNeural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Emma Multilingual",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, EmmaMultilingualNeural)",
        "shortName": "en-US-EmmaMultilingualNeural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Eric",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, EricNeural)",
        "shortName": "en-US-EricNeural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Guy",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, GuyNeural)",
        "shortName": "en-US-GuyNeural",
        "locale": "en-US",
        "styleList": [
            "newscast",
            "angry",
            "cheerful",
            "sad",
            "excited",
            "friendly",
            "terrified",
            "shouting",
            "unfriendly",
            "whispering",
            "hopeful"
        ]
    },
    {
        "gender": "Male",
        "localName": "Jacob",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, JacobNeural)",
        "shortName": "en-US-JacobNeural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Jane",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, JaneNeural)",
        "shortName": "en-US-JaneNeural",
        "locale": "en-US",
        "styleList": [
            "angry",
            "cheerful",
            "excited",
            "friendly",
            "hopeful",
            "sad",
            "shouting",
            "terrified",
            "unfriendly",
            "whispering"
        ]
    },
    {
        "gender": "Male",
        "localName": "Jason",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, JasonNeural)",
        "shortName": "en-US-JasonNeural",
        "locale": "en-US",
        "styleList": [
            "angry",
            "cheerful",
            "excited",
            "friendly",
            "hopeful",
            "sad",
            "shouting",
            "terrified",
            "unfriendly",
            "whispering"
        ]
    },
    {
        "gender": "Female",
        "localName": "Jenny",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, JennyNeural)",
        "shortName": "en-US-JennyNeural",
        "locale": "en-US",
        "styleList": [
            "assistant",
            "chat",
            "customerservice",
            "newscast",
            "angry",
            "cheerful",
            "sad",
            "excited",
            "friendly",
            "terrified",
            "shouting",
            "unfriendly",
            "whispering",
            "hopeful"
        ]
    },
    {
        "gender": "Female",
        "localName": "Jenny Multilingual",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, JennyMultilingualNeural)",
        "shortName": "en-US-JennyMultilingualNeural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Kai",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, KaiNeural)",
        "shortName": "en-US-KaiNeural",
        "locale": "en-US",
        "styleList": [
            "conversation"
        ]
    },
    {
        "gender": "Female",
        "localName": "Luna",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, LunaNeural)",
        "shortName": "en-US-LunaNeural",
        "locale": "en-US",
        "styleList": [
            "conversation"
        ]
    },
    {
        "gender": "Female",
        "localName": "Michelle",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, MichelleNeural)",
        "shortName": "en-US-MichelleNeural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Monica",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, MonicaNeural)",
        "shortName": "en-US-MonicaNeural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Nancy",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, NancyNeural)",
        "shortName": "en-US-NancyNeural",
        "locale": "en-US",
        "styleList": [
            "angry",
            "cheerful",
            "excited",
            "friendly",
            "hopeful",
            "sad",
            "shouting",
            "terrified",
            "unfriendly",
            "whispering"
        ]
    },
    {
        "gender": "Male",
        "localName": "Roger",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, RogerNeural)",
        "shortName": "en-US-RogerNeural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Ryan Multilingual",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, RyanMultilingualNeural)",
        "shortName": "en-US-RyanMultilingualNeural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Sara",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, SaraNeural)",
        "shortName": "en-US-SaraNeural",
        "locale": "en-US",
        "styleList": [
            "angry",
            "cheerful",
            "excited",
            "friendly",
            "hopeful",
            "sad",
            "shouting",
            "terrified",
            "unfriendly",
            "whispering"
        ]
    },
    {
        "gender": "Male",
        "localName": "Steffan",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, SteffanNeural)",
        "shortName": "en-US-SteffanNeural",
        "locale": "en-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Tony",
        "name": "Microsoft Server Speech Text to Speech Voice (en-US, TonyNeural)",
        "shortName": "en-US-TonyNeural",
        "locale": "en-US",
        "styleList": [
            "angry",
            "cheerful",
            "excited",
            "friendly",
            "hopeful",
            "sad",
            "shouting",
            "terrified",
            "unfriendly",
            "whispering"
        ]
    },
    {
        "gender": "Female",
        "localName": "Leah",
        "name": "Microsoft Server Speech Text to Speech Voice (en-ZA, LeahNeural)",
        "shortName": "en-ZA-LeahNeural",
        "locale": "en-ZA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Luke",
        "name": "Microsoft Server Speech Text to Speech Voice (en-ZA, LukeNeural)",
        "shortName": "en-ZA-LukeNeural",
        "locale": "en-ZA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Elena",
        "name": "Microsoft Server Speech Text to Speech Voice (es-AR, ElenaNeural)",
        "shortName": "es-AR-ElenaNeural",
        "locale": "es-AR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Tomas",
        "name": "Microsoft Server Speech Text to Speech Voice (es-AR, TomasNeural)",
        "shortName": "es-AR-TomasNeural",
        "locale": "es-AR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Marcelo",
        "name": "Microsoft Server Speech Text to Speech Voice (es-BO, MarceloNeural)",
        "shortName": "es-BO-MarceloNeural",
        "locale": "es-BO",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Sofia",
        "name": "Microsoft Server Speech Text to Speech Voice (es-BO, SofiaNeural)",
        "shortName": "es-BO-SofiaNeural",
        "locale": "es-BO",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Catalina",
        "name": "Microsoft Server Speech Text to Speech Voice (es-CL, CatalinaNeural)",
        "shortName": "es-CL-CatalinaNeural",
        "locale": "es-CL",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Lorenzo",
        "name": "Microsoft Server Speech Text to Speech Voice (es-CL, LorenzoNeural)",
        "shortName": "es-CL-LorenzoNeural",
        "locale": "es-CL",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Gonzalo",
        "name": "Microsoft Server Speech Text to Speech Voice (es-CO, GonzaloNeural)",
        "shortName": "es-CO-GonzaloNeural",
        "locale": "es-CO",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Salome",
        "name": "Microsoft Server Speech Text to Speech Voice (es-CO, SalomeNeural)",
        "shortName": "es-CO-SalomeNeural",
        "locale": "es-CO",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Juan",
        "name": "Microsoft Server Speech Text to Speech Voice (es-CR, JuanNeural)",
        "shortName": "es-CR-JuanNeural",
        "locale": "es-CR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "María",
        "name": "Microsoft Server Speech Text to Speech Voice (es-CR, MariaNeural)",
        "shortName": "es-CR-MariaNeural",
        "locale": "es-CR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Belkys",
        "name": "Microsoft Server Speech Text to Speech Voice (es-CU, BelkysNeural)",
        "shortName": "es-CU-BelkysNeural",
        "locale": "es-CU",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Manuel",
        "name": "Microsoft Server Speech Text to Speech Voice (es-CU, ManuelNeural)",
        "shortName": "es-CU-ManuelNeural",
        "locale": "es-CU",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Emilio",
        "name": "Microsoft Server Speech Text to Speech Voice (es-DO, EmilioNeural)",
        "shortName": "es-DO-EmilioNeural",
        "locale": "es-DO",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Ramona",
        "name": "Microsoft Server Speech Text to Speech Voice (es-DO, RamonaNeural)",
        "shortName": "es-DO-RamonaNeural",
        "locale": "es-DO",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Andrea",
        "name": "Microsoft Server Speech Text to Speech Voice (es-EC, AndreaNeural)",
        "shortName": "es-EC-AndreaNeural",
        "locale": "es-EC",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Luis",
        "name": "Microsoft Server Speech Text to Speech Voice (es-EC, LuisNeural)",
        "shortName": "es-EC-LuisNeural",
        "locale": "es-EC",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Abril",
        "name": "Microsoft Server Speech Text to Speech Voice (es-ES, AbrilNeural)",
        "shortName": "es-ES-AbrilNeural",
        "locale": "es-ES",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Álvaro",
        "name": "Microsoft Server Speech Text to Speech Voice (es-ES, AlvaroNeural)",
        "shortName": "es-ES-AlvaroNeural",
        "locale": "es-ES",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Arabella Multilingual",
        "name": "Microsoft Server Speech Text to Speech Voice (es-ES, ArabellaMultilingualNeural)",
        "shortName": "es-ES-ArabellaMultilingualNeural",
        "locale": "es-ES",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Arnau",
        "name": "Microsoft Server Speech Text to Speech Voice (es-ES, ArnauNeural)",
        "shortName": "es-ES-ArnauNeural",
        "locale": "es-ES",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Dario",
        "name": "Microsoft Server Speech Text to Speech Voice (es-ES, DarioNeural)",
        "shortName": "es-ES-DarioNeural",
        "locale": "es-ES",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Elias",
        "name": "Microsoft Server Speech Text to Speech Voice (es-ES, EliasNeural)",
        "shortName": "es-ES-EliasNeural",
        "locale": "es-ES",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Elvira",
        "name": "Microsoft Server Speech Text to Speech Voice (es-ES, ElviraNeural)",
        "shortName": "es-ES-ElviraNeural",
        "locale": "es-ES",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Estrella",
        "name": "Microsoft Server Speech Text to Speech Voice (es-ES, EstrellaNeural)",
        "shortName": "es-ES-EstrellaNeural",
        "locale": "es-ES",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Irene",
        "name": "Microsoft Server Speech Text to Speech Voice (es-ES, IreneNeural)",
        "shortName": "es-ES-IreneNeural",
        "locale": "es-ES",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Isidora Multilingual",
        "name": "Microsoft Server Speech Text to Speech Voice (es-ES, IsidoraMultilingualNeural)",
        "shortName": "es-ES-IsidoraMultilingualNeural",
        "locale": "es-ES",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Laia",
        "name": "Microsoft Server Speech Text to Speech Voice (es-ES, LaiaNeural)",
        "shortName": "es-ES-LaiaNeural",
        "locale": "es-ES",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Lia",
        "name": "Microsoft Server Speech Text to Speech Voice (es-ES, LiaNeural)",
        "shortName": "es-ES-LiaNeural",
        "locale": "es-ES",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Nil",
        "name": "Microsoft Server Speech Text to Speech Voice (es-ES, NilNeural)",
        "shortName": "es-ES-NilNeural",
        "locale": "es-ES",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Saul",
        "name": "Microsoft Server Speech Text to Speech Voice (es-ES, SaulNeural)",
        "shortName": "es-ES-SaulNeural",
        "locale": "es-ES",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Teo",
        "name": "Microsoft Server Speech Text to Speech Voice (es-ES, TeoNeural)",
        "shortName": "es-ES-TeoNeural",
        "locale": "es-ES",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Triana",
        "name": "Microsoft Server Speech Text to Speech Voice (es-ES, TrianaNeural)",
        "shortName": "es-ES-TrianaNeural",
        "locale": "es-ES",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Vera",
        "name": "Microsoft Server Speech Text to Speech Voice (es-ES, VeraNeural)",
        "shortName": "es-ES-VeraNeural",
        "locale": "es-ES",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Ximena",
        "name": "Microsoft Server Speech Text to Speech Voice (es-ES, XimenaNeural)",
        "shortName": "es-ES-XimenaNeural",
        "locale": "es-ES",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Javier",
        "name": "Microsoft Server Speech Text to Speech Voice (es-GQ, JavierNeural)",
        "shortName": "es-GQ-JavierNeural",
        "locale": "es-GQ",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Teresa",
        "name": "Microsoft Server Speech Text to Speech Voice (es-GQ, TeresaNeural)",
        "shortName": "es-GQ-TeresaNeural",
        "locale": "es-GQ",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Andrés",
        "name": "Microsoft Server Speech Text to Speech Voice (es-GT, AndresNeural)",
        "shortName": "es-GT-AndresNeural",
        "locale": "es-GT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Marta",
        "name": "Microsoft Server Speech Text to Speech Voice (es-GT, MartaNeural)",
        "shortName": "es-GT-MartaNeural",
        "locale": "es-GT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Carlos",
        "name": "Microsoft Server Speech Text to Speech Voice (es-HN, CarlosNeural)",
        "shortName": "es-HN-CarlosNeural",
        "locale": "es-HN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Karla",
        "name": "Microsoft Server Speech Text to Speech Voice (es-HN, KarlaNeural)",
        "shortName": "es-HN-KarlaNeural",
        "locale": "es-HN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Beatriz",
        "name": "Microsoft Server Speech Text to Speech Voice (es-MX, BeatrizNeural)",
        "shortName": "es-MX-BeatrizNeural",
        "locale": "es-MX",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Candela",
        "name": "Microsoft Server Speech Text to Speech Voice (es-MX, CandelaNeural)",
        "shortName": "es-MX-CandelaNeural",
        "locale": "es-MX",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Carlota",
        "name": "Microsoft Server Speech Text to Speech Voice (es-MX, CarlotaNeural)",
        "shortName": "es-MX-CarlotaNeural",
        "locale": "es-MX",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Cecilio",
        "name": "Microsoft Server Speech Text to Speech Voice (es-MX, CecilioNeural)",
        "shortName": "es-MX-CecilioNeural",
        "locale": "es-MX",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Dalia",
        "name": "Microsoft Server Speech Text to Speech Voice (es-MX, DaliaNeural)",
        "shortName": "es-MX-DaliaNeural",
        "locale": "es-MX",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Gerardo",
        "name": "Microsoft Server Speech Text to Speech Voice (es-MX, GerardoNeural)",
        "shortName": "es-MX-GerardoNeural",
        "locale": "es-MX",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Jorge",
        "name": "Microsoft Server Speech Text to Speech Voice (es-MX, JorgeNeural)",
        "shortName": "es-MX-JorgeNeural",
        "locale": "es-MX",
        "styleList": [
            "cheerful",
            "chat"
        ]
    },
    {
        "gender": "Female",
        "localName": "Larissa",
        "name": "Microsoft Server Speech Text to Speech Voice (es-MX, LarissaNeural)",
        "shortName": "es-MX-LarissaNeural",
        "locale": "es-MX",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Liberto",
        "name": "Microsoft Server Speech Text to Speech Voice (es-MX, LibertoNeural)",
        "shortName": "es-MX-LibertoNeural",
        "locale": "es-MX",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Luciano",
        "name": "Microsoft Server Speech Text to Speech Voice (es-MX, LucianoNeural)",
        "shortName": "es-MX-LucianoNeural",
        "locale": "es-MX",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Marina",
        "name": "Microsoft Server Speech Text to Speech Voice (es-MX, MarinaNeural)",
        "shortName": "es-MX-MarinaNeural",
        "locale": "es-MX",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Nuria",
        "name": "Microsoft Server Speech Text to Speech Voice (es-MX, NuriaNeural)",
        "shortName": "es-MX-NuriaNeural",
        "locale": "es-MX",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Pelayo",
        "name": "Microsoft Server Speech Text to Speech Voice (es-MX, PelayoNeural)",
        "shortName": "es-MX-PelayoNeural",
        "locale": "es-MX",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Renata",
        "name": "Microsoft Server Speech Text to Speech Voice (es-MX, RenataNeural)",
        "shortName": "es-MX-RenataNeural",
        "locale": "es-MX",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Yago",
        "name": "Microsoft Server Speech Text to Speech Voice (es-MX, YagoNeural)",
        "shortName": "es-MX-YagoNeural",
        "locale": "es-MX",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Federico",
        "name": "Microsoft Server Speech Text to Speech Voice (es-NI, FedericoNeural)",
        "shortName": "es-NI-FedericoNeural",
        "locale": "es-NI",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Yolanda",
        "name": "Microsoft Server Speech Text to Speech Voice (es-NI, YolandaNeural)",
        "shortName": "es-NI-YolandaNeural",
        "locale": "es-NI",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Margarita",
        "name": "Microsoft Server Speech Text to Speech Voice (es-PA, MargaritaNeural)",
        "shortName": "es-PA-MargaritaNeural",
        "locale": "es-PA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Roberto",
        "name": "Microsoft Server Speech Text to Speech Voice (es-PA, RobertoNeural)",
        "shortName": "es-PA-RobertoNeural",
        "locale": "es-PA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Alex",
        "name": "Microsoft Server Speech Text to Speech Voice (es-PE, AlexNeural)",
        "shortName": "es-PE-AlexNeural",
        "locale": "es-PE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Camila",
        "name": "Microsoft Server Speech Text to Speech Voice (es-PE, CamilaNeural)",
        "shortName": "es-PE-CamilaNeural",
        "locale": "es-PE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Karina",
        "name": "Microsoft Server Speech Text to Speech Voice (es-PR, KarinaNeural)",
        "shortName": "es-PR-KarinaNeural",
        "locale": "es-PR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Víctor",
        "name": "Microsoft Server Speech Text to Speech Voice (es-PR, VictorNeural)",
        "shortName": "es-PR-VictorNeural",
        "locale": "es-PR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Mario",
        "name": "Microsoft Server Speech Text to Speech Voice (es-PY, MarioNeural)",
        "shortName": "es-PY-MarioNeural",
        "locale": "es-PY",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Tania",
        "name": "Microsoft Server Speech Text to Speech Voice (es-PY, TaniaNeural)",
        "shortName": "es-PY-TaniaNeural",
        "locale": "es-PY",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Lorena",
        "name": "Microsoft Server Speech Text to Speech Voice (es-SV, LorenaNeural)",
        "shortName": "es-SV-LorenaNeural",
        "locale": "es-SV",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Rodrigo",
        "name": "Microsoft Server Speech Text to Speech Voice (es-SV, RodrigoNeural)",
        "shortName": "es-SV-RodrigoNeural",
        "locale": "es-SV",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Alonso",
        "name": "Microsoft Server Speech Text to Speech Voice (es-US, AlonsoNeural)",
        "shortName": "es-US-AlonsoNeural",
        "locale": "es-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Paloma",
        "name": "Microsoft Server Speech Text to Speech Voice (es-US, PalomaNeural)",
        "shortName": "es-US-PalomaNeural",
        "locale": "es-US",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Mateo",
        "name": "Microsoft Server Speech Text to Speech Voice (es-UY, MateoNeural)",
        "shortName": "es-UY-MateoNeural",
        "locale": "es-UY",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Valentina",
        "name": "Microsoft Server Speech Text to Speech Voice (es-UY, ValentinaNeural)",
        "shortName": "es-UY-ValentinaNeural",
        "locale": "es-UY",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Paola",
        "name": "Microsoft Server Speech Text to Speech Voice (es-VE, PaolaNeural)",
        "shortName": "es-VE-PaolaNeural",
        "locale": "es-VE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Sebastián",
        "name": "Microsoft Server Speech Text to Speech Voice (es-VE, SebastianNeural)",
        "shortName": "es-VE-SebastianNeural",
        "locale": "es-VE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Anu",
        "name": "Microsoft Server Speech Text to Speech Voice (et-EE, AnuNeural)",
        "shortName": "et-EE-AnuNeural",
        "locale": "et-EE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Kert",
        "name": "Microsoft Server Speech Text to Speech Voice (et-EE, KertNeural)",
        "shortName": "et-EE-KertNeural",
        "locale": "et-EE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Ainhoa",
        "name": "Microsoft Server Speech Text to Speech Voice (eu-ES, AinhoaNeural)",
        "shortName": "eu-ES-AinhoaNeural",
        "locale": "eu-ES",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Ander",
        "name": "Microsoft Server Speech Text to Speech Voice (eu-ES, AnderNeural)",
        "shortName": "eu-ES-AnderNeural",
        "locale": "eu-ES",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "دلارا",
        "name": "Microsoft Server Speech Text to Speech Voice (fa-IR, DilaraNeural)",
        "shortName": "fa-IR-DilaraNeural",
        "locale": "fa-IR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "فرید",
        "name": "Microsoft Server Speech Text to Speech Voice (fa-IR, FaridNeural)",
        "shortName": "fa-IR-FaridNeural",
        "locale": "fa-IR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Harri",
        "name": "Microsoft Server Speech Text to Speech Voice (fi-FI, HarriNeural)",
        "shortName": "fi-FI-HarriNeural",
        "locale": "fi-FI",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Noora",
        "name": "Microsoft Server Speech Text to Speech Voice (fi-FI, NooraNeural)",
        "shortName": "fi-FI-NooraNeural",
        "locale": "fi-FI",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Selma",
        "name": "Microsoft Server Speech Text to Speech Voice (fi-FI, SelmaNeural)",
        "shortName": "fi-FI-SelmaNeural",
        "locale": "fi-FI",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Angelo",
        "name": "Microsoft Server Speech Text to Speech Voice (fil-PH, AngeloNeural)",
        "shortName": "fil-PH-AngeloNeural",
        "locale": "fil-PH",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Blessica",
        "name": "Microsoft Server Speech Text to Speech Voice (fil-PH, BlessicaNeural)",
        "shortName": "fil-PH-BlessicaNeural",
        "locale": "fil-PH",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Charline",
        "name": "Microsoft Server Speech Text to Speech Voice (fr-BE, CharlineNeural)",
        "shortName": "fr-BE-CharlineNeural",
        "locale": "fr-BE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Gerard",
        "name": "Microsoft Server Speech Text to Speech Voice (fr-BE, GerardNeural)",
        "shortName": "fr-BE-GerardNeural",
        "locale": "fr-BE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Antoine",
        "name": "Microsoft Server Speech Text to Speech Voice (fr-CA, AntoineNeural)",
        "shortName": "fr-CA-AntoineNeural",
        "locale": "fr-CA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Jean",
        "name": "Microsoft Server Speech Text to Speech Voice (fr-CA, JeanNeural)",
        "shortName": "fr-CA-JeanNeural",
        "locale": "fr-CA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Sylvie",
        "name": "Microsoft Server Speech Text to Speech Voice (fr-CA, SylvieNeural)",
        "shortName": "fr-CA-SylvieNeural",
        "locale": "fr-CA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Thierry",
        "name": "Microsoft Server Speech Text to Speech Voice (fr-CA, ThierryNeural)",
        "shortName": "fr-CA-ThierryNeural",
        "locale": "fr-CA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Ariane",
        "name": "Microsoft Server Speech Text to Speech Voice (fr-CH, ArianeNeural)",
        "shortName": "fr-CH-ArianeNeural",
        "locale": "fr-CH",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Fabrice",
        "name": "Microsoft Server Speech Text to Speech Voice (fr-CH, FabriceNeural)",
        "shortName": "fr-CH-FabriceNeural",
        "locale": "fr-CH",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Alain",
        "name": "Microsoft Server Speech Text to Speech Voice (fr-FR, AlainNeural)",
        "shortName": "fr-FR-AlainNeural",
        "locale": "fr-FR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Brigitte",
        "name": "Microsoft Server Speech Text to Speech Voice (fr-FR, BrigitteNeural)",
        "shortName": "fr-FR-BrigitteNeural",
        "locale": "fr-FR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Celeste",
        "name": "Microsoft Server Speech Text to Speech Voice (fr-FR, CelesteNeural)",
        "shortName": "fr-FR-CelesteNeural",
        "locale": "fr-FR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Claude",
        "name": "Microsoft Server Speech Text to Speech Voice (fr-FR, ClaudeNeural)",
        "shortName": "fr-FR-ClaudeNeural",
        "locale": "fr-FR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Coralie",
        "name": "Microsoft Server Speech Text to Speech Voice (fr-FR, CoralieNeural)",
        "shortName": "fr-FR-CoralieNeural",
        "locale": "fr-FR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Denise",
        "name": "Microsoft Server Speech Text to Speech Voice (fr-FR, DeniseNeural)",
        "shortName": "fr-FR-DeniseNeural",
        "locale": "fr-FR",
        "styleList": [
            "cheerful",
            "sad"
        ]
    },
    {
        "gender": "Female",
        "localName": "Eloise",
        "name": "Microsoft Server Speech Text to Speech Voice (fr-FR, EloiseNeural)",
        "shortName": "fr-FR-EloiseNeural",
        "locale": "fr-FR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Henri",
        "name": "Microsoft Server Speech Text to Speech Voice (fr-FR, HenriNeural)",
        "shortName": "fr-FR-HenriNeural",
        "locale": "fr-FR",
        "styleList": [
            "cheerful",
            "sad"
        ]
    },
    {
        "gender": "Female",
        "localName": "Jacqueline",
        "name": "Microsoft Server Speech Text to Speech Voice (fr-FR, JacquelineNeural)",
        "shortName": "fr-FR-JacquelineNeural",
        "locale": "fr-FR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Jerome",
        "name": "Microsoft Server Speech Text to Speech Voice (fr-FR, JeromeNeural)",
        "shortName": "fr-FR-JeromeNeural",
        "locale": "fr-FR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Josephine",
        "name": "Microsoft Server Speech Text to Speech Voice (fr-FR, JosephineNeural)",
        "shortName": "fr-FR-JosephineNeural",
        "locale": "fr-FR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Maurice",
        "name": "Microsoft Server Speech Text to Speech Voice (fr-FR, MauriceNeural)",
        "shortName": "fr-FR-MauriceNeural",
        "locale": "fr-FR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Rémy Multilingue",
        "name": "Microsoft Server Speech Text to Speech Voice (fr-FR, RemyMultilingualNeural)",
        "shortName": "fr-FR-RemyMultilingualNeural",
        "locale": "fr-FR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Vivienne Multilingue",
        "name": "Microsoft Server Speech Text to Speech Voice (fr-FR, VivienneMultilingualNeural)",
        "shortName": "fr-FR-VivienneMultilingualNeural",
        "locale": "fr-FR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Yves",
        "name": "Microsoft Server Speech Text to Speech Voice (fr-FR, YvesNeural)",
        "shortName": "fr-FR-YvesNeural",
        "locale": "fr-FR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Yvette",
        "name": "Microsoft Server Speech Text to Speech Voice (fr-FR, YvetteNeural)",
        "shortName": "fr-FR-YvetteNeural",
        "locale": "fr-FR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Colm",
        "name": "Microsoft Server Speech Text to Speech Voice (ga-IE, ColmNeural)",
        "shortName": "ga-IE-ColmNeural",
        "locale": "ga-IE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Orla",
        "name": "Microsoft Server Speech Text to Speech Voice (ga-IE, OrlaNeural)",
        "shortName": "ga-IE-OrlaNeural",
        "locale": "ga-IE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Roi",
        "name": "Microsoft Server Speech Text to Speech Voice (gl-ES, RoiNeural)",
        "shortName": "gl-ES-RoiNeural",
        "locale": "gl-ES",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Sabela",
        "name": "Microsoft Server Speech Text to Speech Voice (gl-ES, SabelaNeural)",
        "shortName": "gl-ES-SabelaNeural",
        "locale": "gl-ES",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "ધ્વની",
        "name": "Microsoft Server Speech Text to Speech Voice (gu-IN, DhwaniNeural)",
        "shortName": "gu-IN-DhwaniNeural",
        "locale": "gu-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "નિરંજન",
        "name": "Microsoft Server Speech Text to Speech Voice (gu-IN, NiranjanNeural)",
        "shortName": "gu-IN-NiranjanNeural",
        "locale": "gu-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "אברי",
        "name": "Microsoft Server Speech Text to Speech Voice (he-IL, AvriNeural)",
        "shortName": "he-IL-AvriNeural",
        "locale": "he-IL",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "הילה",
        "name": "Microsoft Server Speech Text to Speech Voice (he-IL, HilaNeural)",
        "shortName": "he-IL-HilaNeural",
        "locale": "he-IL",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "अनन्या",
        "name": "Microsoft Server Speech Text to Speech Voice (hi-IN, AnanyaNeural)",
        "shortName": "hi-IN-AnanyaNeural",
        "locale": "hi-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "आरव ",
        "name": "Microsoft Server Speech Text to Speech Voice (hi-IN, AaravNeural)",
        "shortName": "hi-IN-AaravNeural",
        "locale": "hi-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "काव्या",
        "name": "Microsoft Server Speech Text to Speech Voice (hi-IN, KavyaNeural)",
        "shortName": "hi-IN-KavyaNeural",
        "locale": "hi-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "कुनाल ",
        "name": "Microsoft Server Speech Text to Speech Voice (hi-IN, KunalNeural)",
        "shortName": "hi-IN-KunalNeural",
        "locale": "hi-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "मधुर",
        "name": "Microsoft Server Speech Text to Speech Voice (hi-IN, MadhurNeural)",
        "shortName": "hi-IN-MadhurNeural",
        "locale": "hi-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "रेहान",
        "name": "Microsoft Server Speech Text to Speech Voice (hi-IN, RehaanNeural)",
        "shortName": "hi-IN-RehaanNeural",
        "locale": "hi-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "स्वरा",
        "name": "Microsoft Server Speech Text to Speech Voice (hi-IN, SwaraNeural)",
        "shortName": "hi-IN-SwaraNeural",
        "locale": "hi-IN",
        "styleList": [
            "newscast",
            "cheerful",
            "empathetic"
        ]
    },
    {
        "gender": "Female",
        "localName": "Gabrijela",
        "name": "Microsoft Server Speech Text to Speech Voice (hr-HR, GabrijelaNeural)",
        "shortName": "hr-HR-GabrijelaNeural",
        "locale": "hr-HR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Srećko",
        "name": "Microsoft Server Speech Text to Speech Voice (hr-HR, SreckoNeural)",
        "shortName": "hr-HR-SreckoNeural",
        "locale": "hr-HR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Noémi",
        "name": "Microsoft Server Speech Text to Speech Voice (hu-HU, NoemiNeural)",
        "shortName": "hu-HU-NoemiNeural",
        "locale": "hu-HU",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Tamás",
        "name": "Microsoft Server Speech Text to Speech Voice (hu-HU, TamasNeural)",
        "shortName": "hu-HU-TamasNeural",
        "locale": "hu-HU",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Անահիտ",
        "name": "Microsoft Server Speech Text to Speech Voice (hy-AM, AnahitNeural)",
        "shortName": "hy-AM-AnahitNeural",
        "locale": "hy-AM",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Հայկ",
        "name": "Microsoft Server Speech Text to Speech Voice (hy-AM, HaykNeural)",
        "shortName": "hy-AM-HaykNeural",
        "locale": "hy-AM",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Ardi",
        "name": "Microsoft Server Speech Text to Speech Voice (id-ID, ArdiNeural)",
        "shortName": "id-ID-ArdiNeural",
        "locale": "id-ID",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Gadis",
        "name": "Microsoft Server Speech Text to Speech Voice (id-ID, GadisNeural)",
        "shortName": "id-ID-GadisNeural",
        "locale": "id-ID",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Guðrún",
        "name": "Microsoft Server Speech Text to Speech Voice (is-IS, GudrunNeural)",
        "shortName": "is-IS-GudrunNeural",
        "locale": "is-IS",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Gunnar",
        "name": "Microsoft Server Speech Text to Speech Voice (is-IS, GunnarNeural)",
        "shortName": "is-IS-GunnarNeural",
        "locale": "is-IS",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Alessio Multilingual",
        "name": "Microsoft Server Speech Text to Speech Voice (it-IT, AlessioMultilingualNeural)",
        "shortName": "it-IT-AlessioMultilingualNeural",
        "locale": "it-IT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Benigno",
        "name": "Microsoft Server Speech Text to Speech Voice (it-IT, BenignoNeural)",
        "shortName": "it-IT-BenignoNeural",
        "locale": "it-IT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Calimero",
        "name": "Microsoft Server Speech Text to Speech Voice (it-IT, CalimeroNeural)",
        "shortName": "it-IT-CalimeroNeural",
        "locale": "it-IT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Cataldo",
        "name": "Microsoft Server Speech Text to Speech Voice (it-IT, CataldoNeural)",
        "shortName": "it-IT-CataldoNeural",
        "locale": "it-IT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Diego",
        "name": "Microsoft Server Speech Text to Speech Voice (it-IT, DiegoNeural)",
        "shortName": "it-IT-DiegoNeural",
        "locale": "it-IT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Elsa",
        "name": "Microsoft Server Speech Text to Speech Voice (it-IT, ElsaNeural)",
        "shortName": "it-IT-ElsaNeural",
        "locale": "it-IT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Fabiola",
        "name": "Microsoft Server Speech Text to Speech Voice (it-IT, FabiolaNeural)",
        "shortName": "it-IT-FabiolaNeural",
        "locale": "it-IT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Fiamma",
        "name": "Microsoft Server Speech Text to Speech Voice (it-IT, FiammaNeural)",
        "shortName": "it-IT-FiammaNeural",
        "locale": "it-IT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Gianni",
        "name": "Microsoft Server Speech Text to Speech Voice (it-IT, GianniNeural)",
        "shortName": "it-IT-GianniNeural",
        "locale": "it-IT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Giuseppe",
        "name": "Microsoft Server Speech Text to Speech Voice (it-IT, GiuseppeNeural)",
        "shortName": "it-IT-GiuseppeNeural",
        "locale": "it-IT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Imelda",
        "name": "Microsoft Server Speech Text to Speech Voice (it-IT, ImeldaNeural)",
        "shortName": "it-IT-ImeldaNeural",
        "locale": "it-IT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Irma",
        "name": "Microsoft Server Speech Text to Speech Voice (it-IT, IrmaNeural)",
        "shortName": "it-IT-IrmaNeural",
        "locale": "it-IT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Isabella",
        "name": "Microsoft Server Speech Text to Speech Voice (it-IT, IsabellaNeural)",
        "shortName": "it-IT-IsabellaNeural",
        "locale": "it-IT",
        "styleList": [
            "cheerful",
            "chat"
        ]
    },
    {
        "gender": "Female",
        "localName": "Isabella Multilingual",
        "name": "Microsoft Server Speech Text to Speech Voice (it-IT, IsabellaMultilingualNeural)",
        "shortName": "it-IT-IsabellaMultilingualNeural",
        "locale": "it-IT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Lisandro",
        "name": "Microsoft Server Speech Text to Speech Voice (it-IT, LisandroNeural)",
        "shortName": "it-IT-LisandroNeural",
        "locale": "it-IT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Marcello Multilingual",
        "name": "Microsoft Server Speech Text to Speech Voice (it-IT, MarcelloMultilingualNeural)",
        "shortName": "it-IT-MarcelloMultilingualNeural",
        "locale": "it-IT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Palmira",
        "name": "Microsoft Server Speech Text to Speech Voice (it-IT, PalmiraNeural)",
        "shortName": "it-IT-PalmiraNeural",
        "locale": "it-IT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Pierina",
        "name": "Microsoft Server Speech Text to Speech Voice (it-IT, PierinaNeural)",
        "shortName": "it-IT-PierinaNeural",
        "locale": "it-IT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Rinaldo",
        "name": "Microsoft Server Speech Text to Speech Voice (it-IT, RinaldoNeural)",
        "shortName": "it-IT-RinaldoNeural",
        "locale": "it-IT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "ᑕᖅᑭᖅ",
        "name": "Microsoft Server Speech Text to Speech Voice (iu-Cans-CA, TaqqiqNeural)",
        "shortName": "iu-Cans-CA-TaqqiqNeural",
        "locale": "iu-Cans-CA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "ᓯᕿᓂᖅ",
        "name": "Microsoft Server Speech Text to Speech Voice (iu-Cans-CA, SiqiniqNeural)",
        "shortName": "iu-Cans-CA-SiqiniqNeural",
        "locale": "iu-Cans-CA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "ᑕᖅᑭᖅ",
        "name": "Microsoft Server Speech Text to Speech Voice (iu-Latn-CA, TaqqiqNeural)",
        "shortName": "iu-Latn-CA-TaqqiqNeural",
        "locale": "iu-Latn-CA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "ᓯᕿᓂᖅ",
        "name": "Microsoft Server Speech Text to Speech Voice (iu-Latn-CA, SiqiniqNeural)",
        "shortName": "iu-Latn-CA-SiqiniqNeural",
        "locale": "iu-Latn-CA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "七海",
        "name": "Microsoft Server Speech Text to Speech Voice (ja-JP, NanamiNeural)",
        "shortName": "ja-JP-NanamiNeural",
        "locale": "ja-JP",
        "styleList": [
            "chat",
            "customerservice",
            "cheerful"
        ]
    },
    {
        "gender": "Male",
        "localName": "勝 多言語",
        "name": "Microsoft Server Speech Text to Speech Voice (ja-JP, MasaruMultilingualNeural)",
        "shortName": "ja-JP-MasaruMultilingualNeural",
        "locale": "ja-JP",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "圭太",
        "name": "Microsoft Server Speech Text to Speech Voice (ja-JP, KeitaNeural)",
        "shortName": "ja-JP-KeitaNeural",
        "locale": "ja-JP",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "大智",
        "name": "Microsoft Server Speech Text to Speech Voice (ja-JP, DaichiNeural)",
        "shortName": "ja-JP-DaichiNeural",
        "locale": "ja-JP",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "志織",
        "name": "Microsoft Server Speech Text to Speech Voice (ja-JP, ShioriNeural)",
        "shortName": "ja-JP-ShioriNeural",
        "locale": "ja-JP",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "直紀",
        "name": "Microsoft Server Speech Text to Speech Voice (ja-JP, NaokiNeural)",
        "shortName": "ja-JP-NaokiNeural",
        "locale": "ja-JP",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "真夕",
        "name": "Microsoft Server Speech Text to Speech Voice (ja-JP, MayuNeural)",
        "shortName": "ja-JP-MayuNeural",
        "locale": "ja-JP",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "碧衣",
        "name": "Microsoft Server Speech Text to Speech Voice (ja-JP, AoiNeural)",
        "shortName": "ja-JP-AoiNeural",
        "locale": "ja-JP",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Dimas",
        "name": "Microsoft Server Speech Text to Speech Voice (jv-ID, DimasNeural)",
        "shortName": "jv-ID-DimasNeural",
        "locale": "jv-ID",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Siti",
        "name": "Microsoft Server Speech Text to Speech Voice (jv-ID, SitiNeural)",
        "shortName": "jv-ID-SitiNeural",
        "locale": "jv-ID",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "გიორგი",
        "name": "Microsoft Server Speech Text to Speech Voice (ka-GE, GiorgiNeural)",
        "shortName": "ka-GE-GiorgiNeural",
        "locale": "ka-GE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "ეკა",
        "name": "Microsoft Server Speech Text to Speech Voice (ka-GE, EkaNeural)",
        "shortName": "ka-GE-EkaNeural",
        "locale": "ka-GE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Айгүл",
        "name": "Microsoft Server Speech Text to Speech Voice (kk-KZ, AigulNeural)",
        "shortName": "kk-KZ-AigulNeural",
        "locale": "kk-KZ",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Дәулет",
        "name": "Microsoft Server Speech Text to Speech Voice (kk-KZ, DauletNeural)",
        "shortName": "kk-KZ-DauletNeural",
        "locale": "kk-KZ",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "ពិសិដ្ឋ",
        "name": "Microsoft Server Speech Text to Speech Voice (km-KH, PisethNeural)",
        "shortName": "km-KH-PisethNeural",
        "locale": "km-KH",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "ស្រីមុំ",
        "name": "Microsoft Server Speech Text to Speech Voice (km-KH, SreymomNeural)",
        "shortName": "km-KH-SreymomNeural",
        "locale": "km-KH",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "ಗಗನ್",
        "name": "Microsoft Server Speech Text to Speech Voice (kn-IN, GaganNeural)",
        "shortName": "kn-IN-GaganNeural",
        "locale": "kn-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "ಸಪ್ನಾ",
        "name": "Microsoft Server Speech Text to Speech Voice (kn-IN, SapnaNeural)",
        "shortName": "kn-IN-SapnaNeural",
        "locale": "kn-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "국민",
        "name": "Microsoft Server Speech Text to Speech Voice (ko-KR, GookMinNeural)",
        "shortName": "ko-KR-GookMinNeural",
        "locale": "ko-KR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "봉진",
        "name": "Microsoft Server Speech Text to Speech Voice (ko-KR, BongJinNeural)",
        "shortName": "ko-KR-BongJinNeural",
        "locale": "ko-KR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "서현",
        "name": "Microsoft Server Speech Text to Speech Voice (ko-KR, SeoHyeonNeural)",
        "shortName": "ko-KR-SeoHyeonNeural",
        "locale": "ko-KR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "선히",
        "name": "Microsoft Server Speech Text to Speech Voice (ko-KR, SunHiNeural)",
        "shortName": "ko-KR-SunHiNeural",
        "locale": "ko-KR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "순복",
        "name": "Microsoft Server Speech Text to Speech Voice (ko-KR, SoonBokNeural)",
        "shortName": "ko-KR-SoonBokNeural",
        "locale": "ko-KR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "유진",
        "name": "Microsoft Server Speech Text to Speech Voice (ko-KR, YuJinNeural)",
        "shortName": "ko-KR-YuJinNeural",
        "locale": "ko-KR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "인준",
        "name": "Microsoft Server Speech Text to Speech Voice (ko-KR, InJoonNeural)",
        "shortName": "ko-KR-InJoonNeural",
        "locale": "ko-KR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "지민",
        "name": "Microsoft Server Speech Text to Speech Voice (ko-KR, JiMinNeural)",
        "shortName": "ko-KR-JiMinNeural",
        "locale": "ko-KR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "현수",
        "name": "Microsoft Server Speech Text to Speech Voice (ko-KR, HyunsuNeural)",
        "shortName": "ko-KR-HyunsuNeural",
        "locale": "ko-KR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "ແກ້ວມະນີ",
        "name": "Microsoft Server Speech Text to Speech Voice (lo-LA, KeomanyNeural)",
        "shortName": "lo-LA-KeomanyNeural",
        "locale": "lo-LA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "ຈັນທະວົງ",
        "name": "Microsoft Server Speech Text to Speech Voice (lo-LA, ChanthavongNeural)",
        "shortName": "lo-LA-ChanthavongNeural",
        "locale": "lo-LA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Leonas",
        "name": "Microsoft Server Speech Text to Speech Voice (lt-LT, LeonasNeural)",
        "shortName": "lt-LT-LeonasNeural",
        "locale": "lt-LT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Ona",
        "name": "Microsoft Server Speech Text to Speech Voice (lt-LT, OnaNeural)",
        "shortName": "lt-LT-OnaNeural",
        "locale": "lt-LT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Everita",
        "name": "Microsoft Server Speech Text to Speech Voice (lv-LV, EveritaNeural)",
        "shortName": "lv-LV-EveritaNeural",
        "locale": "lv-LV",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Nils",
        "name": "Microsoft Server Speech Text to Speech Voice (lv-LV, NilsNeural)",
        "shortName": "lv-LV-NilsNeural",
        "locale": "lv-LV",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Александар",
        "name": "Microsoft Server Speech Text to Speech Voice (mk-MK, AleksandarNeural)",
        "shortName": "mk-MK-AleksandarNeural",
        "locale": "mk-MK",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Марија",
        "name": "Microsoft Server Speech Text to Speech Voice (mk-MK, MarijaNeural)",
        "shortName": "mk-MK-MarijaNeural",
        "locale": "mk-MK",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "മിഥുൻ",
        "name": "Microsoft Server Speech Text to Speech Voice (ml-IN, MidhunNeural)",
        "shortName": "ml-IN-MidhunNeural",
        "locale": "ml-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "ശോഭന",
        "name": "Microsoft Server Speech Text to Speech Voice (ml-IN, SobhanaNeural)",
        "shortName": "ml-IN-SobhanaNeural",
        "locale": "ml-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Батаа",
        "name": "Microsoft Server Speech Text to Speech Voice (mn-MN, BataaNeural)",
        "shortName": "mn-MN-BataaNeural",
        "locale": "mn-MN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Есүй",
        "name": "Microsoft Server Speech Text to Speech Voice (mn-MN, YesuiNeural)",
        "shortName": "mn-MN-YesuiNeural",
        "locale": "mn-MN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "आरोही",
        "name": "Microsoft Server Speech Text to Speech Voice (mr-IN, AarohiNeural)",
        "shortName": "mr-IN-AarohiNeural",
        "locale": "mr-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "मनोहर",
        "name": "Microsoft Server Speech Text to Speech Voice (mr-IN, ManoharNeural)",
        "shortName": "mr-IN-ManoharNeural",
        "locale": "mr-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Osman",
        "name": "Microsoft Server Speech Text to Speech Voice (ms-MY, OsmanNeural)",
        "shortName": "ms-MY-OsmanNeural",
        "locale": "ms-MY",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Yasmin",
        "name": "Microsoft Server Speech Text to Speech Voice (ms-MY, YasminNeural)",
        "shortName": "ms-MY-YasminNeural",
        "locale": "ms-MY",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Grace",
        "name": "Microsoft Server Speech Text to Speech Voice (mt-MT, GraceNeural)",
        "shortName": "mt-MT-GraceNeural",
        "locale": "mt-MT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Joseph",
        "name": "Microsoft Server Speech Text to Speech Voice (mt-MT, JosephNeural)",
        "shortName": "mt-MT-JosephNeural",
        "locale": "mt-MT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "နီလာ",
        "name": "Microsoft Server Speech Text to Speech Voice (my-MM, NilarNeural)",
        "shortName": "my-MM-NilarNeural",
        "locale": "my-MM",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "သီဟ",
        "name": "Microsoft Server Speech Text to Speech Voice (my-MM, ThihaNeural)",
        "shortName": "my-MM-ThihaNeural",
        "locale": "my-MM",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Finn",
        "name": "Microsoft Server Speech Text to Speech Voice (nb-NO, FinnNeural)",
        "shortName": "nb-NO-FinnNeural",
        "locale": "nb-NO",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Iselin",
        "name": "Microsoft Server Speech Text to Speech Voice (nb-NO, IselinNeural)",
        "shortName": "nb-NO-IselinNeural",
        "locale": "nb-NO",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Pernille",
        "name": "Microsoft Server Speech Text to Speech Voice (nb-NO, PernilleNeural)",
        "shortName": "nb-NO-PernilleNeural",
        "locale": "nb-NO",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "सागर",
        "name": "Microsoft Server Speech Text to Speech Voice (ne-NP, SagarNeural)",
        "shortName": "ne-NP-SagarNeural",
        "locale": "ne-NP",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "हेमकला",
        "name": "Microsoft Server Speech Text to Speech Voice (ne-NP, HemkalaNeural)",
        "shortName": "ne-NP-HemkalaNeural",
        "locale": "ne-NP",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Arnaud",
        "name": "Microsoft Server Speech Text to Speech Voice (nl-BE, ArnaudNeural)",
        "shortName": "nl-BE-ArnaudNeural",
        "locale": "nl-BE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Dena",
        "name": "Microsoft Server Speech Text to Speech Voice (nl-BE, DenaNeural)",
        "shortName": "nl-BE-DenaNeural",
        "locale": "nl-BE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Colette",
        "name": "Microsoft Server Speech Text to Speech Voice (nl-NL, ColetteNeural)",
        "shortName": "nl-NL-ColetteNeural",
        "locale": "nl-NL",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Fenna",
        "name": "Microsoft Server Speech Text to Speech Voice (nl-NL, FennaNeural)",
        "shortName": "nl-NL-FennaNeural",
        "locale": "nl-NL",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Maarten",
        "name": "Microsoft Server Speech Text to Speech Voice (nl-NL, MaartenNeural)",
        "shortName": "nl-NL-MaartenNeural",
        "locale": "nl-NL",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "ସୁକାନ୍ତ",
        "name": "Microsoft Server Speech Text to Speech Voice (or-IN, SukantNeural)",
        "shortName": "or-IN-SukantNeural",
        "locale": "or-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "ସୁଭାସିନୀ",
        "name": "Microsoft Server Speech Text to Speech Voice (or-IN, SubhasiniNeural)",
        "shortName": "or-IN-SubhasiniNeural",
        "locale": "or-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "ਓਜਸ",
        "name": "Microsoft Server Speech Text to Speech Voice (pa-IN, OjasNeural)",
        "shortName": "pa-IN-OjasNeural",
        "locale": "pa-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "ਵਾਨੀ",
        "name": "Microsoft Server Speech Text to Speech Voice (pa-IN, VaaniNeural)",
        "shortName": "pa-IN-VaaniNeural",
        "locale": "pa-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Agnieszka",
        "name": "Microsoft Server Speech Text to Speech Voice (pl-PL, AgnieszkaNeural)",
        "shortName": "pl-PL-AgnieszkaNeural",
        "locale": "pl-PL",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Marek",
        "name": "Microsoft Server Speech Text to Speech Voice (pl-PL, MarekNeural)",
        "shortName": "pl-PL-MarekNeural",
        "locale": "pl-PL",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Zofia",
        "name": "Microsoft Server Speech Text to Speech Voice (pl-PL, ZofiaNeural)",
        "shortName": "pl-PL-ZofiaNeural",
        "locale": "pl-PL",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": " ګل نواز",
        "name": "Microsoft Server Speech Text to Speech Voice (ps-AF, GulNawazNeural)",
        "shortName": "ps-AF-GulNawazNeural",
        "locale": "ps-AF",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "لطيفه",
        "name": "Microsoft Server Speech Text to Speech Voice (ps-AF, LatifaNeural)",
        "shortName": "ps-AF-LatifaNeural",
        "locale": "ps-AF",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Antônio",
        "name": "Microsoft Server Speech Text to Speech Voice (pt-BR, AntonioNeural)",
        "shortName": "pt-BR-AntonioNeural",
        "locale": "pt-BR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Brenda",
        "name": "Microsoft Server Speech Text to Speech Voice (pt-BR, BrendaNeural)",
        "shortName": "pt-BR-BrendaNeural",
        "locale": "pt-BR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Donato",
        "name": "Microsoft Server Speech Text to Speech Voice (pt-BR, DonatoNeural)",
        "shortName": "pt-BR-DonatoNeural",
        "locale": "pt-BR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Elza",
        "name": "Microsoft Server Speech Text to Speech Voice (pt-BR, ElzaNeural)",
        "shortName": "pt-BR-ElzaNeural",
        "locale": "pt-BR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Fabio",
        "name": "Microsoft Server Speech Text to Speech Voice (pt-BR, FabioNeural)",
        "shortName": "pt-BR-FabioNeural",
        "locale": "pt-BR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Francisca",
        "name": "Microsoft Server Speech Text to Speech Voice (pt-BR, FranciscaNeural)",
        "shortName": "pt-BR-FranciscaNeural",
        "locale": "pt-BR",
        "styleList": [
            "calm"
        ]
    },
    {
        "gender": "Female",
        "localName": "Giovanna",
        "name": "Microsoft Server Speech Text to Speech Voice (pt-BR, GiovannaNeural)",
        "shortName": "pt-BR-GiovannaNeural",
        "locale": "pt-BR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Humberto",
        "name": "Microsoft Server Speech Text to Speech Voice (pt-BR, HumbertoNeural)",
        "shortName": "pt-BR-HumbertoNeural",
        "locale": "pt-BR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Julio",
        "name": "Microsoft Server Speech Text to Speech Voice (pt-BR, JulioNeural)",
        "shortName": "pt-BR-JulioNeural",
        "locale": "pt-BR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Leila",
        "name": "Microsoft Server Speech Text to Speech Voice (pt-BR, LeilaNeural)",
        "shortName": "pt-BR-LeilaNeural",
        "locale": "pt-BR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Leticia",
        "name": "Microsoft Server Speech Text to Speech Voice (pt-BR, LeticiaNeural)",
        "shortName": "pt-BR-LeticiaNeural",
        "locale": "pt-BR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Manuela",
        "name": "Microsoft Server Speech Text to Speech Voice (pt-BR, ManuelaNeural)",
        "shortName": "pt-BR-ManuelaNeural",
        "locale": "pt-BR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Nicolau",
        "name": "Microsoft Server Speech Text to Speech Voice (pt-BR, NicolauNeural)",
        "shortName": "pt-BR-NicolauNeural",
        "locale": "pt-BR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Thalita",
        "name": "Microsoft Server Speech Text to Speech Voice (pt-BR, ThalitaNeural)",
        "shortName": "pt-BR-ThalitaNeural",
        "locale": "pt-BR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Thalita multilíngue",
        "name": "Microsoft Server Speech Text to Speech Voice (pt-BR, ThalitaMultilingualNeural)",
        "shortName": "pt-BR-ThalitaMultilingualNeural",
        "locale": "pt-BR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Valerio",
        "name": "Microsoft Server Speech Text to Speech Voice (pt-BR, ValerioNeural)",
        "shortName": "pt-BR-ValerioNeural",
        "locale": "pt-BR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Yara",
        "name": "Microsoft Server Speech Text to Speech Voice (pt-BR, YaraNeural)",
        "shortName": "pt-BR-YaraNeural",
        "locale": "pt-BR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Duarte",
        "name": "Microsoft Server Speech Text to Speech Voice (pt-PT, DuarteNeural)",
        "shortName": "pt-PT-DuarteNeural",
        "locale": "pt-PT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Fernanda",
        "name": "Microsoft Server Speech Text to Speech Voice (pt-PT, FernandaNeural)",
        "shortName": "pt-PT-FernandaNeural",
        "locale": "pt-PT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Raquel",
        "name": "Microsoft Server Speech Text to Speech Voice (pt-PT, RaquelNeural)",
        "shortName": "pt-PT-RaquelNeural",
        "locale": "pt-PT",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Alina",
        "name": "Microsoft Server Speech Text to Speech Voice (ro-RO, AlinaNeural)",
        "shortName": "ro-RO-AlinaNeural",
        "locale": "ro-RO",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Emil",
        "name": "Microsoft Server Speech Text to Speech Voice (ro-RO, EmilNeural)",
        "shortName": "ro-RO-EmilNeural",
        "locale": "ro-RO",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Дария",
        "name": "Microsoft Server Speech Text to Speech Voice (ru-RU, DariyaNeural)",
        "shortName": "ru-RU-DariyaNeural",
        "locale": "ru-RU",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Дмитрий",
        "name": "Microsoft Server Speech Text to Speech Voice (ru-RU, DmitryNeural)",
        "shortName": "ru-RU-DmitryNeural",
        "locale": "ru-RU",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Светлана",
        "name": "Microsoft Server Speech Text to Speech Voice (ru-RU, SvetlanaNeural)",
        "shortName": "ru-RU-SvetlanaNeural",
        "locale": "ru-RU",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "තිළිණි",
        "name": "Microsoft Server Speech Text to Speech Voice (si-LK, ThiliniNeural)",
        "shortName": "si-LK-ThiliniNeural",
        "locale": "si-LK",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "සමීර",
        "name": "Microsoft Server Speech Text to Speech Voice (si-LK, SameeraNeural)",
        "shortName": "si-LK-SameeraNeural",
        "locale": "si-LK",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Lukáš",
        "name": "Microsoft Server Speech Text to Speech Voice (sk-SK, LukasNeural)",
        "shortName": "sk-SK-LukasNeural",
        "locale": "sk-SK",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Viktória",
        "name": "Microsoft Server Speech Text to Speech Voice (sk-SK, ViktoriaNeural)",
        "shortName": "sk-SK-ViktoriaNeural",
        "locale": "sk-SK",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Petra",
        "name": "Microsoft Server Speech Text to Speech Voice (sl-SI, PetraNeural)",
        "shortName": "sl-SI-PetraNeural",
        "locale": "sl-SI",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Rok",
        "name": "Microsoft Server Speech Text to Speech Voice (sl-SI, RokNeural)",
        "shortName": "sl-SI-RokNeural",
        "locale": "sl-SI",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Muuse",
        "name": "Microsoft Server Speech Text to Speech Voice (so-SO, MuuseNeural)",
        "shortName": "so-SO-MuuseNeural",
        "locale": "so-SO",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Ubax",
        "name": "Microsoft Server Speech Text to Speech Voice (so-SO, UbaxNeural)",
        "shortName": "so-SO-UbaxNeural",
        "locale": "so-SO",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Anila",
        "name": "Microsoft Server Speech Text to Speech Voice (sq-AL, AnilaNeural)",
        "shortName": "sq-AL-AnilaNeural",
        "locale": "sq-AL",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Ilir",
        "name": "Microsoft Server Speech Text to Speech Voice (sq-AL, IlirNeural)",
        "shortName": "sq-AL-IlirNeural",
        "locale": "sq-AL",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Nicholas",
        "name": "Microsoft Server Speech Text to Speech Voice (sr-Latn-RS, NicholasNeural)",
        "shortName": "sr-Latn-RS-NicholasNeural",
        "locale": "sr-Latn-RS",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Sophie",
        "name": "Microsoft Server Speech Text to Speech Voice (sr-Latn-RS, SophieNeural)",
        "shortName": "sr-Latn-RS-SophieNeural",
        "locale": "sr-Latn-RS",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Никола",
        "name": "Microsoft Server Speech Text to Speech Voice (sr-RS, NicholasNeural)",
        "shortName": "sr-RS-NicholasNeural",
        "locale": "sr-RS",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Софија",
        "name": "Microsoft Server Speech Text to Speech Voice (sr-RS, SophieNeural)",
        "shortName": "sr-RS-SophieNeural",
        "locale": "sr-RS",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Jajang",
        "name": "Microsoft Server Speech Text to Speech Voice (su-ID, JajangNeural)",
        "shortName": "su-ID-JajangNeural",
        "locale": "su-ID",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Tuti",
        "name": "Microsoft Server Speech Text to Speech Voice (su-ID, TutiNeural)",
        "shortName": "su-ID-TutiNeural",
        "locale": "su-ID",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Hillevi",
        "name": "Microsoft Server Speech Text to Speech Voice (sv-SE, HilleviNeural)",
        "shortName": "sv-SE-HilleviNeural",
        "locale": "sv-SE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Mattias",
        "name": "Microsoft Server Speech Text to Speech Voice (sv-SE, MattiasNeural)",
        "shortName": "sv-SE-MattiasNeural",
        "locale": "sv-SE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Sofie",
        "name": "Microsoft Server Speech Text to Speech Voice (sv-SE, SofieNeural)",
        "shortName": "sv-SE-SofieNeural",
        "locale": "sv-SE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Rafiki",
        "name": "Microsoft Server Speech Text to Speech Voice (sw-KE, RafikiNeural)",
        "shortName": "sw-KE-RafikiNeural",
        "locale": "sw-KE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Zuri",
        "name": "Microsoft Server Speech Text to Speech Voice (sw-KE, ZuriNeural)",
        "shortName": "sw-KE-ZuriNeural",
        "locale": "sw-KE",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Daudi",
        "name": "Microsoft Server Speech Text to Speech Voice (sw-TZ, DaudiNeural)",
        "shortName": "sw-TZ-DaudiNeural",
        "locale": "sw-TZ",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Rehema",
        "name": "Microsoft Server Speech Text to Speech Voice (sw-TZ, RehemaNeural)",
        "shortName": "sw-TZ-RehemaNeural",
        "locale": "sw-TZ",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "பல்லவி",
        "name": "Microsoft Server Speech Text to Speech Voice (ta-IN, PallaviNeural)",
        "shortName": "ta-IN-PallaviNeural",
        "locale": "ta-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "வள்ளுவர்",
        "name": "Microsoft Server Speech Text to Speech Voice (ta-IN, ValluvarNeural)",
        "shortName": "ta-IN-ValluvarNeural",
        "locale": "ta-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "குமார்",
        "name": "Microsoft Server Speech Text to Speech Voice (ta-LK, KumarNeural)",
        "shortName": "ta-LK-KumarNeural",
        "locale": "ta-LK",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "சரண்யா",
        "name": "Microsoft Server Speech Text to Speech Voice (ta-LK, SaranyaNeural)",
        "shortName": "ta-LK-SaranyaNeural",
        "locale": "ta-LK",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "கனி",
        "name": "Microsoft Server Speech Text to Speech Voice (ta-MY, KaniNeural)",
        "shortName": "ta-MY-KaniNeural",
        "locale": "ta-MY",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "சூர்யா",
        "name": "Microsoft Server Speech Text to Speech Voice (ta-MY, SuryaNeural)",
        "shortName": "ta-MY-SuryaNeural",
        "locale": "ta-MY",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "அன்பு",
        "name": "Microsoft Server Speech Text to Speech Voice (ta-SG, AnbuNeural)",
        "shortName": "ta-SG-AnbuNeural",
        "locale": "ta-SG",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "வெண்பா",
        "name": "Microsoft Server Speech Text to Speech Voice (ta-SG, VenbaNeural)",
        "shortName": "ta-SG-VenbaNeural",
        "locale": "ta-SG",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "మోహన్",
        "name": "Microsoft Server Speech Text to Speech Voice (te-IN, MohanNeural)",
        "shortName": "te-IN-MohanNeural",
        "locale": "te-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "శ్రుతి",
        "name": "Microsoft Server Speech Text to Speech Voice (te-IN, ShrutiNeural)",
        "shortName": "te-IN-ShrutiNeural",
        "locale": "te-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "นิวัฒน์",
        "name": "Microsoft Server Speech Text to Speech Voice (th-TH, NiwatNeural)",
        "shortName": "th-TH-NiwatNeural",
        "locale": "th-TH",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "เปรมวดี",
        "name": "Microsoft Server Speech Text to Speech Voice (th-TH, PremwadeeNeural)",
        "shortName": "th-TH-PremwadeeNeural",
        "locale": "th-TH",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "อัจฉรา",
        "name": "Microsoft Server Speech Text to Speech Voice (th-TH, AcharaNeural)",
        "shortName": "th-TH-AcharaNeural",
        "locale": "th-TH",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Ahmet",
        "name": "Microsoft Server Speech Text to Speech Voice (tr-TR, AhmetNeural)",
        "shortName": "tr-TR-AhmetNeural",
        "locale": "tr-TR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Emel",
        "name": "Microsoft Server Speech Text to Speech Voice (tr-TR, EmelNeural)",
        "shortName": "tr-TR-EmelNeural",
        "locale": "tr-TR",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Остап",
        "name": "Microsoft Server Speech Text to Speech Voice (uk-UA, OstapNeural)",
        "shortName": "uk-UA-OstapNeural",
        "locale": "uk-UA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Поліна",
        "name": "Microsoft Server Speech Text to Speech Voice (uk-UA, PolinaNeural)",
        "shortName": "uk-UA-PolinaNeural",
        "locale": "uk-UA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "سلمان",
        "name": "Microsoft Server Speech Text to Speech Voice (ur-IN, SalmanNeural)",
        "shortName": "ur-IN-SalmanNeural",
        "locale": "ur-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "گل",
        "name": "Microsoft Server Speech Text to Speech Voice (ur-IN, GulNeural)",
        "shortName": "ur-IN-GulNeural",
        "locale": "ur-IN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "اسد",
        "name": "Microsoft Server Speech Text to Speech Voice (ur-PK, AsadNeural)",
        "shortName": "ur-PK-AsadNeural",
        "locale": "ur-PK",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "عظمیٰ",
        "name": "Microsoft Server Speech Text to Speech Voice (ur-PK, UzmaNeural)",
        "shortName": "ur-PK-UzmaNeural",
        "locale": "ur-PK",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Madina",
        "name": "Microsoft Server Speech Text to Speech Voice (uz-UZ, MadinaNeural)",
        "shortName": "uz-UZ-MadinaNeural",
        "locale": "uz-UZ",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Sardor",
        "name": "Microsoft Server Speech Text to Speech Voice (uz-UZ, SardorNeural)",
        "shortName": "uz-UZ-SardorNeural",
        "locale": "uz-UZ",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Hoài My",
        "name": "Microsoft Server Speech Text to Speech Voice (vi-VN, HoaiMyNeural)",
        "shortName": "vi-VN-HoaiMyNeural",
        "locale": "vi-VN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Nam Minh",
        "name": "Microsoft Server Speech Text to Speech Voice (vi-VN, NamMinhNeural)",
        "shortName": "vi-VN-NamMinhNeural",
        "locale": "vi-VN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "云哲",
        "name": "Microsoft Server Speech Text to Speech Voice (wuu-CN, YunzheNeural)",
        "shortName": "wuu-CN-YunzheNeural",
        "locale": "wuu-CN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "晓彤",
        "name": "Microsoft Server Speech Text to Speech Voice (wuu-CN, XiaotongNeural)",
        "shortName": "wuu-CN-XiaotongNeural",
        "locale": "wuu-CN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "云松",
        "name": "Microsoft Server Speech Text to Speech Voice (yue-CN, YunSongNeural)",
        "shortName": "yue-CN-YunSongNeural",
        "locale": "yue-CN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "晓敏",
        "name": "Microsoft Server Speech Text to Speech Voice (yue-CN, XiaoMinNeural)",
        "shortName": "yue-CN-XiaoMinNeural",
        "locale": "yue-CN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "云健",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, YunjianNeural)",
        "shortName": "zh-CN-YunjianNeural",
        "locale": "zh-CN",
        "styleList": [
            "narration-relaxed",
            "sports-commentary",
            "sports-commentary-excited",
            "angry",
            "disgruntled",
            "cheerful",
            "sad",
            "serious",
            "depressed",
            "documentary-narration"
        ]
    },
    {
        "gender": "Male",
        "localName": "云夏",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, YunxiaNeural)",
        "shortName": "zh-CN-YunxiaNeural",
        "locale": "zh-CN",
        "styleList": [
            "calm",
            "fearful",
            "cheerful",
            "angry",
            "sad"
        ]
    },
    {
        "gender": "Male",
        "localName": "云希",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, YunxiNeural)",
        "shortName": "zh-CN-YunxiNeural",
        "locale": "zh-CN",
        "styleList": [
            "narration-relaxed",
            "embarrassed",
            "fearful",
            "cheerful",
            "disgruntled",
            "serious",
            "angry",
            "sad",
            "depressed",
            "chat",
            "assistant",
            "newscast"
        ]
    },
    {
        "gender": "Male",
        "localName": "云扬",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, YunyangNeural)",
        "shortName": "zh-CN-YunyangNeural",
        "locale": "zh-CN",
        "styleList": [
            "customerservice",
            "narration-professional",
            "newscast-casual"
        ]
    },
    {
        "gender": "Male",
        "localName": "云杰",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, YunjieNeural)",
        "shortName": "zh-CN-YunjieNeural",
        "locale": "zh-CN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "云枫",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, YunfengNeural)",
        "shortName": "zh-CN-YunfengNeural",
        "locale": "zh-CN",
        "styleList": [
            "angry",
            "disgruntled",
            "cheerful",
            "fearful",
            "sad",
            "serious",
            "depressed"
        ]
    },
    {
        "gender": "Male",
        "localName": "云泽",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, YunzeNeural)",
        "shortName": "zh-CN-YunzeNeural",
        "locale": "zh-CN",
        "styleList": [
            "calm",
            "fearful",
            "cheerful",
            "disgruntled",
            "serious",
            "angry",
            "sad",
            "depressed",
            "documentary-narration"
        ]
    },
    {
        "gender": "Male",
        "localName": "云皓",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, YunhaoNeural)",
        "shortName": "zh-CN-YunhaoNeural",
        "locale": "zh-CN",
        "styleList": [
            "advertisement-upbeat"
        ]
    },
    {
        "gender": "Male",
        "localName": "云逸 多语言",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, YunyiMultilingualNeural)",
        "shortName": "zh-CN-YunyiMultilingualNeural",
        "locale": "zh-CN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "云野",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, YunyeNeural)",
        "shortName": "zh-CN-YunyeNeural",
        "locale": "zh-CN",
        "styleList": [
            "embarrassed",
            "calm",
            "fearful",
            "cheerful",
            "disgruntled",
            "serious",
            "angry",
            "sad"
        ]
    },
    {
        "gender": "Female",
        "localName": "晓伊",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoyiNeural)",
        "shortName": "zh-CN-XiaoyiNeural",
        "locale": "zh-CN",
        "styleList": [
            "angry",
            "disgruntled",
            "affectionate",
            "cheerful",
            "fearful",
            "sad",
            "embarrassed",
            "serious",
            "gentle"
        ]
    },
    {
        "gender": "Female",
        "localName": "晓双",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoshuangNeural)",
        "shortName": "zh-CN-XiaoshuangNeural",
        "locale": "zh-CN",
        "styleList": [
            "chat"
        ]
    },
    {
        "gender": "Female",
        "localName": "晓宇 多语言",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoyuMultilingualNeural)",
        "shortName": "zh-CN-XiaoyuMultilingualNeural",
        "locale": "zh-CN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "晓悠",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoyouNeural)",
        "shortName": "zh-CN-XiaoyouNeural",
        "locale": "zh-CN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "晓晓",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoxiaoNeural)",
        "shortName": "zh-CN-XiaoxiaoNeural",
        "locale": "zh-CN",
        "styleList": [
            "assistant",
            "chat",
            "customerservice",
            "newscast",
            "affectionate",
            "angry",
            "calm",
            "cheerful",
            "disgruntled",
            "fearful",
            "gentle",
            "lyrical",
            "sad",
            "serious",
            "poetry-reading",
            "friendly",
            "chat-casual",
            "whispering",
            "sorry",
            "excited"
        ]
    },
    {
        "gender": "Female",
        "localName": "晓晓 多语言",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoxiaoMultilingualNeural)",
        "shortName": "zh-CN-XiaoxiaoMultilingualNeural",
        "locale": "zh-CN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "晓晓 方言",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoxiaoDialectsNeural)",
        "shortName": "zh-CN-XiaoxiaoDialectsNeural",
        "locale": "zh-CN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "晓柔",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaorouNeural)",
        "shortName": "zh-CN-XiaorouNeural",
        "locale": "zh-CN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "晓梦",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaomengNeural)",
        "shortName": "zh-CN-XiaomengNeural",
        "locale": "zh-CN",
        "styleList": [
            "chat"
        ]
    },
    {
        "gender": "Female",
        "localName": "晓涵",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaohanNeural)",
        "shortName": "zh-CN-XiaohanNeural",
        "locale": "zh-CN",
        "styleList": [
            "calm",
            "fearful",
            "cheerful",
            "disgruntled",
            "serious",
            "angry",
            "sad",
            "gentle",
            "affectionate",
            "embarrassed"
        ]
    },
    {
        "gender": "Female",
        "localName": "晓甄",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaozhenNeural)",
        "shortName": "zh-CN-XiaozhenNeural",
        "locale": "zh-CN",
        "styleList": [
            "angry",
            "disgruntled",
            "cheerful",
            "fearful",
            "sad",
            "serious"
        ]
    },
    {
        "gender": "Female",
        "localName": "晓睿",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoruiNeural)",
        "shortName": "zh-CN-XiaoruiNeural",
        "locale": "zh-CN",
        "styleList": [
            "calm",
            "fearful",
            "angry",
            "sad"
        ]
    },
    {
        "gender": "Female",
        "localName": "晓秋",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoqiuNeural)",
        "shortName": "zh-CN-XiaoqiuNeural",
        "locale": "zh-CN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "晓辰",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaochenNeural)",
        "shortName": "zh-CN-XiaochenNeural",
        "locale": "zh-CN",
        "styleList": [
            "livecommercial"
        ]
    },
    {
        "gender": "Female",
        "localName": "晓辰 多语言",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaochenMultilingualNeural)",
        "shortName": "zh-CN-XiaochenMultilingualNeural",
        "locale": "zh-CN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "晓颜",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoyanNeural)",
        "shortName": "zh-CN-XiaoyanNeural",
        "locale": "zh-CN",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "晓墨",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaomoNeural)",
        "shortName": "zh-CN-XiaomoNeural",
        "locale": "zh-CN",
        "styleList": [
            "embarrassed",
            "calm",
            "fearful",
            "cheerful",
            "disgruntled",
            "serious",
            "angry",
            "sad",
            "depressed",
            "affectionate",
            "gentle",
            "envious"
        ]
    },
    {
        "gender": "Male",
        "localName": "云奇 广西",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN-guangxi, YunqiNeural)",
        "shortName": "zh-CN-guangxi-YunqiNeural",
        "locale": "zh-CN-guangxi",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "云登",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN-henan, YundengNeural)",
        "shortName": "zh-CN-henan-YundengNeural",
        "locale": "zh-CN-henan",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "云彪 辽宁",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN-liaoning, YunbiaoNeural)",
        "shortName": "zh-CN-liaoning-YunbiaoNeural",
        "locale": "zh-CN-liaoning",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "晓北 辽宁",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN-liaoning, XiaobeiNeural)",
        "shortName": "zh-CN-liaoning-XiaobeiNeural",
        "locale": "zh-CN-liaoning",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "晓妮",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN-shaanxi, XiaoniNeural)",
        "shortName": "zh-CN-shaanxi-XiaoniNeural",
        "locale": "zh-CN-shaanxi",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "云翔",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN-shandong, YunxiangNeural)",
        "shortName": "zh-CN-shandong-YunxiangNeural",
        "locale": "zh-CN-shandong",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "云希 四川",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-CN-sichuan, YunxiNeural)",
        "shortName": "zh-CN-sichuan-YunxiNeural",
        "locale": "zh-CN-sichuan",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "曉佳",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-HK, HiuGaaiNeural)",
        "shortName": "zh-HK-HiuGaaiNeural",
        "locale": "zh-HK",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "曉曼",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-HK, HiuMaanNeural)",
        "shortName": "zh-HK-HiuMaanNeural",
        "locale": "zh-HK",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "雲龍",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-HK, WanLungNeural)",
        "shortName": "zh-HK-WanLungNeural",
        "locale": "zh-HK",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "曉臻",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-TW, HsiaoChenNeural)",
        "shortName": "zh-TW-HsiaoChenNeural",
        "locale": "zh-TW",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "曉雨",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-TW, HsiaoYuNeural)",
        "shortName": "zh-TW-HsiaoYuNeural",
        "locale": "zh-TW",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "雲哲",
        "name": "Microsoft Server Speech Text to Speech Voice (zh-TW, YunJheNeural)",
        "shortName": "zh-TW-YunJheNeural",
        "locale": "zh-TW",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Female",
        "localName": "Thando",
        "name": "Microsoft Server Speech Text to Speech Voice (zu-ZA, ThandoNeural)",
        "shortName": "zu-ZA-ThandoNeural",
        "locale": "zu-ZA",
        "styleList": [
            ""
        ]
    },
    {
        "gender": "Male",
        "localName": "Themba",
        "name": "Microsoft Server Speech Text to Speech Voice (zu-ZA, ThembaNeural)",
        "shortName": "zu-ZA-ThembaNeural",
        "locale": "zu-ZA",
        "styleList": [
            ""
        ]
    }
]

export { voices };