import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import "antd/dist/antd.css";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <App />
);

setInterval(function () {
    var els = document.querySelectorAll("input[autocomplete='off']");
    els.forEach(function (el) {
        el.setAttribute("autocomplete", "new-password");
    });
}, 250);