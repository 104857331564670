import { React, dtos, observable, observer, ui, serviceClient, moment, _, splitIntoWords, selectFilter, makeObservable } from "../common";
import { GetValuesResponse, ValueTypes } from "../dtos";
import AudioRecorder from "../audio-recorder";
import AutoComplete from "../autocomplete";
import FlowReferenceValue from "./flow-reference-value";

interface Props {
    valueType: dtos.ValueTypes,
    accountId: string;
    customerId?: string;
    value: dtos.Value;
    field: dtos.DataField;
    onFieldChanged?: () => void;
    readOnly?: boolean;
}

@observer
export default class FlowValueEditor extends React.Component<Props> {
    @observable fieldValues: Array<dtos.FieldValue> = [];
    @observable showAudioRecorder = false;
    @observable recordCallback: (f: dtos.FileInfo) => void = () => { };
    @observable isPlayingAudio = false;
    @observable showStringSelectAsText = false;
    @observable uniquePrefix = "";
    @observable uniqueLength = 4;

    didLoadSpecialType = false;
    audioPlayer = React.createRef<HTMLAudioElement>();

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    componentDidMount() {
        this.uniquePrefix = window.localStorage.getItem("value-editor.prefix") || "";
        var length = window.localStorage.getItem("value-editor.length") || "4";
        this.uniqueLength = parseInt(length);
    }

    async loadSpecial<T>(request: dtos.IReturn<GetValuesResponse>) {
        if (this.didLoadSpecialType) return;
        this.didLoadSpecialType = true;
        let response = await serviceClient.get(request);
        (this.fieldValues as any).replace(response.fieldValues);
    }

    async loadTypeValues(url: string) {
        if (this.didLoadSpecialType) return;
        this.didLoadSpecialType = true;
        let response = await fetch(serviceClient.baseUrl + url + (url.indexOf("?") >= 0 ? "&" : "?") + "accountId=" + this.props.accountId, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application.json",
                "Accept": "application/json"
            }
        });
        let json = await response.json();
        (this.fieldValues as any).replace(json.fieldValues);
    }

    createField(value: dtos.Value, field: dtos.DataField) {

    }

    setFieldValue(value: dtos.Value, key: string, val: any) {
        if (this.props.readOnly) return;
        value[key] = val;
        if (this.props.onFieldChanged) this.props.onFieldChanged();
        this.forceUpdate();
    }

    flowReferenceField(value: dtos.Value, field: dtos.DataField) {
        return (
            <FlowReferenceValue customerId={this.props.customerId} accountId={this.props.accountId} value={value} field={field} onChanged={v => this.setFieldValue(value, "stringValue", v)} />
        )
    }

    endpointField(value: dtos.Value, field: dtos.DataField, type: dtos.EndpointTypes) {
        return (
            <AutoComplete preloadCustomerId={this.props.customerId} type={field.type} value={value != null ? value.stringValue || "" : ""} placeholder={splitIntoWords(field.name)} onChanged={v => this.setFieldValue(value, "stringValue", v)} />
        );
    }

    autoCompleteField(value: dtos.Value, field: dtos.DataField, isStatic: boolean = false) {
        return (
            <AutoComplete type={field.type} static={isStatic} value={value != null ? value.stringValue || "" : ""} placeholder={splitIntoWords(field.name)} onChanged={v => this.setFieldValue(value, "stringValue", v)} />
        );
    }

    selectField(value: dtos.Value, field: dtos.DataField) {
        if (this.props.readOnly) {
            let val = _.find(this.fieldValues, v => v.value == value.stringValue);
            return <span>{val == null ? "" : val.displayName}</span>;
        }

        return (
            <ui.Select showSearch dropdownMatchSelectWidth={false} filterOption={selectFilter} value={value.stringValue || ""} onChange={v => this.setFieldValue(value, "stringValue", v)}>
                <ui.Select.Option value="">(None)</ui.Select.Option>
                {this.fieldValues.map(c => <ui.Select.Option title={c.displayName} key={c.value} value={c.value}><div dangerouslySetInnerHTML={{ __html: c.displayName }} /></ui.Select.Option>)}
            </ui.Select>
        );
    }

    recordAudioFieldValue(value: dtos.Value) {
        this.recordCallback = (f: dtos.FileInfo) => {
            var fv = new dtos.FieldValue({ displayName: (f.customerName ? f.customerName + ": " : "") + f.fileName, value: f.id });
            this.fieldValues = [fv, ...this.fieldValues];
            this.setFieldValue(value, "stringValue", f.id);
            this.showAudioRecorder = false;
        };
        this.showAudioRecorder = true;
    }

    async toggleAudio(fileId: string) {
        if (this.isPlayingAudio) {
            this.audioPlayer.current.pause();
            this.isPlayingAudio = false;
        } else {
            let files = await serviceClient.get(new dtos.ListFiles({ specificIds: [fileId] }));
            let file = files.items[0];
            this.audioPlayer.current.src = file.uri;
            this.audioPlayer.current.play();
            this.audioPlayer.current.onended = () => this.isPlayingAudio = false;
            this.isPlayingAudio = true;
        }
    }

    async onUploadAudioFile(info, value: dtos.Value) {
        if (info.file.status == "done") {
            let f = info.file.response as dtos.FileInfo;
            var fv = new dtos.FieldValue({ displayName: (f.customerName ? f.customerName + ": " : "") + f.fileName, value: f.id });
            this.fieldValues = [fv, ...this.fieldValues];
            this.setFieldValue(value, "stringValue", f.id);
        }
    }

    audioField(value: dtos.Value, field: dtos.DataField) {
        let menu = <ui.Menu>
            <ui.Menu.Item onClick={() => this.recordAudioFieldValue(value)}><i className="fa fa-microphone" />&nbsp;Record Audio</ui.Menu.Item>
            <ui.Menu.Item>
                <ui.Upload showUploadList={false} name="file" data={{ accountId: this.props.accountId, customerId: this.props.customerId || "" }} withCredentials={true} headers={{ "X-Requested-With": null }} action={serviceClient.createUrlFromDto("POST", new dtos.NewFile())} onChange={info => this.onUploadAudioFile(info, value)}>
                    <i className="fa fa-upload" style={{ marginRight: -4 }} /> Upload File
                </ui.Upload>
            </ui.Menu.Item>
        </ui.Menu>;

        return (
            <div>
                <ui.Input.Group compact>
                    <ui.Dropdown overlay={menu}><ui.Button type="primary"><i className="fa fa-microphone" /></ui.Button></ui.Dropdown>
                    <AutoComplete type={field.type} customerId={this.props.customerId} value={value.stringValue || ""} placeholder={splitIntoWords(field.name)} onChanged={v => this.setFieldValue(value, "stringValue", v)} />
                    {value.stringValue && <ui.Button onClick={() => this.toggleAudio(value.stringValue)}><i className={this.isPlayingAudio ? "fa fa-pause" : "fa fa-play"} /></ui.Button>}
                </ui.Input.Group>
                <p style={{ margin: 0, marginTop: -5, padding: 0 }}><small>Audio files must be MP3 or WAV format</small></p>
                <audio ref={this.audioPlayer} />
                <AudioRecorder customerId={this.props.customerId} visible={this.showAudioRecorder} onClosed={() => this.showAudioRecorder = false} accountId={this.props.accountId} onSaved={this.recordCallback} />
            </div>
        );
    }

    dateField(value: dtos.Value, field: dtos.DataField) {
        if (this.props.readOnly) return <span>{moment(value.stringValue || moment.now()).format("LL")}</span>;

        return (
            <ui.DatePicker value={moment(value.stringValue || moment.now())} onChange={(m, str) => this.setFieldValue(value, "stringValue", str)} />
        );
    }

    timeField(value: dtos.Value, field: dtos.DataField) {
        if (this.props.readOnly) return <span>{moment(value.stringValue || "8:00AM", "h:mm a").format("hh:mm a")}</span>;

        return (
            <ui.TimePicker format="h:mm a" use12Hours value={moment(value.stringValue || "8:00AM", "h:mm a")} onChange={(m, str) => this.setFieldValue(value, "stringValue", str)} />
        );
    }

    async generateUnique(value: dtos.Value, field: dtos.DataField) {
        let response = await serviceClient.get(new dtos.GetUniqueEndpointValue({
            accountId: this.props.accountId,
            fieldName: field.name,
            length: this.uniqueLength,
            prefix: this.uniquePrefix
        }));
        value.stringValue = response.value;
        window.localStorage.setItem("value-editor.prefix", this.uniquePrefix);
        window.localStorage.setItem("value-editor.length", this.uniqueLength.toString());
        this.forceUpdate();
    }

    stringField(value: dtos.Value, field: dtos.DataField) {
        if (this.props.readOnly) return <span>{value.stringValue}</span>;
        var isEndpointField = field.hasOwnProperty("endpointType");
        var uniqueFieldNames = ["number", "extension", "digits"]
        var shouldShowUnique = isEndpointField && uniqueFieldNames.find(f => field.name.toLowerCase().indexOf(f) >= 0) != null;

        if (field.possibleValues != null && field.possibleValues.length > 0) {
            return (
                <ui.Input.Group compact>
                    {!this.showStringSelectAsText && <ui.Select style={{ minWidth: 300 }} showSearch filterOption={selectFilter} value={value.stringValue || ""} dropdownMatchSelectWidth={false} onChange={v => this.setFieldValue(value, "stringValue", v)}>
                        <ui.Select.Option value="">(None)</ui.Select.Option>
                        {field.possibleValues.map(v => <ui.Select.Option key={v} value={v}>{splitIntoWords(v)}</ui.Select.Option>)}
                    </ui.Select>}
                    {this.showStringSelectAsText && <ui.Input style={{ width: 250 }} value={value.stringValue} onChange={ev => this.setFieldValue(value, "stringValue", ev.target.value)} />}
                    <ui.Button onClick={() => this.showStringSelectAsText = !this.showStringSelectAsText}><i className="fa fa-edit" /></ui.Button>
                </ui.Input.Group>
            );
        } else {
            if (field.uiHint == dtos.UIHints.LargeText) {
                return (
                    <ui.Input.TextArea placeholder={field.defaultValue == null ? null : field.defaultValue.stringValue || null} value={value.stringValue} onChange={ev => this.setFieldValue(value, "stringValue", ev.target.value)} />
                );
            } else if (field.uiHint === dtos.UIHints.Password) {
                return (
                    <ui.Input.Password placeholder={"Leave blank to keep current value"} value={value.stringValue} onChange={ev => this.setFieldValue(value, "stringValue", ev.target.value)} />
                );
            } else {
                var input = <ui.Input.TextArea placeholder={field.defaultValue == null ? null : field.defaultValue.stringValue || null} autoSize value={value.stringValue} onChange={ev => this.setFieldValue(value, "stringValue", ev.target.value)} />;
                if (shouldShowUnique) {
                    var generator = <ui.Form layout="inline">
                        <ui.Form.Item>
                            <ui.InputNumber value={this.uniqueLength} onChange={v => this.uniqueLength = v} size="small" placeholder="Length" />
                        </ui.Form.Item>
                        <ui.Form.Item>
                            <ui.Input.Search size="small" onSearch={() => this.generateUnique(value, field)} placeholder="Prefix" value={this.uniquePrefix} onChange={ev => this.uniquePrefix = ev.target.value} enterButton={<ui.Button><i className="fa fa-clipboard-check" /></ui.Button>} />
                        </ui.Form.Item>
                        <p style={{ margin: 0, padding: 0 }}><small>Use this utility to generate a unique field value. Typically used for extensions</small></p>
                    </ui.Form>;

                    return (
                        <ui.Popover title="Generate Unique" placement="topRight" content={generator}>
                            {input}
                        </ui.Popover>
                    );
                } else {
                    return input;
                }
            }

        }
    }

    booleanField(value: dtos.Value, field: dtos.DataField) {
        if (this.props.readOnly) return <span>{value.boolValue ? "YES" : "NO"}</span>;

        return (
            <ui.Select showSearch filterOption={selectFilter} dropdownMatchSelectWidth={false} value={value.boolValue == true ? "true" : "false"} onChange={v => this.setFieldValue(value, "boolValue", v == "true" ? true : false)}>
                <ui.Select.Option value={"true"}>Yes</ui.Select.Option>
                <ui.Select.Option value={"false"}>No</ui.Select.Option>
            </ui.Select>
        );
    }

    numberField(value: dtos.Value, field: dtos.DataField) {
        if (this.props.readOnly) return <span>{value.numberValue}</span>;

        return (
            <ui.InputNumber placeholder={field.defaultValue == null || field.defaultValue.numberValue == null ? null : field.defaultValue.numberValue.toString()} value={value.numberValue ? value.numberValue : null} onChange={ev => this.setFieldValue(value, "numberValue", ev)} />
        );
    }

    listField(value: dtos.Value, field: dtos.DataField) {
        if (!value.listValue) value.listValue = [];

        let list = value.listValue;
        let dataType = field.listType;

        const addItem = () => {
            let structData = new dtos.Struct();
            list.push(structData);
            this.forceUpdate();
        };

        const removeItem = (structData) => {
            let idx = list.indexOf(structData);
            list.splice(idx, 1);
            this.forceUpdate();
        }

        for (let structData of list) {
            for (let f of field.listType.fields) {
                if (!structData.hasOwnProperty(f.name)) {
                    structData[f.name] = new dtos.Value();
                }
            }
        }

        return (
            <>
                {list.length > 0 && <ui.Collapse accordion key={field.id} style={{ marginBottom: 8 }}>
                    {list.map((structData, index) =>
                        <ui.Collapse.Panel key={field.id + "." + index} header={`Item ${index + 1}`}>
                            {dataType.fields.map(f => <ui.Form.Item key={f.id} label={splitIntoWords(f.name)}>
                                <FlowValueEditor field={f} value={structData[f.name]} valueType={f.type} accountId={this.props.accountId} customerId={this.props.customerId} />
                            </ui.Form.Item>)}
                            <ui.Button.Group><ui.Popconfirm title="Remove item?" onConfirm={() => removeItem(structData)}><ui.Button danger>Remove</ui.Button></ui.Popconfirm></ui.Button.Group>
                        </ui.Collapse.Panel>)}
                </ui.Collapse>}
                <ui.Button.Group><ui.Button onClick={addItem}>Add Item</ui.Button></ui.Button.Group>
            </>
        );
    }

    structField(value: dtos.Value, field: dtos.DataField) {
        if (!value.structValue) value.structValue = new dtos.Struct();
        let structData = value.structValue;
        let dataType = field.structType;

        for (let f of field.structType.fields) {
            if (!structData.hasOwnProperty(f.name)) {
                structData[f.name] = new dtos.Value();
            }
        }

        return (
            <ui.Collapse key={field.id} style={{ marginBottom: 8 }}>
                <ui.Collapse.Panel header={splitIntoWords(field.name)} key={field.id}>
                    <div className={field.uiHint == dtos.UIHints.InlineForm ? "ant-form ant-form-inline" : ""}>
                        {dataType.fields.map(f => <ui.Form.Item key={f.id} label={f.type === ValueTypes.Struct ? null : splitIntoWords(f.name)}>
                            <FlowValueEditor field={f} value={structData[f.name]} valueType={f.type} accountId={this.props.accountId} customerId={this.props.customerId} />
                        </ui.Form.Item>)}
                    </div>
                </ui.Collapse.Panel>
            </ui.Collapse>
        );
    }

    render() {
        let { value, valueType, field } = this.props;

        switch (valueType) {
            case dtos.ValueTypes.Transition:
                throw "Not supported";
            case dtos.ValueTypes.Struct:
                return this.structField(value, field);
            case dtos.ValueTypes.List:
                return this.listField(value, field);
            case dtos.ValueTypes.String:
                return this.stringField(value, field);
            case dtos.ValueTypes.Boolean:
                return this.booleanField(value, field);
            case dtos.ValueTypes.Number:
                return this.numberField(value, field);
            case dtos.ValueTypes.Date:
                return this.dateField(value, field);
            case dtos.ValueTypes.Time:
                return this.timeField(value, field);
            case dtos.ValueTypes.Custom:
                this.loadTypeValues(field.customFieldValuesUrl);
                return this.selectField(value, field);
            case dtos.ValueTypes.AudioFile:
                return this.audioField(value, field);
            case dtos.ValueTypes.File:
                return this.autoCompleteField(value, field);
            case dtos.ValueTypes.TimeZoneId:
                this.loadSpecial(new dtos.GetTimeZoneFieldValues({ accountId: this.props.accountId }));
                return this.selectField(value, field);
            case dtos.ValueTypes.PhoneNumber:
                return this.endpointField(value, field, dtos.EndpointTypes.PhoneNumber);
            case dtos.ValueTypes.Assistant:
                return this.endpointField(value, field, dtos.EndpointTypes.Assistant);
            case dtos.ValueTypes.FaxNumber:
                return this.endpointField(value, field, dtos.EndpointTypes.FaxNumber);
            case dtos.ValueTypes.Customer:
                return this.autoCompleteField(value, field);
            case dtos.ValueTypes.User:
                return this.autoCompleteField(value, field);
            case dtos.ValueTypes.Endpoint:
                return this.autoCompleteField(value, field);
            case dtos.ValueTypes.Flow:
                return this.autoCompleteField(value, field, true);
            case dtos.ValueTypes.Team:
                return this.autoCompleteField(value, field, true);
            case dtos.ValueTypes.Integration:
                return this.autoCompleteField(value, field);
            case dtos.ValueTypes.EmailAccount:
                this.loadSpecial(new dtos.GetEmailAccountFieldValues({ accountId: this.props.accountId }));
                return this.selectField(value, field);
            case dtos.ValueTypes.FlowReference:
                return this.flowReferenceField(value, field);
            default:
                return <div>Not supported</div>;
        }
    }
}