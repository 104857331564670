import { React, withRouter, serviceClient, dtos, observer, observable, ui, _, Link, Observer, moment, Routes, Route, shared, selectFilter, makeObservable } from "./common";
import AccountsIndex from "./accounts/index";
import AccountDetails from "./accounts/details";
import EndpointIndex from "./endpoints/index";
import EndpointDetails from "./endpoints/details";
import CustomerIndex from "./customers/index";
import CustomerDetails from "./customers/details";
import FlowIndex from "./flows/index";
import FlowDetails from "./flows/details";
import SystemSettings from "./system/settings";
import DashboardIndex from "./dashboard/index";
import DocumentationIndex from "./system/documentation";
import UserActivity from "./dashboard/activity";

import AutoFocus from "./autofocus";
import IntegrationIndex from "./integrations";
import IntegrationDetails from "./integrations/details";
import { NavLink } from "react-router-dom";

interface Props {
    loginEmail?: string;
}

class ManagerRedirect extends React.Component<Props> {
    componentDidMount() {
        var prev = localStorage.getItem("ManagerIndex.location");
        if (prev && prev != "#/") {
            window.location.hash = prev;
        } else {
            window.location.hash = shared.getHomePage();
        }
    }
    render() { return <div />; }
}

@observer
class ManagerIndex extends React.Component<Props> {
    @observable newPassword = "";
    @observable confirmNewPassword = "";
    @observable showChangePassword = false;
    @observable showChangeAccount = false;
    @observable newAccountId = "";
    @observable accountIds: string[] = [];
    @observable accountNames: string[] = [];
    @observable logoUri = "";

    unlisten: Function = null;

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    componentDidMount() {
        function eventListener() {
            localStorage.setItem("ManagerIndex.location", window.location.hash);
        };

        window.addEventListener("hashchange", eventListener);
        this.unlisten = () => window.removeEventListener("hashchange", eventListener);

        serviceClient.get(new dtos.AppGetLogo({ accountId: shared.accountId })).then(r => {
            this.logoUri = r.logoUri;
        })
    }

    componentWillUnmount() {
        this.unlisten();
    }

    setCollapsed(b) {
        shared.collapsed = b;
        localStorage.setItem("ManagerIndex.collapsed", b ? "true" : "false");
        this.forceUpdate();
    }

    async updatePassword() {
        if (!this.newPassword) {
            ui.message.error("Must enter a new password");
            return;
        }

        if (this.newPassword != this.confirmNewPassword) {
            ui.message.error("Passwords do not match");
            return;
        }

        await serviceClient.post(new dtos.ChangeMyPassword({ newPassword: this.newPassword }));
        this.newPassword = this.confirmNewPassword = "";
        this.showChangePassword = false;
        ui.message.success("Password updated successfully");
    }

    getRootKey() {
        let location = (this.props as any).location.pathname;
        var tokens = location.split('/');
        var numTokens = Math.min(tokens.length, 2);
        var rootKey = "";
        for (var i = 1; i < numTokens; i++) {
            rootKey += "/" + tokens[i];
        }
        return rootKey;
    }

    async signOut() {
        await serviceClient.post(new dtos.Authenticate({ provider: "logout" }));
        localStorage.clear();
        window.location.reload();
    }

    async showAccount() {
        let auth = await serviceClient.get(new dtos.GetAuthStatus());
        this.newAccountId = shared.accountId;
        this.accountIds = auth.accountIds;
        this.accountNames = auth.accountNames;
        this.showChangeAccount = true;
    }

    changeAccount() {
        if (!this.newAccountId) {
            ui.message.error("Must select a new account");
            return;
        }

        var min = localStorage.getItem("ManagerIndex.minMode");
        localStorage.clear();
        localStorage.setItem("accountId", this.newAccountId);
        localStorage.setItem("ManagerIndex.minMode", min);
        window.location.hash = "#/endpoints";
        window.location.reload();
    }

    render() {
        const { loginEmail } = this.props;

        const mainMenu = (
            <ui.Menu id="nav" style={{ marginLeft: 100 }} mode="horizontal" selectedKeys={["dashboard"]}>
                {/* <ui.Menu.Item key="dashboard"><span>Dashboard</span></ui.Menu.Item>
                <ui.Menu.Item key="docs"><a href="http://wiki.evo.tech/voice" target="_blank">Documentation</a></ui.Menu.Item>
                <ui.Menu.Item key="support"><a href="https://evo.tech/contact-us/" target="_blank">Support</a></ui.Menu.Item> */}
            </ui.Menu>
        );

        function getLabel(text: string) {
            return <span>{text}</span>;
        }

        const sideMenu = (
            <ui.Menu id="main-menu" mode="vertical" selectedKeys={[(this.props as any).location.pathname, this.getRootKey()]}>
                <ui.Menu.Divider />
                <ui.Menu.SubMenu title={<span className="submenu-title-wrapper"><i className="fa fa-user" />{getLabel(this.props.loginEmail)}</span>}>
                    <ui.Menu.Item key="change-pass" onClick={() => this.showChangePassword = true}><i className="fa fa-key" /> Change Password</ui.Menu.Item>
                    {shared.hasMultipleAccounts && <ui.Menu.Divider />}
                    {shared.hasMultipleAccounts && <ui.Menu.Item key="change-id" onClick={() => this.showAccount()}><i className="fa fa-cabinet-filing" /> Change Account</ui.Menu.Item>}
                    <ui.Menu.Divider />
                    <ui.Menu.Item key="sign-out" onClick={() => this.signOut()}><i className="fa fa-sign-out" /> Sign Out</ui.Menu.Item>
                </ui.Menu.SubMenu>
                <ui.Menu.Divider />
                {shared.canViewSettings() && shared.isCustomer() && <ui.Menu.Item key="/system-settings"><NavLink to="/system-settings"><span><i className="fa fa-home" /> {getLabel("Dashboard")}</span></NavLink></ui.Menu.Item>}
                {shared.canViewDashboard() && <ui.Menu.Item key="/dashboard"><NavLink to="/dashboard"><span><i className="fa fa-home" /> {getLabel("Dashboard")}</span></NavLink></ui.Menu.Item>}
                {shared.canViewEndpoints() && <ui.Menu.Item key="/endpoints"><NavLink to="/endpoints"><span><i className="fa fa-phone-office" /> {getLabel("Endpoints")}</span></NavLink></ui.Menu.Item>}
                {shared.canViewCustomers() && <ui.Menu.Item key="/customers"><NavLink to="/customers"><span><i className="fa fa-users" /> {getLabel("Customers")}</span></NavLink></ui.Menu.Item>}
                {shared.canViewFlows() && <ui.Menu.Item key="/flows"><NavLink to="/flows"><span><i className="fa fa-water" /> {getLabel("Flows")}</span></NavLink></ui.Menu.Item>}
                {shared.canViewIntegrations() && <ui.Menu.Item key="/integrations"><NavLink to="/integrations"><span><i className="fa fa-puzzle-piece" /> {getLabel("Integrations")}</span></NavLink></ui.Menu.Item>}
                {shared.canViewSettings() && !shared.isCustomer() && <ui.Menu.Item key="/system-settings"><NavLink to="/system-settings"><span><i className="fa fa-cogs" /> {getLabel("System")}</span></NavLink></ui.Menu.Item>}
                {shared.canViewAccounts() && <ui.Menu.Item key="/accounts"><NavLink to="/accounts"><span><i className="fa fa-cabinet-filing" /> {getLabel("Accounts")}</span></NavLink></ui.Menu.Item>}

            </ui.Menu>
        );

        const routes = (
            <Routes>
                <Route path="/accounts/:accountId" element={<AccountDetails />} />
                <Route path="/accounts" element={<AccountsIndex />} />
                <Route path="/endpoints/:id" element={<EndpointDetails />} />
                <Route path="/endpoints" element={<EndpointIndex />} />
                <Route path="/customers/:id" element={<CustomerDetails />} />
                <Route path="/customers" element={<CustomerIndex />} />
                <Route path="/integrations/:id" element={<IntegrationDetails />} />
                <Route path="/integrations" element={<IntegrationIndex />} />
                <Route path="/flows/:id" element={<FlowDetails />} />
                <Route path="/flows" element={<FlowIndex />} />
                <Route path="/system-settings" element={<SystemSettings />} />
                <Route path="/dashboard" element={<DashboardIndex />} />
                <Route path="/documentation" element={<DocumentationIndex />} />
                <Route path="/activity" element={<UserActivity accountId={shared.accountId} />} />
                <Route path="*" element={<ManagerRedirect />} />
            </Routes>
        );


        return (
            <div>
                <ui.PageHeader backIcon={this.logoUri ? <img src={this.logoUri} style={{ maxHeight: 60 }} /> : <i className="far fa-comment-alt-smile" style={{ color: shared.collapsed ? "#1890ff" : "rgba(0,0,0,.65)" }} />} onBack={() => this.setCollapsed(!shared.collapsed)} title={this.logoUri ? "" : "Evo Voice"} subTitle={this.logoUri ? "" : shared.accountName} extra={shared.collapsed ? null : mainMenu} />
                <ui.Row gutter={12} style={{ marginLeft: 0, marginRight: 0 }}>
                    <ui.Layout style={{ minHeight: "100px" }}>
                        <ui.Layout.Sider theme="light" collapsible collapsed={shared.collapsed} onCollapse={b => this.setCollapsed(b)}>
                            {sideMenu}
                        </ui.Layout.Sider>
                        <ui.Layout style={{ background: "white", paddingLeft: 16, paddingRight: 16 }}>
                            {routes}
                        </ui.Layout>
                    </ui.Layout>
                </ui.Row>
                <ui.Modal title="Change Password" visible={this.showChangePassword} okText="Update Password" onCancel={() => this.showChangePassword = false} onOk={() => this.updatePassword()}>
                    <ui.Form layout="vertical" onKeyPress={ev => ev.which == 13 && this.updatePassword()}>
                        <ui.Form.Item label="New Password">
                            <ui.Input.Password placeholder="Enter your new password" value={this.newPassword} onChange={ev => this.newPassword = ev.target.value} />
                        </ui.Form.Item>
                        <ui.Form.Item label="Confirm Password">
                            <ui.Input.Password placeholder="Confirm your new password" value={this.confirmNewPassword} onChange={ev => this.confirmNewPassword = ev.target.value} />
                        </ui.Form.Item>
                    </ui.Form>
                </ui.Modal>
                {this.showChangeAccount && <ui.Modal title="Change Account" visible={this.showChangeAccount} okText="Change" onCancel={() => this.showChangeAccount = false} onOk={() => this.changeAccount()}>
                    <ui.Form layout="vertical" onKeyPress={ev => ev.which == 13 && this.changeAccount()}>
                        <ui.Form.Item label="Account">
                            <ui.Select autoFocus showSearch filterOption={selectFilter} value={this.newAccountId} onChange={ev => this.newAccountId = ev} dropdownMatchSelectWidth={false}>
                                {this.accountIds.map((id, i) => <ui.Select.Option key={id} value={id}>{this.accountNames[i]}</ui.Select.Option>)}
                            </ui.Select>
                        </ui.Form.Item>
                    </ui.Form>
                </ui.Modal>}
            </div>
        );
    }
}

export default withRouter(ManagerIndex);