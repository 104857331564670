import { React } from "./common";

interface Props {
    title: string;
}

export default class PageTitle extends React.Component<Props> {
    componentDidMount() {
        document.title = this.props.title + " - Evo Voice -" + (window as any).evoVoiceVersion;;
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.title != prevProps.title) {
            document.title = this.props.title + " - Evo Voice -" + (window as any).evoVoiceVersion;
        }
    }

    componentWillUnmount() {
        document.title = "Evo Voice -" + (window as any).evoVoiceVersion;;
    }

    render() {
        return <div />;
    }
}