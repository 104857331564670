import { React, ui, dtos, _, observer, observable, makeObservable } from "../common";
import FlowCanvas from "./canvas";

interface Props {
    exit: dtos.FlowExit;
    canvas: FlowCanvas;
    selected: boolean;
    onClick: () => void;
    onDelete: () => void;
}

export default class FlowExit extends React.Component<Props> {
    div = React.createRef<HTMLDivElement>();

    constructor(props) {
        super(props);
        makeObservable(this);
    }
    
    componentDidMount() {
        this.props.canvas.addExit("exit-" + this.props.exit.name);
    }

    onClick(ev) {
        this.props.onClick();
        this.div.current.focus();

    }

    onKeyDown(ev) {
        if (ev.keyCode == 46) {
            this.props.canvas.deleteExit(this.props.exit.name);
            this.props.onDelete();
        }
    }

    render() {
        let { exit, selected } = this.props;

        return (
            <div ref={this.div} id={"exit-" + exit.name} onClick={ev => this.onClick(ev)} className={"flow-node " + (this.props.selected ? "selected" : "")} style={{ left: exit.ui.x, top: exit.ui.y }} onKeyDown={ev => this.onKeyDown(ev)} tabIndex={0}>
                <ui.Table dataSource={[{id:"1"}]} pagination={false} size="small" rowKey={rec => rec.id} className={selected ? "selected" : ""}>
                    <ui.Table.Column dataIndex="name" title={<span><i className="fa fa-door-open" />Exit</span>} render={(text, rec) =>
                        <span>{exit.name}</span>} />
                    <ui.Table.Column width={30} align="center" render={(text, rec) => <span></span>} />
                </ui.Table>
            </div>
        );
    }
}