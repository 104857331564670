import { useEffect, useState } from "react";
import { React, serviceClient, dtos, observer, shared, observable, ui, Link, selectFilter, _, splitIntoWords, uuid, moveArray, removeArray } from "../common";
import CustomerList from "../customers/list";
import { CrmContactInfo, CrmCustomerInfo, CustomerInfo, EntityIntegrationData, IntegrationData, IntegrationInfo, ListCrmContacts, ListCrmCustomers, MapCrmCustomer, MapCrmUser } from "../dtos";
import EndpointList from "../endpoints/list";

interface Props {
    customerId: string;
    integrationId: string;
}

function CrmUserMapper(props: { integrationId: string, endpoint: dtos.EndpointInfo, crmContacts: CrmContactInfo[] }) {
    const { endpoint, crmContacts, integrationId } = props;
    const [oldId, setOldId] = useState(endpoint.integrationData[integrationId]?.thirdPartyId || "");
    const [id, setId] = useState(endpoint.integrationData[integrationId]?.thirdPartyId || "");

    function undo() {
        setId(oldId);
    }

    function save() {
        serviceClient.post(new MapCrmUser({
            integrationId,
            userId: endpoint.id,
            mapsToId: id
        })).then(function (r) {
            setOldId(id);
            ui.message.success("Saved");
        });
    }

    return <ui.Space>
        <ui.Select showSearch filterOption={selectFilter} value={id} onChange={setId} dropdownMatchSelectWidth style={{ width: 300 }}>
            <ui.Select.Option value="">(None)</ui.Select.Option>
            {crmContacts.map(c => <ui.Select.Option value={c.id} key={c.id}>{c.displayName}</ui.Select.Option>)}
        </ui.Select>
        {oldId != id && <ui.Button onClick={save} type="primary">Save</ui.Button>}
        {oldId != id && <ui.Button onClick={undo}>Undo</ui.Button>}
    </ui.Space>;
}

export function CrmUserMapping(props: Props) {
    const [crmContacts, setCrmContacts] = useState<CrmContactInfo[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(function loadContacts() {
        setLoading(true);
        serviceClient.get(new ListCrmContacts({ integrationId: props.integrationId })).then(function (r) {
            setCrmContacts(r.contacts);
        }).finally(function() {
            //setLoading(false);
        });
    }, []);

    return <EndpointList type={dtos.EndpointTypes.User}>
        <ui.Table.Column title="Maps to" render={(text, rec: dtos.EndpointInfo) => <>
            {loading && <ui.Spin />} <CrmUserMapper integrationId={props.integrationId} endpoint={rec} crmContacts={crmContacts} />
        </>} />
    </EndpointList>
}