import { React, serviceClient, dtos, observer, observable, ui, _, Link, Observer, moment, splitIntoWords, shared, makeObservable, cache } from "./common";
import { UploadChangeParam } from "antd/lib/upload";
import ValueEditor from "./flows/value-editor";

interface Props {
    accountId: string;
    visible: boolean;
    onClosed: () => void;
    onImported: () => void;
}

@observer
export default class ImportWindow extends React.Component<Props> {
    @observable newCustomers: Array<dtos.CustomerInfo> = [];
    @observable updatedCustomers: Array<dtos.CustomerInfo> = [];

    @observable newUsers: Array<dtos.EndpointInfo> = [];
    @observable updatedUsers: Array<dtos.EndpointInfo> = [];

    @observable updatedPhoneNumbers: Array<dtos.EndpointInfo> = [];
    @observable newPhoneNumbers: Array<dtos.EndpointInfo> = [];

    @observable errors: Array<string> = [];
    @observable customerFields: Array<dtos.CustomerDataField> = [];
    @observable endpointFields: Array<dtos.EndpointDataField> = [];

    @observable loading = false;
    @observable tab = "customers";
    @observable importMessage = "";

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    componentDidMount() {
        this.init(this.props.accountId);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.accountId != prevProps.accountId) {
            this.init(this.props.accountId);
        }
    }

    async init(accountId: string) {
        this.customerFields = [];
        if (!accountId) return;
        let ss = await cache.getSystemSettings();
        this.customerFields = ss.customerFields;
        this.endpointFields = ss.endpointFields;
    }

    onUploadSpreadsheetChange(info: UploadChangeParam) {
        if (info.file.status == "uploading") {
            this.importMessage = "";
            this.loading = true;
        } else if (info.file.status == "done") {
            let result = info.file.response as dtos.ImportSpreadsheetResult;
            this.newCustomers = result.newCustomers;
            this.updatedCustomers = result.updatedCustomers;
            this.newUsers = result.newUsers;
            this.updatedUsers = result.updatedUsers;
            this.updatedPhoneNumbers = result.updatedPhoneNumbers;
            this.newPhoneNumbers = result.newPhoneNumbers;
            this.errors = result.errors;
            this.props.onImported();
            this.loading = false;
            if (this.errors.length > 0) {
                this.tab = "errors";
            } else {
                this.tab = "customers";
            }
            this.importMessage = `${result.changesApplied ? "APPLIED:" : "PREVIEW:"}\r\n${result.errors.length} errors\r\n${result.newCustomers.length} new customers/${result.updatedCustomers.length} updated customers\r\n${result.newUsers.length} new users/${result.updatedUsers.length} updated users\r\n${result.newPhoneNumbers.length} new phone numbers/${result.updatedPhoneNumbers.length} updated phone numbers`;
            this.forceUpdate();
            result.changesApplied && this.props.onClosed();
        } else if (info.file.status == "error") {
            this.loading = false;
            ui.message.error(info.file.response.responseStatus.message);
        }
    }

    getCustomerDataText(cust: dtos.CustomerInfo, f: dtos.CustomerDataField) {
        var val = cust.data[f.name] as dtos.Value;
        if (!val) return <span />;
        return <ValueEditor readOnly field={f} value={val} accountId={this.props.accountId} valueType={f.type} />;
    }

    getEndpointDataText(cust: dtos.EndpointInfo, f: dtos.EndpointDataField) {
        var val = cust.data[f.name] as dtos.Value;
        if (!val) return <span />;
        return <ValueEditor readOnly field={f} value={val} accountId={this.props.accountId} valueType={f.type} />;
    }

    getFlowParams(flowParams: dtos.Struct) {
        var params = [];
        for (let kvp in flowParams) {
            var fieldName = kvp;
            var fieldValue = flowParams[fieldName] as dtos.Value;
            if (!fieldValue) continue;
            if (fieldValue.stringValue) {
                params.push(<span><strong>{splitIntoWords(fieldName)}</strong>: {fieldValue.stringValue}</span>);
            } else if (fieldValue.numberValue) {
                params.push(<span><strong>{splitIntoWords(fieldName)}</strong>: {fieldValue.numberValue}</span>);
            } else if (fieldValue.boolValue) {
                params.push(<span><strong>{splitIntoWords(fieldName)}</strong>: {fieldValue.boolValue}</span>);
            }
        }
        return <div>{params.map(p => <span>{p}&nbsp;&nbsp;</span>)}</div>;
    }

    render() {
        let { visible, onClosed } = this.props;
        let pagination = { defaultPageSize: 5, pageSizeOptions: ["5", "10", "25", "50", "100"], showSizeChanger: true };
        return (
            <ui.Modal width="75%" visible={visible} onCancel={onClosed} title="Import Data" okButtonProps={{ hidden: true }} cancelText="Close">
                <div>
                    <p>Import data in the Voice Spreadsheet format</p>
                    <p>It is highly recommended that you <strong>take a snapshot first</strong> in case you have to rollback</p>

                    <ui.Upload showUploadList={false} name="file" data={{ accountId: this.props.accountId, applyChanges: false }} withCredentials={true} headers={{ "X-Requested-With": null }} action={serviceClient.createUrlFromDto("POST", new dtos.ImportSpreadsheet())} onChange={info => this.onUploadSpreadsheetChange(info)}>
                        <ui.Button style={{ marginTop: 16, marginRight: 16 }} type="primary"><i className="fa fa-upload" />&nbsp;Upload and Preview</ui.Button>
                    </ui.Upload>

                    <ui.Upload showUploadList={false} name="file" data={{ accountId: this.props.accountId, applyChanges: true }} withCredentials={true} headers={{ "X-Requested-With": null }} action={serviceClient.createUrlFromDto("POST", new dtos.ImportSpreadsheet())} onChange={info => this.onUploadSpreadsheetChange(info)}>
                        <ui.Button style={{ marginTop: 16 }} danger><i className="fa fa-upload" />&nbsp;Apply Changes</ui.Button>
                    </ui.Upload>

                    <pre>{this.importMessage}</pre>

                    <ui.Tabs activeKey={this.tab} onChange={t => this.tab = t}>
                        {this.errors.length > 0 && <ui.Tabs.TabPane key="errors" tab="Errors">
                            <ui.Table loading={this.loading} dataSource={this.errors.map((e, i) => ({ id: i.toString(), error: e }))} rowKey="id">
                                <ui.Table.Column dataIndex="error" title="Error" />
                            </ui.Table>
                        </ui.Tabs.TabPane>}

                        <ui.Tabs.TabPane key="customers" tab="Customers">
                            <ui.Table style={{ overflowX: "auto" }} pagination={pagination} loading={this.loading} dataSource={this.newCustomers.concat(this.updatedCustomers)} rowKey="id">
                                <ui.Table.Column dataIndex="name" title="Name" render={(text, rec: dtos.CustomerInfo) => <span>{rec.name} {this.newCustomers.indexOf(rec) >= 0 && <small><strong>(new)</strong></small>}</span>} />
                                <ui.Table.Column dataIndex="breadcrumb" title="Parent" render={(text, rec: dtos.CustomerInfo) =>
                                    <ui.Breadcrumb>
                                        {rec.breadcrumb.map((bc, i) => i < rec.breadcrumb.length - 1 && <ui.Breadcrumb.Item key={bc.id}><Link to={`/customers/${bc.id}`}>{bc.name}</Link></ui.Breadcrumb.Item>)}
                                    </ui.Breadcrumb>
                                } />
                                <ui.Table.Column title="Tags" render={(text, rec: dtos.CustomerInfo) =>
                                    <div>
                                        {rec.tags.map(t => <ui.Tag key={t.id} color={t.color.toLowerCase()}>{t.name}</ui.Tag>)}
                                    </div>} />
                                {this.customerFields.map(f => <ui.Table.Column key={f.name} dataIndex={f.name} title={f.name} render={(text, rec: dtos.CustomerInfo) =>
                                    <span>{this.getCustomerDataText(rec, f)}</span>} />)}
                            </ui.Table>
                        </ui.Tabs.TabPane>
                        <ui.Tabs.TabPane key="users" tab="Users">
                            <ui.Table style={{ overflowX: "auto" }} pagination={pagination} loading={this.loading} dataSource={this.newUsers.concat(this.updatedUsers)} rowKey="id">
                                <ui.Table.Column title="Name" dataIndex="displayName" render={(text, rec: dtos.EndpointInfo) =>
                                    <span><i style={{ marginRight: 5 }} className="fa fa-user" />
                                        {rec.displayName}
                                        {rec.userMode == dtos.UserModes.Sip && <small>&nbsp;(SIP)</small>}
                                        {rec.userMode == dtos.UserModes.DataOnly && <small>&nbsp;(Data Only)</small>}
                                        {rec.userMode == dtos.UserModes.SoftPhone && <small>&nbsp;(Soft Phone)</small>}
                                        {rec.userMode == dtos.UserModes.Flow && <small>&nbsp;(Flow)</small>}

                                        <span>{this.newUsers.indexOf(rec) >= 0 && <small><strong>(new)</strong></small>}</span>
                                    </span>} />
                                <ui.Table.Column dataIndex="customerBreadcrumb" title="Customer" render={(text, rec: dtos.EndpointInfo) =>
                                    <ui.Breadcrumb>
                                        {rec.customerBreadcrumb.map((bc, i) => <ui.Breadcrumb.Item key={bc.id}><Link to={`/customers/${bc.id}`}>{bc.name}</Link></ui.Breadcrumb.Item>)}
                                    </ui.Breadcrumb>
                                } />

                                <ui.Table.Column title="Flow" dataIndex="voiceFlowName" render={(text, rec: dtos.EndpointInfo) => rec.flowId && <Link to={`/flows/${rec.flowId}`}>{rec.flowName}</Link>} />
                                <ui.Table.Column title="Params" dataIndex="flowParams" render={(text, rec: dtos.EndpointInfo) => this.getFlowParams(rec.flowParams)} />
                                <ui.Table.Column title="Tags" render={(text, rec: dtos.EndpointInfo) =>
                                    <div>
                                        {rec.tags.map(t => <ui.Tag key={t.id} color={t.color.toLowerCase()}>{t.name}</ui.Tag>)}
                                    </div>} />
                                {this.endpointFields.filter(f => !f.endpointType || f.endpointType == dtos.EndpointTypes.User).map(f => <ui.Table.Column key={f.name} dataIndex={f.name} title={f.name} render={(text, rec: dtos.EndpointInfo) =>
                                    <span>{this.getEndpointDataText(rec, f)}</span>} />)}
                            </ui.Table>
                        </ui.Tabs.TabPane>
                        <ui.Tabs.TabPane key="phoneNumbers" tab="Phone Numbers">
                            <ui.Table style={{ overflowX: "auto" }} pagination={pagination} loading={this.loading} dataSource={this.newPhoneNumbers.concat(this.updatedPhoneNumbers)} rowKey="id">
                                <ui.Table.Column title="Name" dataIndex="displayName" render={(text, rec: dtos.EndpointInfo) =>
                                    <span>
                                        <i style={{ marginRight: 5 }} className="fa fa-hashtag" />{rec.displayName}
                                        <span>{this.newPhoneNumbers.indexOf(rec) >= 0 && <small><strong> (new)</strong></small>}</span>
                                    </span>
                                } />
                                <ui.Table.Column dataIndex="customerBreadcrumb" title="Customer" render={(text, rec: dtos.EndpointInfo) =>
                                    <ui.Breadcrumb>
                                        {rec.customerBreadcrumb.map((bc, i) => <ui.Breadcrumb.Item key={bc.id}><Link to={`/customers/${bc.id}`}>{bc.name}</Link></ui.Breadcrumb.Item>)}
                                    </ui.Breadcrumb>
                                } />

                                <ui.Table.Column title="Flow" dataIndex="voiceFlowName" render={(text, rec: dtos.EndpointInfo) => rec.flowId && <Link to={`/flows/${rec.flowId}`}>{rec.flowName}</Link>} />
                                <ui.Table.Column title="Params" dataIndex="flowParams" render={(text, rec: dtos.EndpointInfo) => this.getFlowParams(rec.flowParams)} />
                                <ui.Table.Column title="Tags" render={(text, rec: dtos.EndpointInfo) =>
                                    <div>
                                        {rec.tags.map(t => <ui.Tag key={t.id} color={t.color.toLowerCase()}>{t.name}</ui.Tag>)}
                                    </div>} />
                                {this.endpointFields.filter(f => !f.endpointType || f.endpointType == dtos.EndpointTypes.PhoneNumber).map(f => <ui.Table.Column key={f.name} dataIndex={f.name} title={f.name} render={(text, rec: dtos.EndpointInfo) =>
                                    <span>{this.getEndpointDataText(rec, f)}</span>} />)}
                            </ui.Table>
                        </ui.Tabs.TabPane>
                    </ui.Tabs>
                </div>
            </ui.Modal>
        );
    }
}