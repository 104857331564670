import { React, serviceClient, dtos, observer, observable, ui, Link, _, splitIntoWords, uuid, selectFilter, shared, moment, moveArray, removeArray, makeObservable } from "../common";
import { GetEndpoint, Schedule, SetAgentState, SchedulingRule, FlowInfo, ListFlows, Struct, EndpointInfo, Value, ValueTypes, GetInheritedSchedule, SimpleSchedulingRuleTypes, SchedulingRuleFrequency, DayOfWeek, ScheduleDay } from "../dtos";
import FlowParamsEditor from "./flow-params-editor";

interface Props {
    endpointId: string;
    onChanged: (schedule: Schedule) => void;
}

interface State {
    endpoint: EndpointInfo;
    schedule: Schedule;
    flows: FlowInfo[];
    editFlow: FlowInfo;
    editRule: SchedulingRule;
    showEditRule: boolean;
    customerStateNames: string[];
}

export default class SimpleScheduleEditor extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            endpoint: null,
            schedule: null,
            flows: [],
            editFlow: null,
            editRule: null,
            showEditRule: false,
            customerStateNames: []
        };
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.endpointId != this.props.endpointId) {
            this.load();
        }

        if (this.state.schedule != prevState.schedule) {
            this.props.onChanged(this.state.schedule);
        }
    }

    load() {
        serviceClient.get(new GetEndpoint({ endpointId: this.props.endpointId })).then((ep) => {
            this.setState({ endpoint: ep, schedule: ep.schedule });
            serviceClient.get(new GetInheritedSchedule({ endpointId: ep.id })).then((r) => {
                let states = r.rules.map(rule => rule.state).filter((val, idx, arr) => arr.indexOf(val) === idx);
                this.setState({ customerStateNames: states });
            })

            serviceClient.get(new ListFlows({ accountIds: [shared.accountId], all: true })).then((r) => {
                this.setState({ flows: r.items });
            });
    
        });


    }

    newRule() {
        this.setState({
            schedule: new Schedule({
                ...this.state.schedule,
                rules: [new SchedulingRule({
                    id: uuid(),
                    flowId: "",
                    flowParams: new Struct()
                }), ...this.state.schedule.rules]
            })
        })
    }

    removeRule(idx: number) {
        let rules = this.state.schedule.rules;
        rules.splice(idx, 1);
        this.setState({
            schedule: new Schedule({
                ...this.state.schedule,
                rules: [...rules]
            })
        })
    }

    moveRule(idx: number, dir: number) {
        let rules = this.state.schedule.rules;
        let rule = rules[idx];
        rules.splice(idx, 1);
        rules.splice(idx + dir, 0, rule);
        this.setState({ schedule: new Schedule(this.state.schedule) });
    }

    setFlowId(idx: number, flowId: string) {
        let rules = this.state.schedule.rules;
        rules[idx].flowId = flowId;
        this.setState({ schedule: new Schedule(this.state.schedule) });
    }

    editRule(idx: number) {
        let flow = this.state.flows.find(f => f.id === this.state.schedule.rules[idx].flowId);
        let rule = new SchedulingRule(JSON.parse(JSON.stringify(this.state.schedule.rules[idx])));
        rule.flowParams = rule.flowParams || new Struct();
        for (let param of flow.parameters) {
            rule.flowParams[param.name] = rule.flowParams[param.name] || new Value({
                boolValue: param.type === ValueTypes.Boolean ? false : null,
                numberValue: param.type === ValueTypes.Number ? 0 : null,
                structValue: param.type === ValueTypes.Struct ? new Struct({}) : null,
                listValue: param.type === ValueTypes.List ? [] : null
            });
        }
        this.setState({
            editFlow: flow,
            editRule: rule,
            showEditRule: true
        });
    }

    saveRule() {
        let myRule = this.state.schedule.rules.find(r => r.id === this.state.editRule.id);
        Object.assign(myRule, this.state.editRule);

        this.setState({
            showEditRule: false,
            schedule: new Schedule(this.state.schedule)
        });
    }

    getRuleKey(rec: SchedulingRule) {
        if (rec.simpleRuleType === SimpleSchedulingRuleTypes.Always) return "always";
        if (rec.simpleRuleType === SimpleSchedulingRuleTypes.CustomerState) return "customer:" + rec.customerState;
        if (rec.simpleRuleType === SimpleSchedulingRuleTypes.Time) {
            if (rec.frequency === SchedulingRuleFrequency.None) {
                return "specificDate";
            } else if (rec.frequency === SchedulingRuleFrequency.Weekly) {
                return "specificDaysOfWeek"
            }
        }
        return "always";
    }

    setRuleKey(rec: SchedulingRule, key: string) {
        rec.byDay = rec.byHour = rec.byMinute = rec.byMonth = rec.byMonthDay = rec.bySetPosition = rec.byWeekNo = rec.byYearDay = [];
        rec.interval = 1;

        if (key === "always") {
            rec.simpleRuleType = SimpleSchedulingRuleTypes.Always;
        } else if (key.indexOf("customer:") === 0) {
            rec.simpleRuleType = SimpleSchedulingRuleTypes.CustomerState;
            rec.customerState = key.substring("customer:".length);
        } else if (key === "specificDate") {
            rec.simpleRuleType = SimpleSchedulingRuleTypes.Time;
            rec.frequency = SchedulingRuleFrequency.None;
            rec.startDate = moment().format("yyyy-MM-DD");
            rec.startTime = "8:00 AM";
            rec.endTime = "5:00 PM";
            rec.isAllDay = true;
        } else if (key === "specificDaysOfWeek") {
            rec.simpleRuleType = SimpleSchedulingRuleTypes.Time;
            rec.frequency = SchedulingRuleFrequency.Weekly;
            rec.startDate = moment().format("yyyy-MM-DD");
            rec.startTime = "8:00 AM";
            rec.endTime = "5:00 PM";
            rec.isAllDay = true;
            rec.byDay = [new dtos.ScheduleDay({ dayOfWeek: dtos.DayOfWeek.Monday })];
        }

        this.setState({
            schedule: new Schedule({
                ...this.state.schedule,
                rules: [...this.state.schedule.rules]
            })
        });
    }

    updateRule(idx: number, update: Partial<SchedulingRule>) {
        let rule = this.state.schedule.rules[idx];
        Object.assign(rule, update);
        this.setState({
            schedule: new Schedule({
                ...this.state.schedule,
                rules: [...this.state.schedule.rules]
            })
        });
    }

    toggleByDay(idx:number, dow:DayOfWeek, on:boolean) {
        let rule = this.state.schedule.rules[idx];
        let day = rule.byDay.find(bd => bd.dayOfWeek === dow);
        if (on && !day) {
            rule.byDay.push(new ScheduleDay({dayOfWeek: dow}));
        } else if (!on && day) {
            rule.byDay = rule.byDay.filter(bd => bd != day);
        }
        this.setState({ schedule: new Schedule(this.state.schedule)});
    }

    render() {
        if (!this.state.schedule) return <ui.Spin />;
        return <>
            <p><small>Rules are evaluated from top to bottom. The first rule that matches will be used.</small></p>
            <p><small>In general, you want to put your exceptions at the top and your after hours / "default" rule at the bottom.</small></p>
            <ui.Table dataSource={this.state.schedule.rules} rowKey="id" pagination={false} >
                <ui.Table.Column title="When" render={(text, rec: SchedulingRule, idx) => <div>
                    <ui.Select value={this.getRuleKey(rec)} onChange={v => this.setRuleKey(rec, v)}>
                        <ui.Select.Option value="always">{idx !== 0 && idx === this.state.schedule.rules.length - 1 ? "Fallback" : "Always"}</ui.Select.Option>
                        <ui.Select.Option value="specificDate">Specific Date</ui.Select.Option>
                        <ui.Select.Option value="specificDaysOfWeek">Specific Days of Week</ui.Select.Option>
                        <ui.Select.OptGroup label="Customer States">
                            {this.state.customerStateNames.map(sn => <ui.Select.Option key={"customer:" + sn} value={"customer:" + sn}>{sn}</ui.Select.Option>)}
                        </ui.Select.OptGroup>
                    </ui.Select>
                </div>} />
                <ui.Table.Column title="" render={(text, rec: SchedulingRule, idx) => <div style={{ display: "flex", flexDirection: "row", gap: 4, alignItems: "center" }}>
                    {this.getRuleKey(rec) === "specificDate" && <ui.DatePicker value={moment(rec.startDate, "yyyy-MM-DD")} onChange={v => this.updateRule(idx, { startDate: v.toISOString() })} />}
                    {this.getRuleKey(rec) === "specificDaysOfWeek" && <div>
                        {Object.keys(DayOfWeek).map(dow => <ui.Checkbox key={dow} checked={rec.byDay.find(d => d.dayOfWeek === dow) != null} onChange={ev => this.toggleByDay(idx, dow as DayOfWeek, ev.target.checked)}>{dow}</ui.Checkbox>)}
                    </div>}
                </div>} />
                <ui.Table.Column title="Time" render={(text, rec: SchedulingRule, idx) => <div style={{ display: "flex", flexDirection: "row", gap: 4, alignItems: "center" }}>
                    {this.getRuleKey(rec) !== "always" && this.getRuleKey(rec).indexOf("customer:") !== 0 && <>
                        <ui.Checkbox checked={rec.isAllDay} onChange={ev => this.updateRule(idx, { isAllDay: ev.target.checked })}>Is All Day</ui.Checkbox>
                        {!rec.isAllDay && <>
                            <ui.TimePicker value={moment(rec.startTime || "8:00AM", "h:mm a")} onChange={(m, ts) => this.updateRule(idx, { startTime: ts })} format="h:mm a" use12Hours minuteStep={1} placeholder="Start Time" />
                            <ui.TimePicker value={moment(rec.endTime || "8:00AM", "h:mm a")} onChange={(m, ts) => this.updateRule(idx, { endTime: ts })} format="h:mm a" use12Hours minuteStep={1} placeholder="End Time" />
                        </>}
                    </>}

                </div>} />
                <ui.Table.Column title="Flow" render={(text, rec: SchedulingRule, idx) => <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                    <ui.Select value={rec.flowId} onChange={v => this.setFlowId(idx, v)} dropdownMatchSelectWidth={false}>
                        <ui.Select.Option value="">(None)</ui.Select.Option>
                        {this.state.flows.map(f => <ui.Select.Option value={f.id} key={f.id}>{f.name}</ui.Select.Option>)}
                    </ui.Select>
                    {rec.flowId && this.state.flows.find(f => f.id === rec.flowId)?.parameters?.length > 0 && <ui.Button onClick={() => this.editRule(idx)}><i className="fa fa-pencil" /></ui.Button>}
                </div>} />
                <ui.Table.Column render={(text, rec: SchedulingRule, idx) => <div>
                    <ui.Button.Group size="small" style={{ marginRight: 8 }}>
                        <ui.Popconfirm title="Remove this rule?" onConfirm={() => this.removeRule(idx)}><ui.Button><i className="fa fa-trash" /></ui.Button></ui.Popconfirm>
                    </ui.Button.Group>

                    <ui.Button.Group size="small">
                        <ui.Button disabled={idx === 0} onClick={() => this.moveRule(idx, -1)}><i className="fa fa-arrow-up" /></ui.Button>
                        <ui.Button disabled={idx >= this.state.schedule.rules.length - 1} onClick={() => this.moveRule(idx, +1)}><i className="fa fa-arrow-down" /></ui.Button>
                    </ui.Button.Group>

                </div>
                } />
            </ui.Table>
            <ui.Button.Group style={{ marginTop: 8, marginBottom: 16 }}>
                <ui.Button onClick={() => this.newRule()}>New Rule (inserted at top)</ui.Button>
            </ui.Button.Group>
            {this.state.editRule && this.state.editFlow && <ui.Modal width="50%" title={`${this.state.editFlow.name} Settings`} visible={this.state.showEditRule} onOk={() => this.saveRule()} onCancel={() => this.setState({ showEditRule: false })}>
                <FlowParamsEditor customerId={this.state.endpoint.customerId} accountId={this.state.endpoint.accountId} params={this.state.editFlow.parameters.filter(p => p.isPublic)} values={this.state.editRule.flowParams} />
            </ui.Modal>}
        </>
    }
}