import { dtos, uuid, _, clone, moment } from "../common";
import { ValueSources, NodeParameter } from "../dtos";

export interface Transition {
    name: string;
    param: dtos.NodeParameter;
    async: boolean;
}

export default {
    shouldShowField(f: dtos.DataField, data: dtos.NodeParameterMap, allFields: dtos.DataField[]) {
        if (f.conditionalVisibilityField) {
            var theField = _.find(allFields, tf => tf.name == f.conditionalVisibilityField);
            if (!theField) return true;

            var param = data[theField.name] as dtos.NodeParameter;
            if (!param) return false;

            if (param.source != dtos.ValueSources.Value) return true;

            if (theField.type == dtos.ValueTypes.String) {
                return param.value.stringValue == f.conditionalVisibilityValue;
            } else if (theField.type == dtos.ValueTypes.Number) {
                return param.value.numberValue == parseFloat(f.conditionalVisibilityValue);
            } else if (theField.type == dtos.ValueTypes.Boolean) {
                return param.value.boolValue.toString() == f.conditionalVisibilityValue;
            }
            return false;
        }
        return true;
    },

    format(template: string, data: dtos.NodeParameterMap) {
        if (!template) return "";
        return template.replace(/\{[a-zA-Z]+\}/gi, fn => {
            var fieldName = fn.substring(1, fn.length - 1);
            var field = data[fieldName] as NodeParameter;
            if (!field) return "INVALID TEMPLATE";
            switch (field.type) {
                case dtos.ValueTypes.Time:
                    return moment(field.value.stringValue, "h:mm a").format("LT");
                case dtos.ValueTypes.Date:
                    return moment(field.value.stringValue).format("LL");
                case dtos.ValueTypes.Number:
                    return field.value.numberValue.toString();
                default:
                    return field.value.stringValue;
            }
        }).trim();
    },

    getTransitions(struct: dtos.NodeParameterMap, dt: dtos.DataType): Array<Transition> {
        let results = [];
        const getStructTransitions = (dataType: dtos.DataType, data: dtos.NodeParameterMap, name = "") => {
            for (let field of dataType.fields) {
                if (field.type == dtos.ValueTypes.Transition) {
                    var fieldName = name || field.name;
                    if (field.transitionNameFormat) {
                        fieldName = this.format(field.transitionNameFormat, data);
                    }
                    results.push({
                        name: fieldName,
                        param: data[field.name],
                        async: field.isAsync
                    });
                } else if (field.type == dtos.ValueTypes.Struct && data[field.name] != null && data[field.name].structParameters != null) {
                    getStructTransitions(field.structType, data[field.name].structParameters);
                } else if (field.type == dtos.ValueTypes.List && data[field.name] != null && data[field.name].listParameters != null) {
                    for (var i = 0; i < data[field.name].listParameters.length; i++) {
                        let listItemData = data[field.name].listParameters[i];
                        let listType = field.listType;
                        var firstStringField = "";
                        let strField = _.find(listType.fields, f => f.type == dtos.ValueTypes.String || f.type == dtos.ValueTypes.Time || f.type == dtos.ValueTypes.Date);
                        if (strField != null && listItemData[strField.name] != null) {
                            firstStringField = listItemData[strField.name].value.stringValue;
                        }
                        getStructTransitions(field.listType, listItemData, firstStringField);
                    }
                }
            }
        }

        getStructTransitions(dt, struct);
        return results;
    },

    initStruct<TField extends dtos.DataField>(params: dtos.NodeParameterMap, fields: Array<TField>, rootType: dtos.DataType, filter: (f: TField) => boolean = null) {
        if (filter == null) filter = (f: TField) => true;
        for (let field of fields.filter(filter)) {
            var param = params[field.name] as dtos.NodeParameter;
            if (param == null) {
                param = new dtos.NodeParameter({
                    id: uuid(),
                    type: field.type,
                    isOutput: field.isOutput,
                    isAsync: field.isAsync,
                    source: ValueSources.Value,
                    expression: "",
                    value: new dtos.Value(),
                    listType: field.listType
                });
                params[field.name] = param;
            }
            param.noEvalTemplate = field.noEvalTemplate;

            if (field.isOutput && !param.value.stringValue) {
                param.value.stringValue = "";
            } else if (field.type == dtos.ValueTypes.Time && !param.value.stringValue) {
                param.value.stringValue = field.defaultValue == null ? "8:00am" : (field.defaultValue.stringValue || "8:00am");
            } else if (field.type == dtos.ValueTypes.String && !param.value.stringValue) {
                param.value.stringValue = field.defaultValue == null ? "" : (field.defaultValue.stringValue || "");
            } else if (field.type == dtos.ValueTypes.List && !param.listParameters) {
                param.listParameters = [];
            } else if (field.type == dtos.ValueTypes.Number && !param.value.numberValue) {
                param.value.numberValue = field.defaultValue == null ? 0 : (field.defaultValue.numberValue || 0);
            } else if (field.type == dtos.ValueTypes.Boolean && (param.value.boolValue !== false && param.value.boolValue !== true)) {
                param.value.boolValue = field.defaultValue == null ? false : (field.defaultValue.boolValue || false);
            } else if (field.type == dtos.ValueTypes.Struct && !param.structParameters) {
                param.structParameters = new dtos.NodeParameterMap();
                this.initStruct(param.structParameters, field.structType.fields, rootType);
            } else if (field.type == dtos.ValueTypes.Transition && !param.value.stringValue) {
                param.value.stringValue = "";
            }

            if (field.type == dtos.ValueTypes.List && !field.isOutput && param.listParameters.length > 0) {
                let listType = field.listType;
                for (let listParam of param.listParameters) {
                    this.initStruct(listParam, listType.fields, rootType);
                }
            } else if (field.type == dtos.ValueTypes.Struct) {
                let structType = field.structType;
                this.initStruct(param.structParameters, structType.fields, rootType);
            }
        }
    },


};