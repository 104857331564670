import { React, serviceClient, dtos, observer, shared, observable, ui, Link, selectFilter, _, splitIntoWords, uuid, moveArray, removeArray, makeObservable, withRouter, cache, getEndpointIcon } from "../common";
import ValueEditor from "../flows/value-editor";
import PageTitle from "../page-title";
import TagsEditor from "../tags-editor";
import AutoComplete from "../autocomplete";
import Sessions from "../system/sessions";
import Notifications from "../system/notifications";
import ScheduleEditor from "./schedule";
import CustomersIndex from ".";
import BillingSettings from "../accounts/billing";
import AppSettingsEditor from "./app-settings";
import { Space } from "antd";


@observer
class CustomerDetails extends React.Component {
    @observable name = "";
    @observable id = "";
    @observable editName = "";
    @observable editReferenceId = "";
    @observable data = new dtos.Struct();
    @observable dataFields: Array<dtos.CustomerDataField> = [];
    @observable editParentCustomerId = "";
    @observable tags: Array<dtos.Tag> = [];
    @observable loading = true;
    @observable tab = "general";
    @observable editOverrideBilling: boolean;
    @observable editBilling: dtos.BillingSettings;
    @observable editOverrideAppSettings: boolean;
    @observable editAppSettings: dtos.AppSettings;

    @observable endpoints: dtos.EndpointInfo[] = [];
    @observable pagination: any = { position: "bottom", total: 0 };
    @observable sort: any = {};
    @observable filter: any = {};
    @observable searching = false;
    @observable endpointFields: Array<dtos.EndpointDataField> = [];
    @observable pageSize = 20;
    @observable loadingEndpoints = false;

    @observable schedule: dtos.Schedule;
    @observable savedParentCustomerId = "";
    @observable customer: dtos.CustomerInfo = null;

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    componentDidMount() {
        this.refresh((this.props as any).params.id);
    }

    componentDidUpdate(prevProps) {
        var newId = (this.props as any).params.id;
        if (newId != prevProps.params.id) {
            this.refresh(newId);
            this.tab = "general";
        }
    }

    async refresh(id) {
        let customer = (await serviceClient.get(new dtos.ListCustomers({ specificIds: [id] }))).items[0];
        let ss = await cache.getSystemSettings();
        this.id = customer.id;
        this.name = customer.name;
        this.editName = customer.name;
        this.editReferenceId = customer.referenceId;
        this.editOverrideBilling = customer.overrideBillingSettings;
        this.editBilling = customer.billingSettings;
        this.editOverrideAppSettings = customer.overrideAppSettings;
        this.editAppSettings = customer.appSettings;
        this.tags = customer.tags;
        this.schedule = customer.schedule;
        for (let field of ss.customerFields) {
            if (!customer.data[field.name]) {
                customer.data[field.name] = new dtos.Value();
            }
        }
        this.data = customer.data;
        this.dataFields = ss.customerFields;
        this.editParentCustomerId = customer.parentCustomerId;
        this.savedParentCustomerId = customer.parentCustomerId;
        this.endpointFields = ss.endpointFields;
        this.customer = customer;
        this.refreshEndpoints();
        this.loading = false;
    }

    async refreshEndpoints(page = this.pagination.page || 1, sort = this.sort) {
        this.loadingEndpoints = true;
        let response = await serviceClient.get(new dtos.ListEndpoints({ accountIds: [shared.accountId], customerIds: [this.id], page: page - 1, countPerPage: this.pageSize }));
        this.endpoints = response.items;
        let pager = { ...this.pagination };
        pager.current = page;
        pager.total = response.totalCount;
        pager.pageSize = this.pageSize;
        pager.showSizeChanger = true;
        pager.pageSizeOptions = ["10", "20", "50", "100", "250"];
        pager.onShowSizeChange = (c, v) => this.pageSize = v;
        this.sort = sort;
        this.pagination = pager;
        this.loadingEndpoints = false;
    }

    async patchCustomer() {
        let updated = await serviceClient.patch(new dtos.PatchCustomer({
            customerId: this.id,
            name: this.editName,
            data: this.data,
            tagIds: this.tags.map(t => t.id),
            referenceId: this.editReferenceId,
            parentCustomerId: this.editParentCustomerId,
            overrideBillingSettings: this.editOverrideBilling,
            billingSettings: this.editBilling,
            overrideAppSettings: this.editOverrideAppSettings,
            appSettings: this.editAppSettings
        }));
        this.name = this.editName;
        this.savedParentCustomerId = this.editParentCustomerId;
        ui.message.success("Customer updated");
    }

    async deleteCustomer() {
        await serviceClient.delete(new dtos.DeleteCustomer({ customerId: this.id }));
        ui.message.success("Customer deleted");
        window.location.hash = "/customers";
    }

    getEndpointDataText(cust: dtos.EndpointInfo, f: dtos.EndpointDataField) {
        var val = cust.data[f.name] as dtos.Value;
        if (!val) return <span />;
        return <ValueEditor readOnly field={f} value={val} accountId={shared.accountId} valueType={f.type} />;
    }

    setStaging(staging: boolean) {
        serviceClient.post(new dtos.SetCustomerStagingMode({ customerId: this.customer.id, isStaging: staging })).then(cust => {
            this.customer = cust;
            ui.message.success("Mode changed. Phone numbers will be updated in the background")
        });
    }


    render() {
        return (
            <div>
                <PageTitle title={this.name} />
                <ui.Breadcrumb>
                    <ui.Breadcrumb.Item><Link to="/customers">Customers</Link></ui.Breadcrumb.Item>
                    {this.customer && this.customer.breadcrumb.map(bc => <ui.Breadcrumb.Item key={bc.id}>{bc.id == this.customer.id ? <span>{bc.name}</span> : <Link to={"/customers/" + bc.id}>{bc.name}</Link>}</ui.Breadcrumb.Item>)}
                </ui.Breadcrumb>
                {!shared.collapsed && <ui.Typography.Title><i className="fa fa-users" /> {this.name}</ui.Typography.Title>}
                {this.loading && <div style={{ textAlign: "center", paddingTop: 100 }}><ui.Spin size="default" /></div>}
                {!this.loading && <ui.Tabs activeKey={this.tab} onChange={t => this.tab = t}>
                    <ui.Tabs.TabPane key="general" tab="General">
                        {this.customer.isStaging && <ui.Alert type="error" style={{marginBottom: 8}} message="This customer is in Staging mode" />}
                        <ui.Form layout="vertical" onSubmitCapture={() => this.patchCustomer()}>
                            <ui.Row gutter={8}>
                                <ui.Col span={12}>
                                    <ui.Form.Item label="Name">
                                        <ui.Input value={this.editName} onChange={v => this.editName = v.target.value} />
                                    </ui.Form.Item>
                                </ui.Col>
                                <ui.Col span={12}>
                                    <ui.Form.Item label="Customer ID">
                                        <ui.Input value={this.id} readOnly disabled />
                                    </ui.Form.Item>
                                </ui.Col>
                            </ui.Row>
                            <ui.Form.Item label="Parent Customer">
                                <AutoComplete type={dtos.ValueTypes.Customer} value={this.editParentCustomerId} onChanged={v => this.editParentCustomerId = v} placeholder="Search for a parent customer" />
                            </ui.Form.Item>
                            <ui.Form.Item label="Tags">
                                <TagsEditor tags={this.tags} />
                            </ui.Form.Item>
                            <ui.Form.Item label="Reference ID">
                                <ui.Input value={this.editReferenceId} onChange={ev => this.editReferenceId = ev.target.value} />
                            </ui.Form.Item>
                            {this.dataFields.map(f =>
                                <ui.Form.Item label={splitIntoWords(f.name)} key={f.id}>
                                    <ValueEditor customerId={this.id} accountId={shared.accountId} field={f} valueType={f.type} value={this.data[f.name]} />
                                    {f.description && <p style={{ margin: 0, padding: 0, lineHeight: 1 }}><small>{f.description}</small></p>}
                                </ui.Form.Item>)}
                            <ui.Form.Item>
                                <Space>
                                    <ui.Button htmlType="submit" type="primary">Save</ui.Button>
                                    {this.customer.isStaging && <ui.Popconfirm title="Change this customer to use the production servers? This will update all the phone numbers and may take a while." onConfirm={() => this.setStaging(false)}><ui.Button ghost type="primary">Change to Production</ui.Button></ui.Popconfirm>}
                                    {!this.customer.isStaging && <ui.Popconfirm title="Change this customer to use the staging servers? This will update all the phone numbers and may take a while. You can change back to production at any time." onConfirm={() => this.setStaging(true)}><ui.Button ghost danger type="primary">Change to Staging</ui.Button></ui.Popconfirm>}
                                    <ui.Popconfirm title="Are you sure you want to delete this customer?" onConfirm={() => this.deleteCustomer()}><a>Delete Customer</a></ui.Popconfirm>
                                </Space>
                            </ui.Form.Item>
                        </ui.Form>
                    </ui.Tabs.TabPane>
                    <ui.Tabs.TabPane key="children" tab="Children">
                        <CustomersIndex parentCustomerId={this.id} />
                    </ui.Tabs.TabPane>
                    <ui.Tabs.TabPane key="schedule" tab="Schedule">
                        <ScheduleEditor schedule={this.schedule} customerId={this.id} endpointId="" parentCustomerId={this.savedParentCustomerId} />
                    </ui.Tabs.TabPane>
                    <ui.Tabs.TabPane key="app" tab="App">
                        <ui.Checkbox checked={this.editOverrideAppSettings} onChange={ev => this.editOverrideAppSettings = ev.target.checked}>Override App Settings</ui.Checkbox>
                        {this.editOverrideAppSettings && <AppSettingsEditor appSettings={this.editAppSettings} onChanged={s => this.editAppSettings = s} />}
                        <ui.Form.Item style={{ marginTop: 16 }}>
                            <ui.Button type="primary" onClick={() => this.patchCustomer()}>Save</ui.Button>
                        </ui.Form.Item>
                    </ui.Tabs.TabPane>
                    {shared.isManager && <ui.Tabs.TabPane key="billing" tab="Billing">
                        <ui.Checkbox style={{ marginBottom: 8 }} checked={this.editOverrideBilling} onChange={ev => this.editOverrideBilling = ev.target.checked}>Override Billing Settings</ui.Checkbox>
                        {this.editOverrideBilling && <BillingSettings settings={this.editBilling} onChanged={v => this.editBilling = v} />}
                        <ui.Form.Item>
                            <ui.Button onClick={() => this.patchCustomer()} type="primary">Save</ui.Button>
                        </ui.Form.Item>
                    </ui.Tabs.TabPane>}
                    <ui.Tabs.TabPane key="endpoints" tab="Endpoints">
                        <ui.Table loading={this.loadingEndpoints} dataSource={this.endpoints} rowKey="id" pagination={this.pagination} onChange={(p, f, s) => this.refreshEndpoints(p.current, s)}>
                            <ui.Table.Column title="Name" dataIndex="displayName" render={(text, rec: dtos.EndpointInfo) =>
                                <span><i style={{ marginRight: 5 }} className={getEndpointIcon(rec.type)} />
                                    <Link to={`/endpoints/${rec.id}`}>{rec.displayName || "(No Name)"}</Link>
                                    {rec.userMode == dtos.UserModes.Sip && <small>&nbsp;(SIP)</small>}
                                    {rec.userMode == dtos.UserModes.Flow && <small>&nbsp;(Flow)</small>}
                                    {rec.userMode == dtos.UserModes.DataOnly && <small>&nbsp;(Data)</small>}
                                    {rec.type == dtos.EndpointTypes.PhoneNumber && rec.name != null && rec.name.length > 0 && <small>&nbsp;({rec.name})</small>}
                                </span>} />
                            <ui.Table.Column title="Type" dataIndex="type" render={(text, rec: dtos.EndpointInfo) => splitIntoWords(rec.type)} />
                            <ui.Table.Column dataIndex="customerBreadcrumb" title="Customer" render={(text, rec: dtos.EndpointInfo) =>
                                <ui.Breadcrumb>
                                    {rec.customerBreadcrumb.map((bc, i) => <ui.Breadcrumb.Item key={bc.id}><Link to={`/customers/${bc.id}`}>{bc.name}</Link></ui.Breadcrumb.Item>)}
                                </ui.Breadcrumb>
                            } />

                            <ui.Table.Column title="Flow" dataIndex="voiceFlowName" render={(text, rec: dtos.EndpointInfo) => rec.flowId && <Link to={`/flows/${rec.flowId}`}>{rec.flowName}</Link>} />
                            <ui.Table.Column title="Tags" render={(text, rec: dtos.EndpointInfo) =>
                                <div>
                                    {rec.tags.map(t => <ui.Tag key={t.id} color={t.color.toLowerCase()}>{t.name}</ui.Tag>)}
                                </div>} />
                            {this.endpointFields.filter(f => f.showInSearch).map(f => <ui.Table.Column key={f.name} dataIndex={f.name} title={splitIntoWords(f.name)} render={(text, rec: dtos.EndpointInfo) =>
                                <span>{this.getEndpointDataText(rec, f)}</span>} />)}
                        </ui.Table>
                    </ui.Tabs.TabPane>
                    <ui.Tabs.TabPane key="notifications" tab="Notifications">
                        {this.id && <Notifications customerId={this.id} accountId={shared.accountId} />}
                    </ui.Tabs.TabPane>
                    <ui.Tabs.TabPane key="sessions" tab="Sessions">
                        {this.id && <Sessions customerId={this.id} accountId={shared.accountId} />}
                    </ui.Tabs.TabPane>
                </ui.Tabs>}
            </div>
        );
    }
}

export default withRouter(CustomerDetails);