import { React, withRouter, serviceClient, dtos, observer, shared, observable, ui, Link, selectFilter, _, splitIntoWords, uuid, moveArray, removeArray, makeObservable, action, runInAction, cache } from "../common";
import ValueEditor from "../flows/value-editor";
import PageTitle from "../page-title";
import TagsEditor from "../tags-editor";
import AutoComplete from "../autocomplete";
import Sessions from "../system/sessions";
import Notifications from "../system/notifications";
import CustomersIndex from ".";
import { CrmCustomerMapping } from "./customer-mapping";
import { CrmUserMapping } from "./user-mapping";
import { IntegrationFeatures, ValueTypes } from "../dtos";

@observer
class IntegrationDetails extends React.Component {
    @observable name = "";
    @observable id = "";
    @observable editName = "";
    @observable editCustomerId = "";
    @observable editAutomaticallyCreateCustomers = false;
    @observable loading = true;
    @observable busy = false;
    @observable syncResults = "";
    @observable tab = "general";
    @observable dataFields: Array<dtos.DataField> = [];
    @observable features: dtos.IntegrationFeatures[] = [];

    @observable pagination: any = { position: "bottom", total: 0 };
    @observable sort: any = {};
    @observable filter: any = {};
    @observable searching = false;
    @observable pageSize = 20;
    @observable settings = new dtos.Struct();

    @observable integration: dtos.IntegrationInfo = null;

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    componentDidMount() {
        this.refresh((this.props as any).params.id);
    }

    componentDidUpdate(prevProps) {
        var newId = (this.props as any).params.id;
        if (newId != prevProps.params.id) {
            this.refresh(newId);
            this.tab = "general";
        }
    }

    async refresh(id) {
        let it = (await serviceClient.get(new dtos.ListIntegrations({ specificIds: [id] }))).items[0];
        let ss = await cache.getSystemSettings();
        this.id = it.id;
        this.name = it.name;
        this.editName = it.name;
        for (let field of it.settingsDataType.fields) {
            if (!it.settings[field.name]) {
                it.settings[field.name] = new dtos.Value();
            }
        }
        this.features = it.features;
        this.settings = it.settings;
        this.dataFields = it.settingsDataType.fields;
        this.editAutomaticallyCreateCustomers = it.automaticallyCreateCustomers;
        this.editCustomerId = it.customerId;
        this.integration = it;
        this.loading = false;
    }

    async patch() {
        let updated = await serviceClient.patch(new dtos.PatchIntegration({
            integrationId: this.id,
            name: this.editName,
            customerId: this.editCustomerId,
            settings: this.settings,
            automaticallyCreateCustomers: this.editAutomaticallyCreateCustomers
        }));

        if (updated.status === dtos.IntegrationStatuses.Error) {
            ui.message.error("Settings saved but there is an error with the configuration: " + updated.statusMessage);
        } else {
            ui.message.success("Integration configured");
        }

        this.name = this.editName;
        this.integration = updated;
        ui.message.success("Integration updated");
    }

    async deleteIntegration() {
        await serviceClient.delete(new dtos.DeleteIntegration({ integrationId: this.id }));
        ui.message.success("Integration deleted");
        window.location.hash = "/integrations";
    }

    run() {
        this.busy = true;
        this.syncResults = "";
        serviceClient.post(new dtos.SyncIntegration({ integrationId: this.id })).then(r => {
            this.syncResults = r.log;
        }).finally(() => this.busy = false);
    }

    render() {
        return (
            <div>
                <PageTitle title={this.name} />
                <ui.Breadcrumb>
                    <ui.Breadcrumb.Item><Link to="/integrations">Integrations</Link></ui.Breadcrumb.Item>
                    <ui.Breadcrumb.Item>{this.integration?.name}</ui.Breadcrumb.Item>
                </ui.Breadcrumb>
                {!shared.collapsed && <ui.Typography.Title><i className="fa fa-users" /> {this.name}</ui.Typography.Title>}
                {this.loading && <div style={{ textAlign: "center", paddingTop: 100 }}><ui.Spin size="default" /></div>}

                {!this.loading && <ui.Tabs activeKey={this.tab} onChange={t => this.tab = t}>
                    <ui.Tabs.TabPane key="general" tab="Settings">
                        <ui.Form layout="vertical" onSubmitCapture={() => this.patch()}>
                            <ui.Form.Item label="Name">
                                <ui.Input value={this.editName} onChange={v => this.editName = v.target.value} />
                            </ui.Form.Item>
                            <ui.Form.Item label="Customer">
                                <AutoComplete type={dtos.ValueTypes.Customer} value={this.editCustomerId} onChanged={v => this.editCustomerId = v} placeholder="Search for a parent customer" />
                            </ui.Form.Item>
                            {this.features.indexOf(IntegrationFeatures.CrmSync) >= 0 && <ui.Form.Item>
                                <ui.Checkbox checked={this.editAutomaticallyCreateCustomers} onChange={ev => this.editAutomaticallyCreateCustomers = ev.target.checked}>Automatically create new customers / users</ui.Checkbox>
                            </ui.Form.Item>}
                            {this.features.indexOf(IntegrationFeatures.OAuth2) >= 0 && <ui.Form.Item>
                                {!this.integration.isAuthorized && <ui.Button type="primary" href={`${serviceClient.baseUrl}/integrations/${this.integration.id}/authorize`}>Authorize</ui.Button>}
                                {this.integration.isAuthorized && <ui.Space direction="vertical">
                                    <ui.Alert type="info" message="Authorization Successful" />
                                    <ui.Button type="primary" href={`${serviceClient.baseUrl}/integrations/${this.integration.id}/authorize`}>Re-Authorize</ui.Button>
                                </ui.Space>}
                            </ui.Form.Item>}
                            {this.dataFields.map(f =>
                                <ui.Form.Item label={f.type === ValueTypes.Struct ? null : splitIntoWords(f.name)} key={f.id}>
                                    <ValueEditor customerId={this.id} accountId={shared.accountId} field={f} valueType={f.type} value={this.settings[f.name]} />
                                    {f.description && <p style={{ margin: 0, padding: 0, lineHeight: 1 }}><small>{f.description}</small></p>}
                                </ui.Form.Item>)}
                            <ui.Form.Item>
                                <ui.Space>
                                    <ui.Button htmlType="submit" type="primary" disabled={this.busy}>Save</ui.Button>
                                    {this.integration?.status === dtos.IntegrationStatuses.OK && <ui.Popconfirm title="Sync this integration? This may take a while." onConfirm={() => this.run()}><ui.Button type="default" disabled={this.busy}>Sync</ui.Button></ui.Popconfirm>}
                                    <ui.Popconfirm title="Are you sure you want to delete this integration?" onConfirm={() => this.deleteIntegration()}><a style={{ marginLeft: 30 }}>Delete Customer</a></ui.Popconfirm>
                                </ui.Space>
                            </ui.Form.Item>
                        </ui.Form>
                    </ui.Tabs.TabPane>
                    {this.integration?.status === dtos.IntegrationStatuses.OK && this.features.indexOf(dtos.IntegrationFeatures.CrmSync) >= 0 && <ui.Tabs.TabPane key="hsc" tab="Map Customers">
                        <CrmCustomerMapping customerId={this.integration.customerId} integrationId={this.id} />
                    </ui.Tabs.TabPane>}
                    {this.integration?.status === dtos.IntegrationStatuses.OK && this.features.indexOf(dtos.IntegrationFeatures.CrmSync) >= 0 && <ui.Tabs.TabPane key="hsu" tab="Map Users">
                        <CrmUserMapping customerId={this.integration.customerId} integrationId={this.id} />
                    </ui.Tabs.TabPane>}
                </ui.Tabs>}
                <ui.Modal title="Sync Successful" okButtonProps={{ hidden: true }} cancelText="Close" open={this.syncResults.length > 0} onCancel={() => this.syncResults = ""}>
                    <ui.Input.TextArea rows={15} style={{ background: "#f0f0f0" }} value={this.syncResults} readOnly />
                </ui.Modal>
            </div>
        );
    }
}

export default withRouter(IntegrationDetails);