import { React, dtos } from "../common";
import ReactPanZoom from "./pan-zoom";

interface Props {
    ui: dtos.FlowUI;
    children: any;
}

export default class FlowCanvasContainer extends React.Component<Props> {
    div = React.createRef<HTMLDivElement>();

    resize: () => void;
    
    componentDidMount() {
        this.resize = this.resizeCanvas.bind(this);
        window.addEventListener("resize", this.resize);
        this.resizeCanvas();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
    }

    resizeCanvas() {
        let height = document.body.clientHeight - this.div.current.getBoundingClientRect().top;
        this.div.current.style.height = height + "px";
    }
    
    onPan(x: number, y: number) {
        this.props.ui.canvasX = x;
        this.props.ui.canvasY = y;
    }

    render() {
        return (
            <div ref={this.div} className="canvas-container">
                <ReactPanZoom width="100%" height="100%" zoom={this.props.ui.canvasZoom || 1} pandx={this.props.ui.canvasX} pandy={this.props.ui.canvasY}  onPan={(x, y) => this.onPan(x, y)}>
                    {this.props.children}
                </ReactPanZoom>
            </div>
        );
    }
}