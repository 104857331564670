import { React, ui } from "./common"

interface Props {
    height: number;
    children: any;
}

export default class ScrollPane extends React.Component<Props> {
    render() {
        return (
            <div style={{ maxHeight: this.props.height, overflowY: "auto", overflowX: "hidden" }}>
                {this.props.children}
            </div>
        );
    }
}