import { React, serviceClient, dtos, observer, observable, ui, Link, Observer, makeObservable } from "../common";
import PageTitle from "../page-title";

@observer
export default class AccountIndex extends React.Component {
    @observable accounts: Array<dtos.AccountInfo> = [];
    @observable pagination: any = { position: "both" };
    @observable sort: any = {};
    @observable loading = false;
    @observable filter: any = {};
    @observable pageSize = 20;

    @observable showNewAccount = false;
    @observable newAccountName = "";
    @observable newAccountSid = "";
    @observable newAccountAuthToken = "";
    @observable showCloseAccount = false;
    @observable accountToClose: dtos.AccountInfo;
    @observable confirmDelete1 = false;
    @observable confirmDelete2 = false;

    constructor(props) {
        super(props);
        makeObservable(this);
        this.filter.name = localStorage.getItem("AccountIndex.filter.name");
        this.pageSize = parseInt(localStorage.getItem("AccountIndex.pagination.pageSize") || "20");
    }

    componentDidMount() {
        this.refresh(1, {});
    }

    async saveNewAccount(ev: React.FormEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        let newAccount = await serviceClient.post(new dtos.NewAccount(
            {
                name: this.newAccountName,
                twilioAccountSid: this.newAccountSid,
                twilioAuthToken: this.newAccountAuthToken
            }));
        window.location.hash = "/accounts/" + newAccount.id;
    }

    async refresh(page = this.pagination.page || 1, sort = this.sort) {
        this.loading = true;
        let request = new dtos.ListAccounts({
            countPerPage: this.pageSize,
            page: page - 1,
            sortField: sort.field,
            sortOrder: sort.order,
            nameFilter: this.filter.name,
            simplifiedPaging: true 
        });
        let response = await serviceClient.get(request);
        this.accounts = response.items;
        this.sort = sort;
        let pager = { ...this.pagination };
        pager.current = page;
        pager.total = page * this.pageSize + (response.hasMorePages ? 1 : 0);
        pager.pageSize = this.pageSize;
        pager.showSizeChanger = true;
        pager.pageSizeOptions = ["10", "20", "50", "100", "250"];
        pager.onShowSizeChange = (c, v) => this.pageSize = v;
        this.pagination = pager;
        this.loading = false;


        localStorage.setItem("AccountIndex.filter.name", this.filter.name || "");
        localStorage.setItem("AccountIndex.pagination.pageSize", this.pageSize.toString());
    }

    checkFilterSubmit(ev: React.KeyboardEvent) {
        if (ev.which == 13) {
            this.refresh();
        }
    }

    startCloseAccount(rec: dtos.AccountInfo) {
        this.accountToClose = rec;
        this.showCloseAccount = true;
        this.confirmDelete1 = this.confirmDelete2 = false;
    }

    async closeAccount() {
        await serviceClient.delete(new dtos.DeleteAccount({accountId: this.accountToClose.id}));
        this.refresh();
        ui.message.success("Account closed");
        this.showCloseAccount = false;
    }

    async checkAccount(acc:dtos.AccountInfo) {
            let response = await serviceClient.post(new dtos.CheckAccount({accountId: acc.id}));
            ui.message.success(response.message || "Check complete. No changes performed");
 
    }

    render() {
        return (
            <div>
                <PageTitle title="Accounts" />
                <ui.Breadcrumb>
                    <ui.Breadcrumb.Item>Accounts</ui.Breadcrumb.Item>
                </ui.Breadcrumb>
                <ui.Typography.Title><i className="fa fa-cabinet-filing" /> Accounts</ui.Typography.Title>
                <ui.Button.Group style={{ marginBottom: 16 }}>
                    <ui.Button type="primary" onClick={() => this.showNewAccount = true}>New Account</ui.Button>
                </ui.Button.Group>
                <ui.Form layout="inline" style={{ marginBottom: 8 }}>
                    <ui.Form.Item>
                        <ui.Input onKeyPress={ev => this.checkFilterSubmit(ev)} placeholder="Filter by name" value={this.filter.name || ""} onChange={ev => this.filter.name = ev.target.value} />
                    </ui.Form.Item>
                    <ui.Form.Item>
                        <ui.Button.Group>
                            <ui.Button type="primary" onClick={() => this.refresh()}>Search</ui.Button>
                            <ui.Button type="default" onClick={() => { this.filter = {}; this.refresh(); }}>Clear</ui.Button>
                        </ui.Button.Group>
                    </ui.Form.Item>
                </ui.Form>
                <ui.Table className="hide-pagination-buttons" loading={this.loading} dataSource={this.accounts} rowKey="id" pagination={this.pagination} onChange={(p, f, s) => this.refresh(p.current, s)}>
                    <ui.Table.Column title="Name" dataIndex="name" render={(text, rec: dtos.AccountInfo) => <div><Link to={"/accounts/" + rec.id}>{rec.name}</Link> {rec.isBYOA && <span>(BYOA)</span>}</div>} />
                    <ui.Table.Column title="SID" dataIndex="twilioAccountSid" />
                    <ui.Table.Column title="Trust Hub" dataIndex="trustHubProfileSid" />
                    <ui.Table.Column title="Actions" render={(text, rec: dtos.AccountInfo) => <ui.Button.Group>
                        <ui.Button danger onClick={() => this.startCloseAccount(rec)}>Close Account</ui.Button>
                        <ui.Button onClick={() => this.checkAccount(rec)}>Check Account</ui.Button>
                    </ui.Button.Group>} />
                </ui.Table>
                <ui.Modal title="New Account" visible={this.showNewAccount} onCancel={() => this.showNewAccount = false} okText="Save" onOk={(ev) => this.saveNewAccount(ev)}>
                    <ui.Form layout="vertical" onSubmitCapture={ev => this.saveNewAccount(ev)}>
                        <button type="submit" style={{ position: "absolute", top: -9999, left: -9999 }}>Submit</button>
                        <ui.Form.Item label="New Account Name">
                            <ui.Input placeholder="Enter the new name for this account" value={this.newAccountName} onChange={ev => this.newAccountName = ev.target.value} />
                        </ui.Form.Item>
                        <ui.Form.Item label="Twilio Account SID (when bringing your own account)">
                            <ui.Input placeholder="Enter account SID" value={this.newAccountSid} onChange={ev => this.newAccountSid = ev.target.value} />

                        </ui.Form.Item>
                        <ui.Form.Item label="Twilio Auth Token (when bringing your own account)">
                            <ui.Input placeholder="Enter auth token" value={this.newAccountAuthToken} onChange={ev => this.newAccountAuthToken = ev.target.value} />

                        </ui.Form.Item>
                    </ui.Form>
                </ui.Modal>
                <ui.Modal title="Close Account" onOk={() => this.closeAccount()} okButtonProps={{ disabled: !this.confirmDelete1 || !this.confirmDelete2 }} visible={this.showCloseAccount} onCancel={() => this.showCloseAccount = false}>
                    <p>Closing <strong>{this.accountToClose && this.accountToClose.name}</strong> account will delete <strong>ALL DATA</strong> associated with this account and will delete the Twilio account. You should port away any numbers you want to keep before doing this.</p>
                    <p><ui.Checkbox checked={this.confirmDelete1} onChange={ev => this.confirmDelete1 = ev.target.checked} /> I acknowledge that this will delete all Evo Voice data</p>
                    <p><ui.Checkbox checked={this.confirmDelete2} onChange={ev => this.confirmDelete2 = ev.target.checked} /> I acknowledge that this will close the associated Twilio sub-account and release any phone numbers still on the account.</p>
                </ui.Modal>
            </div>
        );
    }
}