import { React, dtos, serviceClient, ui, observer, observable, _, makeObservable, getEndpointIcon } from "../common"
import FlowValueEditor from "../flows/value-editor";

interface Props {
    accountId: string;
    endpointId?: string;
    customerId?: string;
}

@observer
export default class UserSettings extends React.Component<Props> {
    @observable accountId: string;
    @observable settingsObjects: dtos.SettingsObject[] = [];

    constructor(props) {
        super(props);
        makeObservable(this);
    }
    
    componentDidMount() {
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.accountId != prevProps.accountId) {
            this.accountId = this.props.accountId;
            this.init();
        }
    }

    async init() {
        let response = await serviceClient.get(new dtos.ListSettingsObjects());
        this.settingsObjects = response.settingsObjects;
    }

    async save(so: dtos.SettingsObject) {
        let data = {};
        for (let field of so.fields) {
            data[field.name] = field.value;
        }

        await serviceClient.patch(new dtos.PatchSettingsObject({
            id: so.id,
            data: data 
        }))
        
        ui.message.success("Changes saved");
    }

    render() {
        return (
            <div>
                <ui.Collapse>
                    {this.settingsObjects.filter(so => so.fields.length > 0).map(so => <ui.Collapse.Panel key={so.id} header={<span><i className={getEndpointIcon(so.endpointType)} /> {so.name}</span>}>
                        <div>
                            {so.fields.map(f => <ui.Form.Item key={f.name} label={f.displayName}>
                                <FlowValueEditor field={f.dataField} accountId={this.accountId} valueType={f.type} value={f.value} />
                            </ui.Form.Item>)}
                            <div>
                                <ui.Button type="primary" onClick={() => this.save(so)}>Save</ui.Button>
                            </div>
                        </div>
                    </ui.Collapse.Panel>)}
                </ui.Collapse>

            </div>
        );
    }
}