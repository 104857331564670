import { React, ui, dtos, observable, observer, uuid, Observer, observe, serviceClient, _, validateParamName, makeObservable } from "../common";
import ValueEditor from "./value-editor";
import OverflowPanel from "./overflow-panel";
import AutoFocus from "../autofocus";
import DataTypeEditor from "./data-type-editor";

interface Props {
    parameters: Array<dtos.FlowParameter>;
    exits: Array<dtos.FlowExit>;
    accountId: string;
    onChanged: () => void;
}

interface ParameterType {
    name: string;
    type: dtos.ValueTypes;
}

@observer
export default class FlowParams extends React.Component<Props> {
    @observable showNewParam = false;
    @observable newParamName = "";
    @observable newParameterType = dtos.ValueTypes.String;
    @observable newParamPublic = false;
    @observable newParamOutput = false;
    @observable newParamKnob = false;
    @observable newParamListType: dtos.DataType = null;
    @observable showEditValues = false;
    @observable editValuesField: dtos.DataField = null;
    @observable editValues: Array<string> = [];

    @observable editDataType: dtos.DataType = null;
    @observable showEditDataType = false;
    @observable editDataTypeParam: dtos.FlowParameter = null;

    paramsDisposer: any = null;

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    componentDidMount() {
        this.paramsDisposer = observe(this.props.parameters, () => this.forceUpdate());
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.parameters != prevProps.parameters) {
            if (this.paramsDisposer) {
                this.paramsDisposer();
            }
            this.paramsDisposer = observe(this.props.parameters, () => this.forceUpdate());
        }
    }

    componentWillUnmount() {
        this.paramsDisposer();
    }

    addNewParam() {
        if (_.find(this.props.parameters, f => f.name == this.newParamName)) {
            ui.message.error("A field with this name already exists");
            return;
        }

        if (_.find(this.props.exits, ex => ex.name == this.newParamName)) {
            ui.message.error("There is already a flow exit with this name");
            return;
        }

        if (!validateParamName(this.newParamName)) {
            ui.message.error("This is not a valid field name. Names should not contain any spaces");
            return;
        }

        let field = new dtos.FlowParameter({
            id: uuid(),
            name: this.newParamName,
            type: this.newParameterType,
            defaultValue: new dtos.Value(),
            isPublic: this.newParamPublic,
            isOutput: this.newParamOutput,
            isKnob: this.newParamKnob,
            listType: this.newParamListType
        });

        this.props.parameters.push(field);
        this.newParamName = "";
        this.showNewParam = false;
        this.forceUpdate();
        this.props.onChanged();
    }

    removeParam(f: dtos.FlowParameter) {
        let idx = this.props.parameters.indexOf(f);
        if (idx >= 0) {
            this.props.parameters.splice(idx, 1);
            this.forceUpdate();
            this.props.onChanged();
        }
    }

    setIsPublic(f: dtos.FlowParameter, isPublic: boolean) {
        f.isPublic = isPublic;
        if (f.isPublic) {
            f.isKnob = false;
        }
        this.forceUpdate();
    }

    setIsKnob(f: dtos.FlowParameter, isPublic: boolean) {
        f.isKnob = isPublic;
        if (f.isKnob) {
            f.isPublic = false;
            f.isOutput = false;
        }
        this.forceUpdate();
    }

    setDescription(f: dtos.FlowParameter, des: string) {
        f.description = des;
        this.forceUpdate();
    }

    setIsOutput(f: dtos.FlowParameter, isOutput: boolean) {
        f.isOutput = isOutput;
        if (f.isOutput) {
            f.isKnob = false;
        }
        this.forceUpdate();
    }

    editFieldValues(field: dtos.DataField) {
        this.editValuesField = field;
        this.editValues = [...(field.possibleValues || [])];
        this.showEditValues = true;
    }

    saveFieldValues() {
        this.editValuesField.possibleValues = this.editValues;
        this.showEditValues = false;
    }

    move(rec: dtos.FlowParameter, dir: number) {
        let idx = this.props.parameters.indexOf(rec);
        let newIdx = idx + dir;
        if (newIdx < 0 || newIdx >= this.props.parameters.length) return;
        this.props.parameters.splice(idx, 1);
        this.props.parameters.splice(newIdx, 0, rec);
        this.forceUpdate();
        this.props.onChanged();
    }

    setNewParameterType(t: dtos.ValueTypes) {
        this.newParameterType = t;
        if (t === dtos.ValueTypes.List) {
            this.newParamListType = new dtos.DataType({
                fields: []
            });
        } else {
            this.newParamListType = null;
        }
    }

    startEditDataType(p: dtos.FlowParameter) {
        this.editDataTypeParam = p;
        this.editDataType = new dtos.DataType(JSON.parse(JSON.stringify(p.listType)));
        this.showEditDataType = true;
    }

    saveDataType() {
        for (let field of this.editDataType.fields) {
            if (!field.name) {
                ui.message.error("All fields must have a name");
                return;
            }
        }

        this.editDataTypeParam.listType = this.editDataType;
        this.showEditDataType = false;
    }

    render() {
        return (
            <OverflowPanel>
                <div>
                    <ui.Table dataSource={this.props.parameters} rowKey="id" pagination={false}>
                        <ui.Table.Column dataIndex="name" title="Name" />
                        <ui.Table.Column dataIndex="type" title="Type" render={(text, rec: dtos.FlowParameter) => rec.type} />
                        <ui.Table.Column dataIndex="defaultValue" title="Default" render={(text, rec: dtos.FlowParameter) =>
                            <ValueEditor valueType={rec.type} value={rec.defaultValue} field={rec} accountId={this.props.accountId} />} />
                        <ui.Table.Column dataIndex="description" title="Description" render={(text, rec: dtos.FlowParameter) => <ui.Input value={rec.description} onChange={ev => this.setDescription(rec, ev.target.value)} />} />
                        <ui.Table.Column dataIndex="isSlot" title="Public" render={(text, rec: dtos.FlowParameter) =>
                            <ui.Checkbox checked={rec.isPublic} onChange={ev => this.setIsPublic(rec, ev.target.checked)} />} />
                        <ui.Table.Column dataIndex="isKnob" title="Knob" render={(text, rec: dtos.FlowParameter) =>
                            <ui.Checkbox checked={rec.isKnob} onChange={ev => this.setIsKnob(rec, ev.target.checked)} />} />
                        <ui.Table.Column dataIndex="isOutput" title="Output" render={(text, rec: dtos.FlowParameter) =>
                            <ui.Checkbox checked={rec.isOutput} onChange={ev => this.setIsOutput(rec, ev.target.checked)} />} />
                        <ui.Table.Column dataIndex="uiTab" title="Tab" render={(text, rec: dtos.FlowParameter) =>
                            <ui.Input width={60} value={rec.uiTab || ""} onChange={ev => { rec.uiTab = ev.target.value; this.forceUpdate(); }} />} />
                        <ui.Table.Column title="Actions" render={(text, rec: dtos.FlowParameter, idx: number) =>
                            <React.Fragment>
                                {rec.type === dtos.ValueTypes.List && <ui.Button.Group size="small">
                                    <ui.Button title="Edit Data Type" onClick={() => { this.startEditDataType(rec) }}><i className="fa fa-database" /></ui.Button>
                                </ui.Button.Group>}

                                <ui.Button.Group size="small" style={{ marginLeft: 6 }}>
                                    {rec.type == dtos.ValueTypes.String && <ui.Button onClick={() => this.editFieldValues(rec)}><i className="fa fa-list" /></ui.Button>}
                                    <ui.Popconfirm title="Remove this flow parameter?" onConfirm={() => this.removeParam(rec)}><ui.Button><i className="fa fa-trash" /></ui.Button></ui.Popconfirm>
                                </ui.Button.Group>

                                <ui.Button.Group size="small" style={{ marginLeft: 6 }}>
                                    <ui.Button disabled={idx == 0} onClick={() => this.move(rec, -1)}><i className="fa fa-arrow-alt-up" /></ui.Button>
                                    <ui.Button disabled={idx == this.props.parameters.length - 1} onClick={() => this.move(rec, 1)}><i className="fa fa-arrow-alt-down" /></ui.Button>
                                </ui.Button.Group>
                            </React.Fragment>} />
                    </ui.Table>
                    <ui.Button onClick={() => this.showNewParam = true} block type="primary">Add Parameter</ui.Button>
                    <ui.Modal title="New Parameter" okText="Add Parameter" onOk={() => this.addNewParam()} visible={this.showNewParam} onCancel={() => this.showNewParam = false}>
                        <ui.Form layout="vertical" onKeyPress={ev => ev.which == 13 && this.addNewParam()}>
                            <ui.Form.Item label="Name">
                                <AutoFocus visible={this.showNewParam}>
                                    <ui.Input value={this.newParamName} onChange={ev => this.newParamName = ev.target.value} />
                                </AutoFocus>
                            </ui.Form.Item>
                            <ui.Form.Item label="Type">
                                <ui.Select showSearch value={this.newParameterType} onChange={v => this.setNewParameterType(v)}>
                                    {Object.keys(dtos.ValueTypes).filter(t => t != dtos.ValueTypes.Custom && t != dtos.ValueTypes.NotSpecified && t != dtos.ValueTypes.Transition && t != dtos.ValueTypes.Struct).sort((a, b) => a.localeCompare(b)).map(t => <ui.Select.Option key={t} value={t}>{t}</ui.Select.Option>)}
                                </ui.Select>
                            </ui.Form.Item>
                            <ui.Form.Item>
                                <ui.Checkbox value={this.newParamPublic} onChange={b => { this.newParamPublic = b.target.checked; this.newParamKnob = false; }}>Public</ui.Checkbox>
                                <ui.Checkbox value={this.newParamKnob} onChange={b => { this.newParamKnob = b.target.checked; this.newParamOutput = false; this.newParamPublic = false }}>Knob</ui.Checkbox>
                                <ui.Checkbox value={this.newParamOutput} onChange={b => { this.newParamOutput = b.target.checked; this.newParamKnob = false; }}>Output</ui.Checkbox>
                            </ui.Form.Item>
                            <ui.Alert type="info" message="Note: you cannot change flow parameter names after creation" />
                        </ui.Form>
                    </ui.Modal>
                    <ui.Modal title="Edit Possible Values" visible={this.showEditValues} onOk={() => this.saveFieldValues()} onCancel={() => this.showEditValues = false}>
                        <table>
                            <thead className="ant-table-thead">
                                <tr>
                                    <th>Value</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody className="ant-table-tbody">
                                {this.editValues.map((v, i) =>
                                    <tr key={"row-" + i} className="ant-table-row ant-table-row-level-0">
                                        <td>
                                            <ui.Input value={v} onChange={ev => this.editValues[i] = ev.target.value} />
                                        </td>
                                        <td>
                                            <a onClick={() => this.editValues.splice(i, 1)}>Remove</a>
                                        </td>
                                    </tr>)}
                            </tbody>
                        </table>
                        <br />
                        <ui.Button type="primary" onClick={() => this.editValues.push("")}>Add Item</ui.Button>
                    </ui.Modal>
                    <ui.Modal title="Edit Data Type" visible={this.showEditDataType} onOk={() => this.saveDataType()} onCancel={() => this.showEditDataType = false}>
                        <DataTypeEditor dataType={this.editDataType} />
                    </ui.Modal>
                </div>
            </OverflowPanel>
        );
    }
}