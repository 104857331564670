import { makeObservable, React, dtos, serviceClient, ui, Link, observable, observer, shared, selectFilter } from "./common"

interface LoginProps {
    onAuthenticated: Function;
}

@observer
export default class Login extends React.Component<LoginProps> {
    @observable emailAddress = "";
    @observable password = "";
    @observable showForgotPassword = false;
    @observable needsAccountId = false;
    @observable accountId = "";
    @observable accountIds: string[] = [];
    @observable accountNames: string[] = [];

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    async signIn() {
        await serviceClient.post(new dtos.Authenticate({
            provider: "credentials",
            rememberMe: true,
            userName: this.emailAddress,
            password: this.password
        }));
        var authStatus = await serviceClient.get(new dtos.GetAuthStatus());
        shared.roles = authStatus.roles;
        shared.dashboardPermissions = authStatus.dashboardPermissions;
        
        if (authStatus.accountIds.length == 1) {
            shared.accountId = authStatus.accountIds[0];
            shared.accountName = authStatus.accountNames[0];
            localStorage.setItem("accountId", shared.accountId);
            this.props.onAuthenticated();
        } else {
            this.accountIds = authStatus.accountIds;
            this.accountNames = authStatus.accountNames;
            this.accountId = authStatus.accountIds[0];
            this.needsAccountId = true;
        }
    }

    setAccountId() {
        if (!this.accountId) {
            ui.message.error("Select an account");
            return;
        }

        var idx = this.accountIds.indexOf(this.accountId);
        shared.accountId = this.accountId;
        shared.accountName = this.accountNames[idx];
        shared.hasMultipleAccounts = true;
        localStorage.setItem("accountId", shared.accountId);
        this.props.onAuthenticated();
    }

    async recoverPassword() {
        let request = new dtos.ForgotPassword();
        request.emailAddress = this.emailAddress;
        await serviceClient.post(request);
        ui.message.success("Please check your email for a password recovery link");
        this.showForgotPassword = false;
    }

    doAction() {
        if (this.needsAccountId) {
            this.setAccountId();
        } else if (this.showForgotPassword) {
            this.recoverPassword();
        } else {
            this.signIn();
        }
    }

    render() {
        const footer = (
            <div>
                {!this.showForgotPassword && <a onClick={() => this.showForgotPassword = true} style={{ float: "left" }}>Forgot Password</a>}
                {this.showForgotPassword && <a onClick={() => this.showForgotPassword = false} style={{ float: "left" }}>Sign In</a>}
                {!this.showForgotPassword && <ui.Button type="primary" onClick={() => this.doAction()}><i style={{ fontSize: 18 }} className="fal fa-comment-alt-smile" /></ui.Button>}
                {this.showForgotPassword && <ui.Button type="primary" onClick={() => this.doAction()}>Reset Password</ui.Button>}
            </div>
        );

        return (
            <div>
                <ui.Modal open={true} cancelButtonProps={{ disabled: true }} title="Sign in to Evo Voice" footer={footer}>
                    {!this.needsAccountId && <ui.Form layout="vertical">
                        <ui.Form.Item label="Email Address/User Name">
                            <ui.Input autoFocus placeholder="Enter your email address or user name" prefix={<i className="fa fa-user" style={{ color: 'rgba(0,0,0,.25)' }} />} type="email" value={this.emailAddress} onChange={ev => this.emailAddress = ev.target.value } onPressEnter={() => this.doAction()} />
                        </ui.Form.Item>
                        {!this.showForgotPassword &&
                            <ui.Form.Item label="Password">
                                <ui.Input.Password placeholder="Enter your password" prefix={<i className="fa fa-key" style={{ color: 'rgba(0,0,0,.25)' }} />} value={this.password} onChange={ev => this.password = ev.target.value} onPressEnter={() => this.doAction()} />
                            </ui.Form.Item>}
                    </ui.Form>}
                    {this.needsAccountId && <ui.Form layout="vertical">
                        <ui.Form.Item label="Choose Account">
                            <ui.Select autoFocus showSearch filterOption={selectFilter} value={this.accountId} onChange={v => this.accountId = v} dropdownMatchSelectWidth={false}>
                                {this.accountIds.map((v, i) => <ui.Select.Option key={v} value={this.accountIds[i]}>{this.accountNames[i]}</ui.Select.Option>)}
                            </ui.Select>
                        </ui.Form.Item>
                    </ui.Form>}
                </ui.Modal>
            </div>
        );
    }
}