import { HashRouter, Routes, Route } from "react-router-dom";
import Auth from "./auth";
import Public from "./public";
import RecorderWindow from "./recorder";

export default function App() {
    return <HashRouter>
        <Routes>
            <Route path="/public" element={<Public />} />
            <Route path="/recorder" element={<RecorderWindow />} />
            <Route path="*" element={<Auth />} />
        </Routes>
    </HashRouter>;
}
