import { React, dtos, serviceClient, observable, observer, ui, moment, makeObservable } from "../common";

interface Props {
    accountId: string;
}

@observer
export default class DashboardUsage extends React.Component<Props> {
    @observable accountId = "";
    @observable startDate = moment().startOf("week");
    @observable endDate = moment().endOf("week");
    @observable usage: Array<dtos.UsageRecordInfo> = [];
    @observable loading = false;

    constructor(props) {
        super(props);
        makeObservable(this);
    }
    
    componentDidMount() {
        this.accountId = this.props.accountId;
        this.refresh();
    }

    componentDidUpdate(prevProps) {
        if (this.props.accountId != prevProps.accountId) {
            this.accountId = this.props.accountId;
            this.refresh();
        }
    }

    setDateRange(range) {
        this.startDate = range[0];
        this.endDate = range[1];
    }

    async refresh() {
        try {
            this.loading = true;
            this.usage = await serviceClient.get(new dtos.ListUsageRecords({
                accountId: this.accountId,
                startDate: this.startDate.format("YYYY-MM-DD"),
                endDate: this.endDate.format("YYYY-MM-DD")
            }));
        } finally {
            this.loading = false;
        }
    }

    getUsage(rec: dtos.UsageRecordInfo) {
        if (rec.category == "totalprice") return "";
        return rec.usage + " " + rec.usageUnit;
    }
    render() {
        return (
            <div>
                <ui.Form layout="inline" style={{ marginBottom: 8 }}>
                    <ui.Form.Item>
                        <ui.DatePicker.RangePicker value={[this.startDate, this.endDate]} onChange={ev => this.setDateRange(ev)} />
                    </ui.Form.Item>
                    
                    <ui.Form.Item>
                        <ui.Button.Group>
                            <ui.Button type="primary" onClick={() => this.refresh()}>Search</ui.Button>
                        </ui.Button.Group>
                    </ui.Form.Item>
                </ui.Form>
                <ui.Table dataSource={this.usage} rowKey="uri" loading={this.loading} pagination={false} style={{ marginBottom: 24 }}>
                    <ui.Table.Column dataIndex="description" title="Description" />
                    <ui.Table.Column dataIndex="startDate" title="Date Range" render={(text, rec:dtos.UsageRecordInfo) => moment(rec.startDate).format("LL") + " - " + moment(rec.endDate).format("LL")} />
                    <ui.Table.Column dataIndex="count" title="Count" render={(text, rec: dtos.UsageRecordInfo) => rec.count == 0 ? "" : (Math.round(rec.count * 100) / 100) + " " + rec.countUnit} />
                    <ui.Table.Column dataIndex="usage" title="Usage" render={(text, rec: dtos.UsageRecordInfo) => this.getUsage(rec)} />
                    <ui.Table.Column dataIndex="price" title="Price" render={(text, rec: dtos.UsageRecordInfo) => (Math.round(rec.price * 100) / 100) + " " + rec.priceUnit} />
                </ui.Table>
            </div>
        );
    }
}