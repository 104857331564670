import { React, serviceClient, dtos, observer, shared, observable, ui, _, Link, moment, selectFilter, makeObservable, cache } from "../common";
import ValueEditor from "../flows/value-editor";
import { KeyboardEvent } from "react";
import TagsEditor from "../tags-editor";
import AutoComplete from "../autocomplete";

interface Props {
    parentCustomerId?: string;
    children?: React.ReactNode;
}

@observer
export default class CustomerList extends React.Component<Props> {
    @observable customers: Array<dtos.CustomerInfo> = [];
    @observable pagination: any = { position: "both" };
    @observable filter: any = {};
    @observable sort: any = {};
    @observable customerFields: Array<dtos.CustomerDataField> = [];
    @observable loading = true;
    @observable pageSize = 20;
    @observable selectedCustomerIds: Array<string> = [];
    @observable tags: Array<dtos.Tag> = [];
    @observable allTags: Array<dtos.Tag> = [];
    @observable showNewCustomer = false;
    @observable assignParentId = "";
    @observable assignToTagId = "";
    @observable showImport = false;
    @observable showBulkUpdate = false;

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    async componentDidMount() {
        let systemSettings = await cache.getSystemSettings();
        this.customerFields = systemSettings.customerFields;
        this.allTags = systemSettings.tags;
        this.refresh(1);
    }

    async refresh(page = this.pagination.page || 1, sort = this.sort) {
        this.loading = true;
        let request = new dtos.ListCustomers({
            accountIds: [shared.accountId],
            countPerPage: this.pageSize,
            page: page - 1,
            sortField: sort.field,
            sortOrder: sort.order,
            shallowParent: this.props.parentCustomerId || this.filter.shallowParent ? true : false,
            nameFilter: this.filter.name || null,
            tagIds: this.tags.length > 0 ? this.tags.map(t => t.id) : null,
            parentCustomerIds: this.props.parentCustomerId ? [this.props.parentCustomerId] : (this.filter.customerId ? [this.filter.customerId] : null),
            simplifiedPaging: true,
        });
        let response = await serviceClient.get(request);
        let currentlySelected = this.customers.filter(ep => this.selectedCustomerIds.indexOf(ep.id) >= 0);
        this.customers = [...currentlySelected, ...response.items.filter(c => this.selectedCustomerIds.indexOf(c.id) < 0)];
        let pager = { ...this.pagination };
        pager.current = page;
        pager.total = page * this.pageSize + (response.hasMorePages ? 1 : 0);
        pager.pageSize = this.pageSize;
        pager.showSizeChanger = true;
        pager.pageSizeOptions = ["10", "20", "50", "100", "250"];
        pager.onShowSizeChange = (c, v) => this.pageSize = v;

        this.pagination = pager;
        this.loading = false;
    }

    getCustomerDataText(cust: dtos.CustomerInfo, f: dtos.CustomerDataField) {
        var val = cust.data[f.name] as dtos.Value;
        if (!val) return <span />;
        return <ValueEditor readOnly field={f} value={val} accountId={shared.accountId} valueType={f.type} />;
    }

    checkFilterSubmit(ev: KeyboardEvent) {
        if (ev.which == 13) {
            this.refresh();
        }
    }

    async assignParent() {
        this.loading = true;
        for (let id of this.selectedCustomerIds) {
            let updated = await serviceClient.patch(new dtos.PatchCustomer({
                customerId: id,
                parentCustomerId: this.assignParentId
            }));
            Object.assign(_.find(this.customers, ep => ep.id == id), updated);

        }
        this.refresh();
    }

    async assignToTag() {
        this.loading = true;
        for (let id of this.selectedCustomerIds) {
            let cust = _.find(this.customers, e => e.id == id);
            let tagIds = cust.tags.map(t => t.id);
            if (tagIds.indexOf(this.assignToTagId) < 0) {
                tagIds.push(this.assignToTagId);
            }
            let updated = await serviceClient.patch(new dtos.PatchCustomer({
                customerId: id,
                tagIds: tagIds
            }));
            Object.assign(_.find(this.customers, ep => ep.id == id), updated);

        }
        this.selectedCustomerIds = [];
        this.refresh();
    }

    async removeFromTag() {
        this.loading = true;
        for (let id of this.selectedCustomerIds) {
            let cust = _.find(this.customers, e => e.id == id);
            let tagIds = cust.tags.map(t => t.id);
            let idx = tagIds.indexOf(this.assignToTagId);
            if (idx >= 0) {
                tagIds.splice(idx, 1);
                let updated = await serviceClient.patch(new dtos.PatchCustomer({
                    customerId: id,
                    tagIds: tagIds
                }));
                Object.assign(_.find(this.customers, ep => ep.id == id), updated);
            }
        }
        this.selectedCustomerIds = [];
        this.refresh();
    }

    async deleteCustomer(cust: dtos.CustomerInfo) {
        await serviceClient.delete(new dtos.DeleteCustomer({
            customerId: cust.id
        }));
        let idx = this.customers.indexOf(cust);
        this.customers.splice(idx, 1);
        this.forceUpdate();
        ui.message.info(cust.name + " deleted");
    }
    async deleteCustomers() {
        this.loading = true;
        for (let custId of this.selectedCustomerIds) {
            await serviceClient.delete(new dtos.DeleteCustomer({ customerId: custId }));
        }
        this.selectedCustomerIds = [];
        this.refresh();
        this.loading = false;
    }

    onCustomerCreated(cust: dtos.CustomerInfo) {
        window.location.hash = `/customers/${cust.id}`;
        this.showNewCustomer = false;
    }

    render() {
        const rowSelection = {
            selectedRowKeys: this.selectedCustomerIds,
            onChange: keys => this.selectedCustomerIds = keys
        };

        function getValue(rec: dtos.CustomerInfo, f: dtos.CustomerDataField) {
            rec.data[f.name] = rec.data[f.name] || new dtos.Value();
            return rec.data[f.name];
        }

        const embedded = this.props.parentCustomerId;

        return (
            <div>
                <ui.Form layout="inline" style={{ marginBottom: 8 }}>
                    <ui.Form.Item>
                        <ui.Input onKeyPress={ev => this.checkFilterSubmit(ev)} placeholder="Filter by name" value={this.filter.name || ""} onChange={ev => this.filter.name = ev.target.value} />
                    </ui.Form.Item>
                    {!this.props.parentCustomerId && <>
                        <ui.Form.Item>
                            <AutoComplete includeNotAssigned type={dtos.ValueTypes.Customer} value={this.filter.customerId} onChanged={v => { this.filter.customerId = v; this.refresh(); }} placeholder="Filter by parent customer" />
                        </ui.Form.Item>
                        {this.filter.customerId && this.filter.customerId != "_" && <ui.Form.Item style={{ marginLeft: -8 }}>
                            <ui.Tooltip title="Select this option to show only direct children of the specified customer"><ui.Button type={this.filter.shallowParent ? "primary" : "default"} onClick={() => { this.filter.shallowParent = !this.filter.shallowParent; this.refresh() }}><i className="fa fa-sitemap" /></ui.Button></ui.Tooltip>
                        </ui.Form.Item>}
                    </>}
                    <ui.Form.Item>
                        <TagsEditor placeholder="Filter by tag" tags={this.tags} />
                    </ui.Form.Item>
                    <ui.Form.Item>
                        <ui.Button.Group>
                            <ui.Button type="primary" onClick={() => this.refresh()}>Search</ui.Button>
                            <ui.Button type="default" onClick={() => { this.filter = {}; this.selectedCustomerIds = []; this.tags = []; this.refresh(); }}>Clear</ui.Button>
                        </ui.Button.Group>
                    </ui.Form.Item>
                </ui.Form>
                <ui.Form layout="inline">
                    {this.selectedCustomerIds.length > 0 &&
                        <React.Fragment>
                            <ui.Form.Item>
                                <ui.Input.Group compact>
                                    <AutoComplete type={dtos.ValueTypes.Customer} placeholder="Assign Parent" value={this.assignParentId} onChanged={v => this.assignParentId = v} />
                                    <ui.Popconfirm title="Assign this parent customer to all of the selected customers?" onConfirm={() => this.assignParent()}><ui.Button type="default" disabled={!this.assignParentId}><i className="fa fa-save" /></ui.Button></ui.Popconfirm>
                                </ui.Input.Group>
                            </ui.Form.Item>
                            <ui.Form.Item>
                                <ui.Input.Group compact>
                                    <ui.Select showSearch filterOption={selectFilter} style={{ width: 175 }} dropdownMatchSelectWidth={false} placeholder="Assign/Remove Tag" value={this.assignToTagId || undefined} onChange={v => this.assignToTagId = v}>
                                        {this.allTags.map(c => <ui.Select.Option key={c.id} value={c.id}>{c.name}</ui.Select.Option>)}
                                    </ui.Select>
                                    <ui.Button.Group>
                                        <ui.Popconfirm title="Assign this tag to all of the selected customers?" onConfirm={() => this.assignToTag()}><ui.Button title="Assign to tag" type="default" disabled={!this.assignToTagId}><i className="fa fa-save" /></ui.Button></ui.Popconfirm>
                                        <ui.Popconfirm title="Remove this tag from all of the selected customers?" onConfirm={() => this.removeFromTag()}><ui.Button title="Remove from tag" type="default" disabled={!this.assignToTagId}><i className="fa fa-times" /></ui.Button></ui.Popconfirm>
                                    </ui.Button.Group>
                                </ui.Input.Group>
                            </ui.Form.Item>

                        </React.Fragment>}
                </ui.Form>
                <ui.Table className="hide-pagination-buttons" rowSelection={rowSelection} loading={this.loading} dataSource={this.customers} rowKey="id" pagination={this.pagination} onChange={(p, f, s) => this.refresh(p.current, s)}>
                    <ui.Table.Column dataIndex="name" title="Name" render={(text, rec: dtos.CustomerInfo) => <>
                        <Link to={`/customers/${rec.id}`}>{rec.name}</Link>
                    </>} />
                    {!this.props.parentCustomerId &&
                        <ui.Table.Column dataIndex="breadcrumb" title="Parent" render={(text, rec: dtos.CustomerInfo) =>
                            <ui.Breadcrumb>
                                {rec.breadcrumb.map((bc, i) => i < rec.breadcrumb.length - 1 && <ui.Breadcrumb.Item key={bc.id}><Link to={`/customers/${bc.id}`}>{bc.name}</Link></ui.Breadcrumb.Item>)}
                            </ui.Breadcrumb>
                        } />
                    }
                    <ui.Table.Column dataIndex="referenceId" title="Reference ID" />

                    <ui.Table.Column title="Tags" render={(text, rec: dtos.CustomerInfo) =>
                        <div>
                            {rec.tags.map(t => <ui.Tag key={t.id} color={t.color.toLowerCase()}>{t.name}</ui.Tag>)}
                        </div>} />
                    {this.customerFields.filter(f => f.showInSearch).map(f => <ui.Table.Column key={f.name} dataIndex={f.name} title={f.name} render={(text, rec: dtos.CustomerInfo) =>
                        <span>{this.getCustomerDataText(rec, f)}</span>} />)}
                    {this.props.children}
                </ui.Table>

            </div>
        );
    }
}