import { React, Routes, Route, serviceClient, initServiceClient, dtos, ui, observable, observer, shared, makeObservable } from "./common";
import Login from "./login";
import ManagerIndex from "./manager";

function NotAuthenticated(props) {
    async function signOut() {
        await serviceClient.post(new dtos.Authenticate({ provider: "logout" }));
        window.location.reload();
    }
    return <div style={{ padding: 50, textAlign: "center" }}><h1>Invalid User</h1><p>You are currently signed in as an app user or console user. <br /> This user does not have access to the Evo Voice Dashboard. Please <a onClick={signOut}>Sign Out</a> and sign in as an admin</p></div>;
}

@observer
export default class Auth extends React.Component {
    @observable needsAuth = true;
    @observable hasConfig = false;
    @observable loginEmail = "";
    @observable roles: Array<string> = [];

    constructor(props) {
        super(props);
        makeObservable(this);
    }
    
    componentDidMount() {
        console.log("auth mounted");
        shared.collapsed = localStorage.getItem("ManagerIndex.collapsed") == "true";

        fetch("./config.json").then(res => {
            res.json().then(cfg => {
                shared.isDev = cfg.isDev === true;
                initServiceClient(cfg.apiUrl);
                serviceClient.baseUrl = cfg.apiUrl;
                serviceClient.onAuthenticationRequired = this.onAuthenticationRequired.bind(this);
                serviceClient.get(new dtos.GetAuthStatus()).then(user => {
                    if (!user.isAuthenticated) {
                        this.onAuthenticationRequired();
                    } else {
                        var accountId = localStorage.getItem("accountId");
                        var accountIndex = user.accountIds.indexOf(accountId);
                        if (!accountId || accountIndex < 0) {
                            serviceClient.post(new dtos.Authenticate({ provider: "logout" })).then(() => {
                                localStorage.clear();
                                this.onAuthenticationRequired();
                            });
                        } else {
                            shared.accountId = accountId;
                            shared.accountName = user.accountNames[accountIndex];
                            shared.roles = user.roles;
                            shared.hasMultipleAccounts = user.accountIds.length > 1;
                            shared.dashboardPermissions = user.dashboardPermissions;
                            this.loginEmail = user.emailAddress;
                            this.roles = user.roles;
                            this.needsAuth = false;
                            this.hasConfig = true;
                            this.forceUpdate();
                        }
                    }
                });
            });
        });
    }

    onAuthenticated() {
        this.needsAuth = false;
        serviceClient.onAuthenticationRequired = this.onAuthenticationRequired.bind(this);
        serviceClient.get(new dtos.GetAuthStatus()).then(user => {
            this.loginEmail = user.emailAddress;
            this.roles = user.roles;
            this.needsAuth = false;
            this.hasConfig = true;
        });
    }

    onAuthenticationRequired() {
        console.log("auth required", this);
        window.location.hash = "#/";
        serviceClient.onAuthenticationRequired = null;
        this.hasConfig = true;
        this.needsAuth = true;
        this.forceUpdate();
        return new Promise<any>((resolve, reject) => { });
    }

    render() {
        if (!this.hasConfig) {
            return <div style={{ textAlign: "center", marginTop: 200 }}><ui.Spin size="large" /></div>;
        } else if (!this.needsAuth) {
            // const isSystemAdmin = this.roles.indexOf("SystemAdministrator") >= 0;
            // const isManager = isSystemAdmin || this.roles.indexOf("Manager") >= 0 || this.roles.indexOf("Customer") >= 0;
            // const isUser = this.roles.indexOf("User") >= 0;
            // const isCustomer = this.roles.indexOf("Customer") >= 0;
            // const simplified = this.roles.indexOf("CustomerSimplified") >= 0;

            if (shared.isManager()) {
                return <ManagerIndex loginEmail={this.loginEmail} />;
            } else if (shared.isUser()) {
                return (
                    <Routes>
                        <Route path="*" element={<NotAuthenticated />} />
                    </Routes>
                );
            } else {
                return <div>Invalid User Type</div>;
            }
        } else {
            return <Login onAuthenticated={() => this.onAuthenticated()} />;
        }
    }
}