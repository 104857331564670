import { React, ui, dtos, _, observer, observable, splitIntoWords } from "../common";
import FlowCanvas from "./canvas";
import FlowUtil from "./util";

interface Props {
    node: dtos.FlowNode;
    canvas: FlowCanvas;
    selected: boolean;
    onClick: () => void;
    onDelete: () => void;
    onCopy: () => void;
}

interface TransitionProps {
    canvas: FlowCanvas;
    id: string;
}

class Transition extends React.Component<TransitionProps> {
    div = React.createRef<HTMLDivElement>();

    componentDidMount() {
        let { canvas, id } = this.props;
        canvas.addTransition(id);
    }

    componentWillUnmount() {
        let { canvas } = this.props;
        canvas.instance.remove(this.div.current);
    }

    render() {
        return (
            <div ref={this.div} id={this.props.id} className="ep" />
        );
    }
}

@observer
export default class FlowCanvasNode extends React.Component<Props> {
    div = React.createRef<HTMLDivElement>();
    @observable editingTitle = false;
    @observable editName = "";

    componentDidMount() {
        let { canvas, node } = this.props;
        canvas.addNode(node.id, node.isStartNode);
        setTimeout(() => {
            for (let trans of FlowUtil.getTransitions(node.parameters, node.spec.dataType)) {
                if (trans.param.value.stringValue) {
                    canvas.connect(trans.param.id, trans.param.value.stringValue);
                }
            }
        });
    }

    refreshTransitions() {
        this.forceUpdate(() => {
            setTimeout(() => {
                let { canvas, node } = this.props;
                for (let trans of FlowUtil.getTransitions(node.parameters, node.spec.dataType)) {
                    if (trans.param.value.stringValue) {
                        canvas.reconnect(trans.param.id, trans.param.value.stringValue);
                    }
                }
                canvas.instance.revalidate(node.id);
            });
        });
    }

    onClick(ev) {
        if ((window.event.target as any).tagName.toLowerCase() == "input") return;
        ev.preventDefault();
        ev.stopPropagation();
        this.div.current.focus();
        this.props.onClick();
    }

    onKeyDown(ev) {
        if (ev.keyCode == 46) {
            this.props.canvas.deleteNode(this);
            this.props.onDelete();
        }
    }

    getTransitionId(fieldId) {
        return this.props.node.id + "." + fieldId;
    }

    editNodeTitle() {
        this.editingTitle = true;
        this.editName = this.props.node.name || this.props.node.spec.name;
        this.refreshTransitions();
    }

    stopEditNodeTitle() {
        this.editingTitle = false;
        this.refreshTransitions();
    }

    onEditKeyPress(ev) {
        if (ev.which == 13) {
            this.acceptName();
        }
    }

    setEditName(name:string) {
        this.editName = name;
        this.forceUpdate();
    }

    acceptName() {
        this.props.node.name = this.editName;
        this.editingTitle = false;
        this.refreshTransitions();
    }

    copyNode() {
        this.props.onCopy();
    }

    render() {
        let { node, selected } = this.props;
        let transitions = FlowUtil.getTransitions(node.parameters, node.spec.dataType);
        return (
            <div ref={this.div} id={node.id} onClick={ev => this.onClick(ev)} className={"flow-node " + (this.props.selected ? "selected" : "")} style={{ outline: "none", left: node.ui.x, top: node.ui.y }} onKeyDown={ev => this.onKeyDown(ev)} tabIndex={0}>
                {node.ui.notes && <div style={{ "position": "absolute", "top": "-25px", "right": "-25px" }}><ui.Tooltip title={node.ui.notes}><ui.Button size="small" shape="circle"><i className="fa fa-info" /></ui.Button></ui.Tooltip></div>}
                <ui.Table dataSource={transitions} pagination={false} size="small" rowKey={rec => rec.param.id} className={selected ? "selected" : ""}>
                    <ui.Table.Column dataIndex="name" render={(text, rec: any) => <span>{splitIntoWords(rec.name)} {rec.async && <i className="far fa-clock" />}</span>}
                        title={
                            this.editingTitle ?
                                <ui.Input size="small" value={this.editName} onBlur={() => this.stopEditNodeTitle()} onChange={ev => this.setEditName(ev.target.value)} onKeyPress={ev => this.onEditKeyPress(ev)} autoFocus />
                                : <span style={{ whiteSpace: "nowrap" }} onDoubleClick={() => this.editNodeTitle()}><ui.Tooltip title={node.spec.name}><i className={node.spec.iconClass} /></ui.Tooltip> {this.props.node.name || this.props.node.spec.name}</span>
                    } />
                    <ui.Table.Column width={30} align="center" title={(this.editingTitle && <ui.Button size="small" onClick={() => this.stopEditNodeTitle()}><i className="fa fa-times" /></ui.Button>) || (!this.editingTitle && selected && <ui.Popconfirm onConfirm={() => this.copyNode()} title="Copy node?"><ui.Button type="primary" size="small" title="Copy Node"><i className="fa fa-copy" /></ui.Button></ui.Popconfirm>)} render={(text, rec: any) =>
                        <Transition canvas={this.props.canvas} key={rec.param.id} id={rec.param.id} />} />
                </ui.Table>
            </div>
        );
    }
}