import { useEffect, useState } from "react";
import { React, serviceClient, dtos, observer, observable, ui, Link, _, splitIntoWords, uuid, selectFilter, shared, moment, moveArray, removeArray, makeObservable, withRouter, cache, getEndpointIcon } from "../common";
import { voices } from "./aivoices";
import { AssistantContact, AssistantIntegration, AssistantLink, AssistantSettings, AssistantTakeMessageField, AssistantWord } from "../dtos";

interface Props {
    settings: dtos.AssistantSettings;
    onChanged(settings: dtos.AssistantSettings);
}

export default function AssistantSettingsEditor(props: Props) {
    const [settings, setSettings] = useState(props.settings);
    const [editContact, setEditContact] = useState<AssistantContact>(null);
    const [editContactIndex, setEditContactIndex] = useState(0);

    useEffect(function updateSettings() {
        setSettings(props.settings);
    }, [props.settings]);

    useEffect(function checkSettings() {
        if (settings.voiceStyle) {
            const voice = voices.find(v => v.name === settings.voice);
            if (voice.styleList.indexOf(settings.voiceStyle) < 0) {
                updateSettings({ voiceStyle: "" });
            }
        }
    }, [settings.voice]);

    function updateSettings(update: Partial<AssistantSettings>) {
        props.onChanged({ ...settings, ...update });
    }

    function newContact() {
        setEditContactIndex(-1);
        setEditContact({ name: "", phoneNumber: "", emailAddress: "", about: "", takeMessageFields: [], transferType: dtos.AssistantTransferTypes.Blind });
    }

    function startEditContact(i: number) {
        setEditContact(JSON.parse(JSON.stringify(settings.contacts[i])));
        setEditContactIndex(i);
    }

    function saveContact() {
        const contacts = settings.contacts;
        if (editContactIndex < 0) {
            updateSettings({ contacts: [...contacts, editContact] });
        } else {
            contacts[editContactIndex] = editContact;
            updateSettings({ contacts: [...contacts] });
        }
        setEditContact(null);
    }

    return <>
        <ui.Collapse defaultActiveKey={"1"} accordion>
            <ui.Collapse.Panel header="General" key="general" >
                <ui.Form layout="vertical">
                    <ui.Form.Item label="Company Name">
                        <ui.Input value={settings.companyName} onChange={ev => updateSettings({ companyName: ev.target.value })} />
                    </ui.Form.Item>
                    <ui.Form.Item label="Greeting">
                        <ui.Input value={settings.greeting} onChange={ev => updateSettings({ greeting: ev.target.value })} />
                    </ui.Form.Item>
                </ui.Form>
            </ui.Collapse.Panel>
            <ui.Collapse.Panel header="About" key="about">
                <ui.Input.TextArea autoSize value={settings.companyInformation} onChange={ev => updateSettings({ companyInformation: ev.target.value })} />
            </ui.Collapse.Panel>
            <ui.Collapse.Panel header="Contacts" key="contacts">
                <ui.Table className="hide-pagination-buttons" pagination={false} dataSource={settings.contacts} rowKey={(w, i) => i}>
                    <ui.Table.Column title="Name" render={(text, rec: AssistantContact, i) => rec.name} />
                    <ui.Table.Column title="Phone Number" render={(text, rec: AssistantContact, i) => rec.phoneNumber} />
                    <ui.Table.Column title="Email" render={(text, rec: AssistantContact, i) => rec.emailAddress} />
                    <ui.Table.Column title="" render={(text, rec: AssistantContact, i) => <ui.Button.Group>
                        <ui.Button onClick={() => startEditContact(i)}><i className="fa fa-pencil" /></ui.Button>
                        <ui.Popconfirm title="Remove" onConfirm={() => updateSettings({ contacts: settings.contacts.filter(w => w !== settings.contacts[i]) })}><ui.Button><i className="fa fa-trash" /></ui.Button></ui.Popconfirm>
                    </ui.Button.Group>} />
                </ui.Table>
                <ui.Button onClick={newContact} style={{ marginTop: 8 }}>Add Contact</ui.Button>
            </ui.Collapse.Panel>
            <ui.Collapse.Panel header="Links" key="links">
                <ui.Table className="hide-pagination-buttons" pagination={false} dataSource={settings.links} rowKey={(w, i) => i}>
                    <ui.Table.Column title="URL" render={(text, rec: AssistantLink, i) => <ui.Input value={settings.links[i].url} onChange={ev => updateSettings({ links: settings.links.with(i, { ...settings.links[i], url: ev.target.value }) })} />} />
                    <ui.Table.Column title="Description" render={(text, rec: AssistantLink, i) => <ui.Input value={settings.links[i].description} onChange={ev => updateSettings({ links: settings.links.with(i, { ...settings.links[i], description: ev.target.value }) })} />} />
                    <ui.Table.Column title="" render={(text, rec: AssistantLink, i) => <ui.Popconfirm title="Remove" onConfirm={() => updateSettings({ links: settings.links.filter(w => w !== settings.links[i]) })}><ui.Button><i className="fa fa-trash" /></ui.Button></ui.Popconfirm>} />
                </ui.Table>
                <ui.Button onClick={() => updateSettings({ links: [...settings.links, { description: "", url: "" }] })} style={{ marginTop: 8 }}>Add Link</ui.Button>
            </ui.Collapse.Panel>
            <ui.Collapse.Panel header="Integrations" key="integrations">
                <ui.Table className="hide-pagination-buttons" pagination={false} dataSource={settings.integrations} rowKey={(w, i) => i}>
                    <ui.Table.Column title="URL" render={(text, rec: AssistantIntegration, i) => <ui.Input value={settings.integrations[i].uri} onChange={ev => updateSettings({ integrations: settings.integrations.with(i, { ...settings.integrations[i], uri: ev.target.value }) })} />} />
                    <ui.Table.Column title="HTTP Method" render={(text, rec: AssistantIntegration, i) => <ui.Input value={settings.integrations[i].httpMethod} onChange={ev => updateSettings({ integrations: settings.integrations.with(i, { ...settings.integrations[i], httpMethod: ev.target.value }) })} />} />
                    <ui.Table.Column title="Auth Token" render={(text, rec: AssistantIntegration, i) => <ui.Input value={settings.integrations[i].authToken} onChange={ev => updateSettings({ integrations: settings.integrations.with(i, { ...settings.integrations[i], authToken: ev.target.value }) })} />} />
                    <ui.Table.Column title="" render={(text, rec: AssistantIntegration, i) => <ui.Popconfirm title="Remove" onConfirm={() => updateSettings({ integrations: settings.integrations.filter(w => w !== settings.integrations[i]) })}><ui.Button><i className="fa fa-trash" /></ui.Button></ui.Popconfirm>} />
                </ui.Table>
                <ui.Button onClick={() => updateSettings({ integrations: [...settings.integrations, { authToken: "", httpMethod: "", uri: "" }] })} style={{ marginTop: 8 }}>Add Link</ui.Button>
            </ui.Collapse.Panel>
            <ui.Collapse.Panel header="Custom Prompt" key="prompt">
                <ui.Input.TextArea autoSize value={settings.customPrompt} onChange={ev => updateSettings({ customPrompt: ev.target.value })} />
            </ui.Collapse.Panel>
            <ui.Collapse.Panel header="Custom Words" key="words">
                <ui.Table className="hide-pagination-buttons" pagination={false} dataSource={settings.words} rowKey={(w, i) => i}>
                    <ui.Table.Column title="Word" render={(text, rec: AssistantWord, i) => <ui.Input value={settings.words[i].word} onChange={ev => updateSettings({ words: settings.words.with(i, { ...settings.words[i], word: ev.target.value }) })} />} />
                    <ui.Table.Column title="Pronounced" render={(text, rec: AssistantWord, i) => <ui.Input value={settings.words[i].pronounced} onChange={ev => updateSettings({ words: settings.words.with(i, { ...settings.words[i], pronounced: ev.target.value }) })} />} />
                    <ui.Table.Column title="" render={(text, rec: AssistantWord, i) => <ui.Popconfirm title="Remove" onConfirm={() => updateSettings({ words: settings.words.filter(w => w !== settings.words[i]) })}><ui.Button><i className="fa fa-trash" /></ui.Button></ui.Popconfirm>} />
                </ui.Table>
                <ui.Button onClick={() => updateSettings({ words: [...settings.words, { word: "", pronounced: "" }] })} style={{ marginTop: 8 }}>Add Word</ui.Button>
            </ui.Collapse.Panel>
            <ui.Collapse.Panel header="AI Settings" key="ai">
                <ui.Form layout="vertical">
                    <ui.Form.Item label="Tuning">
                        <ui.Select value={settings.tuning} onChange={ev => updateSettings({ tuning: ev })}>
                            <ui.Select.Option key="Accuracy" value="Accuracy">Accuracy</ui.Select.Option>
                            <ui.Select.Option key="Speed" value="Speed">Speed</ui.Select.Option>
                        </ui.Select>
                    </ui.Form.Item>
                    <ui.Form.Item label="Voice">
                        <ui.Select showSearch filterOption={selectFilter} value={settings.voice || ""} onChange={ev => updateSettings({ voice: ev })}>
                            <ui.Select.Option>(Default)</ui.Select.Option>
                            {voices.filter(v => v.locale.indexOf("en") === 0).map(v => <ui.Select.Option key={v.name} value={v.name}>{v.localName} ({v.locale})</ui.Select.Option>)}
                        </ui.Select>
                    </ui.Form.Item>
                    <ui.Form.Item label="Voice Style">
                        <ui.Select showSearch filterOption={selectFilter} value={settings.voiceStyle} onChange={v => updateSettings({ voiceStyle: v })} >
                            <ui.Select.Option>(Default)</ui.Select.Option>
                            {(voices.find(v => v.name === settings.voice)?.styleList || []).filter(v => v).map(v => <ui.Select.Option key={v} value={v}>{v}</ui.Select.Option>)}
                        </ui.Select>
                    </ui.Form.Item>
                    <a target="_blank" href="https://speech.microsoft.com/portal/voicegallery">Listen to all the voices here</a>
                </ui.Form>
            </ui.Collapse.Panel>
        </ui.Collapse>
        {editContact != null && <ui.Modal open={editContact != null} title="Edit Contact" onOk={saveContact} onCancel={() => setEditContact(null)} cancelButtonProps={{ hidden: true }}>
            <ui.Form layout="vertical">
                <ui.Form.Item label="Name">
                    <ui.Input value={editContact.name} onChange={ev => setEditContact({ ...editContact, name: ev.target.value })} />
                </ui.Form.Item>
                <ui.Form.Item label="Phone Number">
                    <ui.Input value={editContact.phoneNumber} onChange={ev => setEditContact({ ...editContact, phoneNumber: ev.target.value })} />
                </ui.Form.Item>
                <ui.Form.Item label="Transfer Type">
                    <ui.Select value={editContact.transferType} onChange={ev => setEditContact({ ...editContact, transferType: ev })}>
                        <ui.Select.Option key="Blind" value="Blind">Blind</ui.Select.Option>
                        <ui.Select.Option key="Supervised" value="Supervised">Warm</ui.Select.Option>
                        <ui.Select.Option key="MessagesOnly" value="MessagesOnly">Messages Only</ui.Select.Option>
                    </ui.Select>
                </ui.Form.Item>
                <ui.Form.Item label="Email Address">
                    <ui.Input value={editContact.emailAddress} onChange={ev => setEditContact({ ...editContact, emailAddress: ev.target.value })} />
                </ui.Form.Item>
                <ui.Form.Item label="About">
                    <ui.Input.TextArea value={editContact.about} onChange={ev => setEditContact({ ...editContact, about: ev.target.value })} />
                </ui.Form.Item>
                <ui.Form.Item label="Take Message Fields">
                    <ui.Table className="hide-pagination-buttons" pagination={false} dataSource={editContact.takeMessageFields} rowKey={(w, i) => i}>
                        <ui.Table.Column title="Name" render={(text, rec: AssistantTakeMessageField, i) => <ui.Input value={editContact.takeMessageFields[i].name} onChange={ev => setEditContact({ ...editContact, takeMessageFields: editContact.takeMessageFields.with(i, { ...editContact.takeMessageFields[i], name: ev.target.value }) })} />} />
                        <ui.Table.Column title="Description" render={(text, rec: AssistantTakeMessageField, i) => <ui.Input value={editContact.takeMessageFields[i].description} onChange={ev => setEditContact({ ...editContact, takeMessageFields: editContact.takeMessageFields.with(i, { ...editContact.takeMessageFields[i], description: ev.target.value }) })} />} />
                        <ui.Table.Column title="Required" render={(text, rec: AssistantTakeMessageField, i) => <ui.Checkbox value={editContact.takeMessageFields[i].required} onChange={ev => setEditContact({ ...editContact, takeMessageFields: editContact.takeMessageFields.with(i, { ...editContact.takeMessageFields[i], required: ev.target.checked }) })} />} />
                        <ui.Table.Column title="" render={(text, rec: AssistantTakeMessageField, i) => <ui.Popconfirm title="Remove" onConfirm={() => setEditContact({ ...editContact, takeMessageFields: editContact.takeMessageFields.filter(w => w !== editContact.takeMessageFields[i]) })}><ui.Button><i className="fa fa-trash" /></ui.Button></ui.Popconfirm>} />
                    </ui.Table>
                    <ui.Button onClick={() => setEditContact({ ...editContact, takeMessageFields: [...editContact.takeMessageFields, { name: "", description: "", required: false }] })} style={{ marginTop: 8 }}>Add Field</ui.Button>
                    <p><small>By default, if no fields are specified, we will collect the name, reason for call, and callback number</small></p>
                </ui.Form.Item>
            </ui.Form>
        </ui.Modal>}
    </>
}