import moment, { Moment } from "moment";
import { dtos, serviceClient, shared } from "./common";

export default class Cache {
    systemSettings: dtos.SystemSettingsInfo;
    systemSettingsTime: Moment = moment().add(-1, "hours");
    systemSettingsPromise: Promise<dtos.SystemSettingsInfo>;

    countries: dtos.AvailableCountryInfo[];
    countriesTime: Moment = moment().add(-1, "hours");
    countriesPromise: Promise<dtos.AvailableCountryInfo[]>;

    addresses: dtos.AddressInfo[];
    addressesTime: Moment = moment().add(-1, "hours");
    addressesPromise: Promise<dtos.ListAddressesResponse>;

    flows: dtos.FlowInfo[];
    flowsTime: Moment = moment().add(-1, "hours");
    flowsPromise: Promise<dtos.ListResponse<dtos.FlowInfo>>;

    async getSystemSettings() {
        if (moment().diff(this.systemSettingsTime, "seconds") > 60) {
            this.systemSettingsTime = moment();
            this.systemSettingsPromise = serviceClient.get(new dtos.GetSystemSettings({accountId: shared.accountId}));
        }

        return await this.systemSettingsPromise;
    }

    async getFlows() {
        if (moment().diff(this.flowsTime, "seconds") > 60) {
            this.flowsTime = moment();
            this.flowsPromise = serviceClient.get(new dtos.ListFlows({accountIds: [shared.accountId]}));
        }

        return await this.flowsPromise;
    }

    async getCountries() {
        if (moment().diff(this.countriesTime, "seconds") > 60) {
            this.countriesTime = moment();
            this.countriesPromise = serviceClient.get(new dtos.ListAvailableCountries({}));
        }

        return await this.countriesPromise;
    }

    async getAddresses() {
        if (moment().diff(this.addressesTime, "seconds") > 60) {
            this.addressesTime = moment();
            this.addressesPromise = serviceClient.get(new dtos.ListAddresses({accountId: shared.accountId}));
        }

        return (await this.addressesPromise).addresses;
    }

    updateSystemSettings(ss:dtos.SystemSettingsInfo) {
        this.systemSettings = ss;
    }

    updateAddresses() {
        this.addressesTime = moment().add(-1, "hours");
    }
}