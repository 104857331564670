import { React, ui, dtos, observable, observer, serviceClient, moment, selectFilter, shared, makeObservable, cache } from "./common";

interface Props {
    tags: Array<dtos.Tag>;
    placeholder?:string;
    ss?:dtos.SystemSettingsInfo;
}

@observer
export default class TagsEditor extends React.Component<Props> {
    @observable systemTags: Array<dtos.Tag> = [];
    
    constructor(props) {
        super(props);
        makeObservable(this);
    }

    async componentDidMount() {
        let ss = this.props.ss;
        if (!ss) {
            ss = await cache.getSystemSettings();
        }
        this.systemTags = ss.tags;
    }
    
    onChanged(ids: string[]) {
        let newTags = this.systemTags.filter(t => ids.indexOf(t.id) >= 0);
        this.props.tags.splice(0, this.props.tags.length);
        this.props.tags.splice(0, 0, ...newTags);
        this.forceUpdate();
    }

    render() {
        return (
            <ui.Select style={{ width: 175 }} showSearch filterOption={selectFilter} mode="tags" placeholder={this.props.placeholder || "Select tags"} value={this.props.tags.map(t => t.id)} onChange={(v:string[]) => this.onChanged(v)} dropdownMatchSelectWidth={false}>
                {this.systemTags.map(t => <ui.Select.Option key={t.id} value={t.id}>{t.name}</ui.Select.Option>)}
            </ui.Select>
        ); 
    }
}