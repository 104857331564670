import { React, serviceClient, dtos, observer, observable, ui, Link, _, splitIntoWords, uuid, selectFilter, shared, moment, moveArray, removeArray, makeObservable, withRouter, cache, getEndpointIcon } from "../common";
import FlowUtil from "../flows/util";
import ValueEditor from "../flows/value-editor"
import PageTitle from "../page-title";
import NewCustomer from "../customers/new"
import TagsEditor from "../tags-editor";
import Sessions from "../system/sessions";
import AutoComplete from "../autocomplete";
import FlowDesigner from "../flows/designer";
import { UploadChangeParam } from "antd/lib/upload";
import ScheduleEditor from "../customers/schedule";
import SimpleScheduleEditor from "./simple-schedule";
import FlowParamEditor from "../flows/param-editor";
import FlowParamsEditor from "./flow-params-editor";
import ThirdParty from "./third-party";
import AppSettingsEditor from "../customers/app-settings";
import { Space } from "antd";
import AssistantSettingsEditor from "./assistant-settings";

@observer
class EndpointDetails extends React.Component {
    @observable endpoint: dtos.EndpointInfo;
    @observable endpointId = "";
    @observable customerId = "";
    @observable editFlowId = "";
    @observable editReferenceId = "";
    @observable flows: Array<dtos.FlowInfo> = [];
    @observable editFlowParams: Array<dtos.FlowParameter> = [];
    @observable editFlowParamValues: dtos.Struct;
    @observable editEnableCallerIdLookup = false;
    @observable editUserPassword = "";
    @observable editUserMode = dtos.UserModes.SoftPhone;
    @observable editSipRegion = dtos.TwilioSipRegions.NorthAmericaVirginia;
    @observable editCallerIdName = "";
    @observable editUserFirstName = "";
    @observable editUserLastName = "";
    @observable editUserEmail = "";
    @observable editUserManagerRole: dtos.UserManagerRoles;
    @observable editDashboardPermissions: Array<dtos.DashboardPermissions> = [];
    @observable editUserMyFieldPermissions: dtos.UserDataFieldModes;
    @observable editUserCustomerFieldPermissions: dtos.UserDataFieldModes;
    @observable editUserOtherUserFieldPermissions: dtos.UserDataFieldModes;
    @observable editUserOtherEndpointFieldPermissions: dtos.UserDataFieldModes;
    @observable editContactListVisibility: dtos.CustomerVisibility;
    @observable editContacts: Array<dtos.EndpointContact> = [];
    @observable editName = "";
    @observable data = new dtos.Struct();
    @observable dataFields: Array<dtos.EndpointDataField> = [];
    @observable editTeamMemberIds: string[] = [];
    @observable tags: Array<dtos.Tag> = [];
    @observable actionUrls: Array<dtos.EndpointActionUrl> = [];
    @observable loading = true;
    @observable ss: dtos.SystemSettingsInfo = null;
    @observable forceUpdateUrls = false;
    @observable showNewCustomer = false;
    @observable oldParams: dtos.FlowParameter[] = [];
    @observable oldParamValues: dtos.Struct = null;
    @observable addresses: Array<dtos.AddressInfo> = [];
    @observable editAddressSid = "";
    @observable editEmergencyAddressSid = "";
    @observable editEmergencyCalling = false;
    @observable editEmergencyNumberId = "";
    @observable editDisableSms = false;
    @observable editUseExternal10Dlc = false;
    @observable editFlowSchedule: dtos.EndpointFlowSchedules;
    @observable editFlowScheduleState = "";
    @observable editScheduledFlows: Array<dtos.ScheduledFlow> = [];
    @observable editScheduledFlow: dtos.ScheduledFlow = null;
    @observable editScheduledFlowParams: Array<dtos.FlowParameter> = [];
    @observable schedule: dtos.Schedule;
    @observable allScheduleStates: Array<string> = [];
    @observable saving = false;
    @observable editSchedule: dtos.Schedule;
    @observable editThirdParty: dtos.ThirdPartyPhoneSystemSettings;
    @observable editOverrideAppSettings: boolean;
    @observable editAppSettings: dtos.AppSettings;
    @observable editAssistantSettings: dtos.AssistantSettings;

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    // test
    async componentDidMount() {
        this.refresh((this.props as any).params.id);
    }

    async refresh(id) {

        var endpoint = (await serviceClient.get(new dtos.ListEndpoints({ specificIds: [id] }))).items[0];
        this.endpoint = endpoint;
        this.endpointId = this.endpoint.id;
        this.flows = (await serviceClient.get(new dtos.ListFlows({ accountIds: [this.endpoint.accountId], all: true }))).items;
        this.addresses = await cache.getAddresses();
        this.customerId = this.endpoint.customerId || "";
        this.editReferenceId = this.endpoint.referenceId;
        this.changeFlowId(this.endpoint.flowId || "");
        this.editFlowParamValues = this.endpoint.flowParams;
        this.initParams(this.editFlowParamValues, this.editFlowParams);
        this.editEnableCallerIdLookup = this.endpoint.enableCallerIdLookup;
        this.editUserMode = this.endpoint.userMode;
        this.editUserFirstName = this.endpoint.userFirstName;
        this.editUserLastName = this.endpoint.userLastName;
        this.editUserEmail = this.endpoint.userEmailAddress;
        this.editUserManagerRole = this.endpoint.managerRole;
        this.editSipRegion = this.endpoint.sipRegion;
        this.editCallerIdName = this.endpoint.callerIdName;
        this.editDashboardPermissions = this.endpoint.dashboardPermissions;
        this.editUserMyFieldPermissions = this.endpoint.myFieldPermissions;
        this.editUserCustomerFieldPermissions = this.endpoint.customerFieldPermissions;
        this.editUserOtherUserFieldPermissions = this.endpoint.otherUserFieldPermissions;
        this.editUserOtherEndpointFieldPermissions = this.endpoint.otherEndpointFieldPermissions;
        this.editName = this.endpoint.name;
        this.actionUrls = this.endpoint.actionUrls;
        this.editTeamMemberIds = this.endpoint.teamMemberIds;
        this.editContactListVisibility = this.endpoint.contactListVisibility;
        this.editContacts = this.endpoint.contacts;
        this.editAddressSid = this.endpoint.addressSid;
        this.editEmergencyAddressSid = this.endpoint.emergencyAddressSid;
        this.editEmergencyCalling = this.endpoint.enableEmergencyCalling;
        this.editEmergencyNumberId = this.endpoint.emergencyPhoneNumberId;
        this.editDisableSms = this.endpoint.disableSms;
        this.editUseExternal10Dlc = this.endpoint.useExternal10DlcCampaign;
        this.oldParams = this.editFlowParams;
        this.oldParamValues = this.editFlowParamValues;
        this.editFlowSchedule = this.endpoint.flowSchedule;
        this.editScheduledFlows = this.endpoint.scheduledFlows;
        this.schedule = this.endpoint.schedule;
        this.editThirdParty = this.endpoint.thirdPartyPhoneSystemSettings;
        this.editOverrideAppSettings = this.endpoint.overrideAppSettings;
        this.editAppSettings = this.endpoint.appSettings;
        this.editAssistantSettings = this.endpoint.assistantSettings;

        this.setEditFlowScheduleState("");

        let ss = await cache.getSystemSettings();
        this.tags = this.endpoint.tags;

        this.data = this.endpoint.data;
        for (let field of ss.endpointFields) {
            if (!this.data[field.name]) {
                this.data[field.name] = new dtos.Value();
            }
        }
        for (let fieldName in this.data) {
            if (!_.find(ss.endpointFields, f => f.name == fieldName)) {
                delete this.data[fieldName];
            }
        }

        this.dataFields = ss.endpointFields.filter(f => !f.endpointType || f.endpointType == null || f.endpointType == this.endpoint.type || (this.endpoint.type == dtos.EndpointTypes.FaxNumber && f.endpointType == dtos.EndpointTypes.PhoneNumber));
        this.ss = ss;
        this.loading = false;
    }

    initParams(struct: dtos.Struct, fields: Array<dtos.FlowParameter>) {
        for (let field of fields) {
            if (!struct[field.name]) {
                struct[field.name] = new dtos.Value();
            }
        }
    }

    async patch() {
        try {
            this.saving = true;
            this.forceUpdate();
            this.endpoint = await serviceClient.patch(new dtos.PatchEndpoint({
                endpointId: this.endpointId,
                customerId: this.customerId,
                referenceId: this.editReferenceId,
                flowId: this.editFlowId,
                flowParams: this.editFlowParamValues,
                userMode: this.editUserMode,
                userPassword: this.editUserPassword || null,
                data: this.data,
                userFirstName: this.editUserFirstName,
                userLastName: this.editUserLastName,
                userEmailAddress: this.editUserEmail,
                sipRegion: this.editSipRegion,
                managerRole: this.editUserManagerRole,
                dashboardPermissions: this.editDashboardPermissions,
                myFieldPermissions: this.editUserMyFieldPermissions,
                customerFieldPermissions: this.editUserCustomerFieldPermissions,
                otherEndpointFieldPermissions: this.editUserOtherEndpointFieldPermissions,
                otherUserFieldPermissions: this.editUserOtherUserFieldPermissions,
                name: this.editName,
                tagIds: this.tags.map(t => t.id),
                enableCallerIdLookup: this.editEnableCallerIdLookup,
                actionUrls: this.actionUrls,
                forceUpdateUrls: this.forceUpdateUrls,
                teamMemberIds: this.editTeamMemberIds,
                contactListVisibility: this.editContactListVisibility,
                contacts: this.editContacts,
                addressSid: this.editAddressSid || "",
                enableEmergencyCalling: this.editEmergencyCalling,
                emergencyAddressSid: this.editEmergencyAddressSid,
                emergencyPhoneNumberId: this.editEmergencyNumberId,
                disableSms: this.editDisableSms,
                useExternal10DlcCampaign: this.editUseExternal10Dlc,
                flowSchedule: this.editFlowSchedule,
                scheduledFlows: this.editScheduledFlows,
                schedule: this.editSchedule,
                thirdPartyPhoneSystemSettings: this.editThirdParty,
                overrideAppSettings: this.editOverrideAppSettings,
                appSettings: this.editAppSettings,
                callerIdName: this.editCallerIdName,
                assistantSettings: this.editAssistantSettings
            }));

            this.oldParams = this.editFlowParams;
            this.oldParamValues = this.editFlowParamValues;

            ui.message.success("Endpoint updated successfully");
        } catch (err) {
            // will be notified elsewhere
        } finally {
            this.saving = false;
            this.forceUpdate();
        }
    }

    async changeFlowId(id) {
        this.editFlowId = id;
        this.editFlowParams = [];
        this.editFlowParamValues = null;
        if (id) {
            let flow = _.find(this.flows, f => f.id == id);
            let params = new dtos.Struct();
            this.editFlowParams = flow.parameters.filter(p => p.isPublic);
            this.initParams(params, this.editFlowParams);
            for (let param of this.editFlowParams) {
                var oldParam = _.find(this.oldParams, p => p.name == param.name && p.type == param.type);
                if (oldParam != null && this.oldParamValues[oldParam.name]) {
                    params[oldParam.name] = this.oldParamValues[oldParam.name];
                }
            }
            this.editFlowParamValues = params;
        }
    }

    setEditFlowScheduleState(state) {
        this.editFlowScheduleState = state;
        let fs = _.find(this.editScheduledFlows, f => f.stateName == state);
        if (fs == null) {
            fs = new dtos.ScheduledFlow({
                stateName: state,
                flowParams: new dtos.Struct(),
                flowId: ""
            })
            this.editScheduledFlows.push(fs);
        }
        this.editScheduledFlow = fs;
        this.changeScheduledFlowId(fs.flowId)
    }

    changeScheduledFlowId(flowId) {
        if (flowId != this.editScheduledFlow.flowId) {
            this.editScheduledFlow.flowId = flowId;
            this.editScheduledFlowParams = [];
            if (flowId) {
                let flow = _.find(this.flows, f => f.id == flowId);
                let params = new dtos.Struct();
                this.editScheduledFlowParams = flow.parameters.filter(p => p.isPublic);
                this.initParams(params, this.editScheduledFlowParams);
                this.editScheduledFlow.flowParams = params;
            }
        } else {
            let flow = _.find(this.flows, f => f.id == flowId);
            this.editScheduledFlowParams = flow == null ? [] : flow.parameters.filter(p => p.isPublic);
            this.initParams(this.editScheduledFlow.flowParams, this.editScheduledFlowParams);
        }
        this.forceUpdate();
    }

    cleanupScheduledFlows() {
        for (let sf of this.editScheduledFlows.slice()) {
            if (sf.flowId && !this.flows.find(f => f.id == sf.flowId)) {
                sf.flowId = "";
            }

            if (sf.stateName && this.allScheduleStates.indexOf(sf.stateName) < 0) {
                console.log("REMOVE", sf.stateName);
                let idx = this.editScheduledFlows.indexOf(sf);
                this.editScheduledFlows.splice(idx, 1);
            }
        }
    }

    isStateScheduled(state): boolean {
        return _.find(this.editScheduledFlows, f => f.stateName == state && f.flowId != null && f.flowId.length > 0) != null;
    }

    async deleteEndpoint() {
        await serviceClient.delete(new dtos.DeleteEndpoint({ endpointId: this.endpointId }));
        window.location.hash = "/endpoints";
    }

    async setIsFax(isFax: boolean) {
        var updated = await serviceClient.patch(new dtos.SetPhoneNumberIsFax({
            endpointId: this.endpointId,
            isFax: isFax
        }));
        window.location.reload();
    }

    newContact() {
        this.editContacts.push(new dtos.EndpointContact({
            id: uuid(),
            displayName: "",
            address: ""
        }));
        this.forceUpdate();
    }

    removeContact(c: dtos.EndpointContact) {
        let idx = this.editContacts.indexOf(c);
        if (idx >= 0) {
            this.editContacts.splice(idx, 1);
            this.forceUpdate();
        }
    }

    async regenerateSipCredentials() {
        this.endpoint = await serviceClient.patch(new dtos.RegenerateSipPassword({
            endpointId: this.endpointId
        }));
        ui.message.success("Credentials reset");
    }

    removeActionUrl(url: dtos.EndpointActionUrl) {
        let idx = this.actionUrls.indexOf(url);
        if (idx < 0) return;
        this.actionUrls.splice(idx, 1);
        this.forceUpdate();
    }

    newActionUrl() {
        this.actionUrls = [...this.actionUrls, new dtos.EndpointActionUrl({
            id: uuid(),
            url: "",
            method: dtos.ActionUrlHttpMethods.GET
        })];
    }

    async forceUpdateActionUrls() {
        this.forceUpdateUrls = true;
        await this.patch();
        this.forceUpdateUrls = false;
    }

    newTeamMember() {
        this.editTeamMemberIds.push("");
        this.forceUpdate();
    }

    removeTeamMember(id) {
        let idx = this.editTeamMemberIds.indexOf(id);
        while (idx >= 0) {
            this.editTeamMemberIds.splice(idx, 1);
            idx = this.editTeamMemberIds.indexOf(id);
        }
        this.forceUpdate();
        debugger;
    }

    setTeamMember(idx: number, id) {
        this.editTeamMemberIds[idx] = id;
        this.forceUpdate();
    }

    onUploadAvatarChange(info: UploadChangeParam) {
        if (info.file.status == "done") {
            let updatedEndpoint = info.file.response;
            this.endpoint.avatarUrl = updatedEndpoint.avatarUrl;
            this.forceUpdate();
        }
    }

    async deleteAvatar() {
        await serviceClient.delete(new dtos.DeleteUserAvatar({
            endpointId: this.endpointId
        }));
        ui.message.success("Avatar removed");
        this.endpoint.avatarUrl = null;
        this.forceUpdate();
    }

    async enrollIn10DLC() {
        await serviceClient.patch(new dtos.PatchEndpoint({
            endpointId: this.endpoint.id,
            enrollIn10DlcService: true
        }));
        ui.message.success("Number successfully enrolled");
        this.endpoint.isEnrolledIn10DlcService = true;
    }

    async leave10DLC() {
        await serviceClient.patch(new dtos.PatchEndpoint({
            endpointId: this.endpoint.id,
            leave10DlcService: true
        }));
        ui.message.success("Number successfully removed");
        this.endpoint.isEnrolledIn10DlcService = false;
    }

    toggleDashboardPermission(p, b: boolean) {
        let idx = this.editDashboardPermissions.indexOf(p);
        if (idx < 0 && b) {
            this.editDashboardPermissions.push(p);
        } else if (idx >= 0 && !b) {
            this.editDashboardPermissions.splice(idx, 1);
        }
    }

    verifyCallerId() {
        serviceClient.post(new dtos.VerifyCallerId({
            endpointId: this.endpoint.id
        })).then((ep) => {
            this.endpoint.isCallerIdVerified = ep.isCallerIdVerified;
            this.endpoint.callerIdVerificationCode = ep.callerIdVerificationCode;
            ui.message.info("Caller ID verification taking place now. You will receive an email once the process completes with the result");
        })
    }

    getFlows(): dtos.FlowInfo[] {
        return this.flows;
    }
    render() {
        if (this.endpoint == null) return <div />;

        let dataFields = this.dataFields.map(f =>
            <ui.Form.Item label={splitIntoWords(f.name)} key={f.id}>
                <ValueEditor accountId={this.endpoint.accountId} customerId={this.endpoint.customerId} field={f} valueType={f.type} value={this.data[f.name]} />
                {f.description && <p style={{ margin: 0, padding: 0, lineHeight: 1 }}><small>{f.description}</small></p>}
            </ui.Form.Item>);



        const self = this;
        function SaveButton(props) {
            return <ui.Button disabled={self.saving} type="primary" onClick={() => self.patch()}>{self.saving && <ui.Spin style={{ marginRight: 4 }} size="small" />} {self.saving ? "Saving..." : "Save"}</ui.Button>;
        }

        return (
            <div>
                <PageTitle title={this.endpoint.displayName} />
                <ui.Breadcrumb>
                    <ui.Breadcrumb.Item><Link to="/endpoints">Endpoints</Link></ui.Breadcrumb.Item>
                    <ui.Breadcrumb.Item>{this.endpoint.displayName}</ui.Breadcrumb.Item>
                </ui.Breadcrumb>
                {!shared.collapsed && <ui.Typography.Title><i className={getEndpointIcon(this.endpoint.type)} /> {this.endpoint.displayName}</ui.Typography.Title>}
                {this.loading && <div style={{ textAlign: "center", paddingTop: 100 }}><ui.Spin size="default" /></div>}
                {!this.loading &&
                    <ui.Tabs animated={false}>
                        {this.endpoint.type == dtos.EndpointTypes.User &&
                            <ui.Tabs.TabPane key="general" tab="General">
                                <ui.Form layout="vertical">
                                    <ui.Row gutter={16}>
                                        <ui.Col span={1}>
                                            <div style={{ marginTop: 24, position: "relative" }}>
                                                <ui.Upload style={{ cursor: "pointer" }} accept="image/*" showUploadList={false} name="file" data={{ endpointId: this.endpointId }} withCredentials={true} headers={{ "X-Requested-With": null }} action={serviceClient.createUrlFromDto("POST", new dtos.NewUserAvatar())} onChange={info => this.onUploadAvatarChange(info)}>
                                                    <ui.Tooltip title="Avatar should be a square image. It will automatically be resized to be 128x128">
                                                        <ui.Avatar icon={this.endpoint.avatarUrl ? null : "user"} src={this.endpoint.avatarUrl || null} size="large" />
                                                    </ui.Tooltip>
                                                </ui.Upload>
                                                {this.endpoint.avatarUrl && <ui.Popconfirm title="Remove avatar?" onConfirm={() => this.deleteAvatar()}><a style={{ display: "block", position: "absolute", bottom: "-14px", right: "12px" }}><i className="fa fa-trash" /></a></ui.Popconfirm>}
                                            </div>
                                        </ui.Col>
                                        <ui.Col span={4}>
                                            <ui.Form.Item label="Customer">
                                                <ui.Input.Group compact>
                                                    <AutoComplete type={dtos.ValueTypes.Customer} value={this.customerId} onChanged={v => this.customerId = v} placeholder="Filter by customer" />
                                                    <ui.Button type="primary" onClick={() => this.showNewCustomer = true}><i className="fa fa-plus" /></ui.Button>
                                                </ui.Input.Group>
                                            </ui.Form.Item>
                                        </ui.Col>
                                        <ui.Col span={3}>
                                            <ui.Form.Item label="Mode">
                                                <ui.Select value={this.editUserMode} onChange={v => this.editUserMode = v}>
                                                    <ui.Select.Option value={dtos.UserModes.SoftPhone}>Soft Phone</ui.Select.Option>
                                                    <ui.Select.Option value={dtos.UserModes.Sip}>SIP Phone</ui.Select.Option>
                                                    <ui.Select.Option value={dtos.UserModes.Flow}>Flow (Advanced)</ui.Select.Option>
                                                    <ui.Select.Option value={dtos.UserModes.DataOnly}>Data Only</ui.Select.Option>
                                                    <ui.Select.Option value={dtos.UserModes.ThirdParty}>Third Party Phone System</ui.Select.Option>
                                                </ui.Select>
                                            </ui.Form.Item>
                                        </ui.Col>
                                        <ui.Col span={6}>
                                            <ui.Form.Item label="First Name">
                                                <ui.Input value={this.editUserFirstName} onChange={ev => this.editUserFirstName = ev.target.value} />
                                            </ui.Form.Item>
                                        </ui.Col>
                                        <ui.Col span={6}>
                                            <ui.Form.Item label="Last Name">
                                                <ui.Input value={this.editUserLastName} onChange={ev => this.editUserLastName = ev.target.value} />
                                            </ui.Form.Item>
                                        </ui.Col>
                                        <ui.Col span={4}>
                                            <ui.Form.Item label="Tags">
                                                <TagsEditor ss={this.ss} tags={this.tags} />
                                            </ui.Form.Item>
                                        </ui.Col>
                                    </ui.Row>

                                    {(this.editUserMode == dtos.UserModes.SoftPhone || this.editUserMode == dtos.UserModes.ThirdParty) && <ui.Row gutter={16}>
                                        <ui.Col span={12}>
                                            <ui.Form.Item label="Email Address (login)">
                                                <ui.Input value={this.editUserEmail} onChange={ev => this.editUserEmail = ev.target.value} />
                                            </ui.Form.Item>
                                        </ui.Col>
                                        <ui.Col span={12}>
                                            <ui.Form.Item label="Password">
                                                <ui.Input.Password autoComplete="new-password" placeholder="Leave blank to keep current password" value={this.editUserPassword} onChange={ev => this.editUserPassword = ev.target.value} />
                                            </ui.Form.Item>
                                        </ui.Col>
                                    </ui.Row>}

                                    {dataFields}

                                    {this.editUserMode != dtos.UserModes.DataOnly && <ui.Form.Item label="Contact List Visibility">
                                        <ui.Select value={this.editContactListVisibility} onChange={v => this.editContactListVisibility = v}>
                                            <ui.Select.Option value="None">None</ui.Select.Option>
                                            <ui.Select.Option value="CurrentCustomer">Current Customer</ui.Select.Option>
                                            <ui.Select.Option value="CurrentAndChildCustomers">Current Customer and Children</ui.Select.Option>
                                        </ui.Select>
                                    </ui.Form.Item>}
                                    <ui.Form.Item>
                                        <SaveButton />
                                        <ui.Popconfirm title="Are you sure you want to delete this endpoint?" onConfirm={() => this.deleteEndpoint()}><a style={{ marginLeft: 12 }}>Delete Endpoint</a></ui.Popconfirm>
                                    </ui.Form.Item>
                                </ui.Form>
                            </ui.Tabs.TabPane>}

                        {this.endpoint.type == dtos.EndpointTypes.Team &&
                            <ui.Tabs.TabPane key="general" tab="General">
                                <ui.Form layout="vertical">
                                    <ui.Form.Item label="Customer">
                                        <ui.Input.Group compact>
                                            <AutoComplete type={dtos.ValueTypes.Customer} value={this.customerId} onChanged={v => this.customerId = v} placeholder="Filter by customer" />
                                            <ui.Button type="primary" onClick={() => this.showNewCustomer = true}><i className="fa fa-plus" /></ui.Button>
                                        </ui.Input.Group>
                                    </ui.Form.Item>
                                    <ui.Form.Item label="Name">
                                        <ui.Input value={this.editName} onChange={ev => this.editName = ev.target.value} />
                                    </ui.Form.Item>
                                    <ui.Form.Item label="Tags">
                                        <TagsEditor ss={this.ss} tags={this.tags} />
                                    </ui.Form.Item>
                                    {dataFields}
                                    <ui.Form.Item label="Contact List Visibility">
                                        <ui.Select value={this.editContactListVisibility} onChange={v => this.editContactListVisibility = v}>
                                            <ui.Select.Option value="None">None</ui.Select.Option>
                                            <ui.Select.Option value="CurrentCustomer">Current Customer</ui.Select.Option>
                                            <ui.Select.Option value="CurrentAndChildCustomers">Current Customer and Children</ui.Select.Option>
                                        </ui.Select>
                                    </ui.Form.Item>
                                    <ui.Form.Item>
                                        <SaveButton />
                                        <ui.Popconfirm title="Are you sure you want to delete this endpoint?" onConfirm={() => this.deleteEndpoint()}><a style={{ marginLeft: 12 }}>Delete Endpoint</a></ui.Popconfirm>
                                    </ui.Form.Item>
                                </ui.Form>
                            </ui.Tabs.TabPane>}

                        {this.endpoint.type == dtos.EndpointTypes.Assistant &&
                            <ui.Tabs.TabPane key="assistant" tab="Assistant">
                                <AssistantSettingsEditor settings={this.editAssistantSettings} onChanged={s => this.editAssistantSettings = s} />
                                <ui.Form.Item style={{marginTop: 8}}>
                                    <SaveButton />
                                    <ui.Popconfirm title="Are you sure you want to delete this endpoint?" onConfirm={() => this.deleteEndpoint()}><a style={{ marginLeft: 12 }}>Delete Endpoint</a></ui.Popconfirm>
                                </ui.Form.Item>
                            </ui.Tabs.TabPane>}


                        {this.endpoint.type == dtos.EndpointTypes.Assistant &&
                            <ui.Tabs.TabPane key="general" tab="General">
                                <ui.Form layout="vertical">
                                    <ui.Form.Item label="Customer">
                                        <ui.Input.Group compact>
                                            <AutoComplete type={dtos.ValueTypes.Customer} value={this.customerId} onChanged={v => this.customerId = v} placeholder="Filter by customer" />
                                            <ui.Button type="primary" onClick={() => this.showNewCustomer = true}><i className="fa fa-plus" /></ui.Button>
                                        </ui.Input.Group>
                                    </ui.Form.Item>
                                    <ui.Form.Item label="Name">
                                        <ui.Input value={this.editName} onChange={ev => this.editName = ev.target.value} />
                                    </ui.Form.Item>
                                    <ui.Form.Item label="Tags">
                                        <TagsEditor ss={this.ss} tags={this.tags} />
                                    </ui.Form.Item>
                                    {dataFields}
                                    <ui.Form.Item>
                                        <SaveButton />
                                        <ui.Popconfirm title="Are you sure you want to delete this endpoint?" onConfirm={() => this.deleteEndpoint()}><a style={{ marginLeft: 12 }}>Delete Endpoint</a></ui.Popconfirm>
                                    </ui.Form.Item>
                                </ui.Form>
                            </ui.Tabs.TabPane>}

                        {(this.endpoint.type == dtos.EndpointTypes.PhoneNumber || this.endpoint.type == dtos.EndpointTypes.FaxNumber) &&
                            <ui.Tabs.TabPane key="general" tab="General">
                                <ui.Form layout="vertical">
                                    <ui.Form.Item label="Customer">
                                        <ui.Input.Group compact>
                                            <AutoComplete type={dtos.ValueTypes.Customer} value={this.customerId} onChanged={v => this.customerId = v} placeholder="Filter by customer" />
                                            <ui.Button type="primary" onClick={() => this.showNewCustomer = true}><i className="fa fa-plus" /></ui.Button>
                                        </ui.Input.Group>
                                    </ui.Form.Item>
                                    <ui.Form.Item label="Number">
                                        <ui.Input disabled value={this.endpoint.phoneNumber} />
                                        {/* {!this.endpoint.documoId && this.endpoint.type == dtos.EndpointTypes.PhoneNumber && !this.endpoint.isVirtualPhoneNumber && < ui.Popconfirm title="Change this number to a fax number? Remember to update the Flow" onConfirm={() => this.setIsFax(true)}><a>Convert to Fax Line</a></ui.Popconfirm>}
                                        {!this.endpoint.documoId && this.endpoint.type == dtos.EndpointTypes.FaxNumber && !this.endpoint.isVirtualPhoneNumber && < ui.Popconfirm title="Change this number to a voice number? Remember to update the Flow" onConfirm={() => this.setIsFax(false)}><a>Convert to Voice Line</a></ui.Popconfirm>} */}
                                    </ui.Form.Item>
                                    {this.endpoint.type === dtos.EndpointTypes.PhoneNumber && <ui.Form.Item label="Caller ID Name (CNAM)">
                                        <ui.Input placeholder="Max 15 characters. Can take up to 14 days to take effect." maxLength={15} value={this.editCallerIdName} onChange={ev => this.editCallerIdName = ev.target.value} />
                                    </ui.Form.Item>}
                                    {!this.endpoint.documoId && !this.endpoint.isVirtualPhoneNumber && < ui.Row >
                                        <ui.Col span={4}>
                                            <ui.Form.Item>
                                                <ui.Checkbox checked={this.editEnableCallerIdLookup} onChange={v => this.editEnableCallerIdLookup = v.target.checked}>Enable Caller ID Lookup</ui.Checkbox>
                                            </ui.Form.Item>
                                        </ui.Col>
                                        <ui.Col span={4}>
                                            {this.endpoint.type == dtos.EndpointTypes.PhoneNumber && <ui.Form.Item>
                                                <ui.Checkbox checked={this.editDisableSms} onChange={v => this.editDisableSms = v.target.checked}>Disable SMS</ui.Checkbox>
                                            </ui.Form.Item>}
                                        </ui.Col>
                                        <ui.Col span={4}>
                                            {this.endpoint.type == dtos.EndpointTypes.PhoneNumber && <ui.Form.Item>
                                                <ui.Checkbox checked={this.editUseExternal10Dlc} onChange={v => this.editUseExternal10Dlc = v.target.checked}>Use External 10DLC Campaign</ui.Checkbox>
                                            </ui.Form.Item>}
                                        </ui.Col>
                                    </ui.Row>}
                                    {this.endpoint.type == dtos.EndpointTypes.PhoneNumber && this.endpoint.isVirtualPhoneNumber && <ui.Form.Item label="Caller ID Verification">
                                        {this.endpoint.isCallerIdVerified && <ui.Col>
                                            <ui.Alert type="info" showIcon icon={<i className="fa fa-circle-info" />} message="Caller ID verified" />
                                        </ui.Col>}
                                        {!this.endpoint.isCallerIdVerified && this.endpoint.callerIdVerificationCode && <ui.Col>
                                            <p>Verification Code: <strong>{this.endpoint.callerIdVerificationCode}</strong></p>
                                            <p>Twilio will call this phone number in 10 seconds and prompt for this code.</p>
                                            <p>If using HostedSuite put the code into the dial box and Send DTMF after answering.</p>
                                            <p>If answering using a handset, answer the call and enter the code on the dial pad.</p>
                                            <p><strong>If you miss the call, click Try Again</strong></p>
                                            <Space>
                                                <ui.Button type="primary" onClick={() => this.verifyCallerId()}>Code Entered Successfully</ui.Button>
                                                <ui.Button danger onClick={() => this.verifyCallerId()}>Try Again</ui.Button>
                                            </Space>
                                        </ui.Col>}
                                        {!this.endpoint.isCallerIdVerified && !this.endpoint.callerIdVerificationCode && <ui.Col>
                                            <p>Before verifying caller ID, make sure that someone is available to answer this number when Twilio's automated system calls.</p>
                                            <p>If this number is forwarded to Evo Voice, the operator will need to enter the Code into HostedSuite in the dial box and click the DTMF button otherwise they will need to enter the Code on the handset after answering the call.</p>
                                            <ui.Button onClick={() => this.verifyCallerId()}>Verify Caller ID</ui.Button>
                                        </ui.Col>}
                                    </ui.Form.Item>}
                                    {dataFields}
                                    {this.endpoint.type == dtos.EndpointTypes.PhoneNumber && <ui.Form.Item label="Address">
                                        <ui.Select placeholder="Select an address" value={this.editAddressSid} onChange={v => this.editAddressSid = v} showSearch filterOption={selectFilter}>
                                            <ui.Select.Option value="">(Select an address)</ui.Select.Option>
                                            {this.addresses.map(a => <ui.Select.Option value={a.id} key={a.id}>{a.friendlyName}</ui.Select.Option>)}
                                        </ui.Select>
                                    </ui.Form.Item>}
                                    {this.endpoint.type == dtos.EndpointTypes.PhoneNumber && !this.endpoint.disableSms && <ui.Form.Item label="10DLC Messaging">
                                        {!this.endpoint.isEnrolledIn10DlcService && <div>
                                            <p style={{ margin: 0, padding: 0 }}>This number is not enrolled in the 10DLC messaging service. If you are not sending/receiving SMS messages on this line, you can ignore this message.</p>
                                            <ui.Popconfirm title="You are about to enroll this number in 10DLC messaging. This should only be done if this number is used for SMS messaging" onConfirm={() => this.enrollIn10DLC()}><ui.Button size="small">Enroll in 10DLC</ui.Button></ui.Popconfirm>
                                        </div>}
                                        {this.endpoint.isEnrolledIn10DlcService && <div>
                                            <p>This number is currently enrolled in the 10DLC messaging service</p>
                                            <ui.Popconfirm title="You are about to remove this number from 10DLC messaging. This should only be done if this number is not being used for SMS" onConfirm={() => this.leave10DLC()}><ui.Button size="small">Remove from 10DLC</ui.Button></ui.Popconfirm>
                                        </div>}
                                    </ui.Form.Item>}
                                    <ui.Form.Item label="Notes">
                                        <ui.Input value={this.editName} onChange={ev => this.editName = ev.target.value} />
                                    </ui.Form.Item>
                                    <ui.Form.Item label="Tags">
                                        <TagsEditor ss={this.ss} tags={this.tags} />
                                    </ui.Form.Item>
                                    <ui.Form.Item>
                                        <SaveButton />
                                        <ui.Popconfirm title="Are you sure you want to delete this endpoint?" onConfirm={() => this.deleteEndpoint()}><a style={{ marginLeft: 12 }}>Delete Endpoint</a></ui.Popconfirm>
                                    </ui.Form.Item>
                                </ui.Form>
                            </ui.Tabs.TabPane>}

                        {this.endpoint.type == dtos.EndpointTypes.Team &&
                            <ui.Tabs.TabPane key="team-members" tab="Members">
                                <ui.Form layout="vertical">
                                    <ui.Table dataSource={this.editTeamMemberIds.map(id => ({ id: id }))} rowKey="id" pagination={false}>
                                        <ui.Table.Column dataIndex="id" title="User" render={(text, mem: any, idx) =>
                                            <div>
                                                <AutoComplete type={dtos.ValueTypes.User} value={mem.id} onChanged={(v) => this.setTeamMember(idx, v)} customerId={""} placeholder="Search for a user" />
                                            </div>} />
                                        <ui.Table.Column title="" render={(text, rec: any) =>
                                            <ui.Button.Group size="small">
                                                <ui.Popconfirm title="Are you sure you want to remove this member?" onConfirm={() => this.removeTeamMember(rec.id)}><ui.Button><i className="fa fa-trash" /></ui.Button></ui.Popconfirm>
                                            </ui.Button.Group>} />
                                    </ui.Table>
                                    <ui.Button.Group style={{ marginTop: 16, marginBottom: 24 }}>
                                        <ui.Button onClick={() => this.newTeamMember()}>New Member</ui.Button>
                                    </ui.Button.Group>
                                    <ui.Form.Item>
                                        <SaveButton />
                                        <ui.Popconfirm title="Are you sure you want to delete this endpoint?" onConfirm={() => this.deleteEndpoint()}><a style={{ marginLeft: 12 }}>Delete Endpoint</a></ui.Popconfirm>
                                    </ui.Form.Item>
                                </ui.Form>
                            </ui.Tabs.TabPane>}

                        {this.endpoint.type == dtos.EndpointTypes.EmailAddress &&
                            <ui.Tabs.TabPane key="general" tab="General">
                                <ui.Form layout="vertical">
                                    <ui.Form.Item label="Customer">
                                        <ui.Input.Group compact>
                                            <AutoComplete type={dtos.ValueTypes.Customer} value={this.customerId} onChanged={v => this.customerId = v} placeholder="Filter by customer" />
                                            <ui.Button type="primary" onClick={() => this.showNewCustomer = true}><i className="fa fa-plus" /></ui.Button>
                                        </ui.Input.Group>
                                    </ui.Form.Item>
                                    <ui.Form.Item label="Email Address">
                                        <ui.Input disabled value={this.endpoint.emailAddress} />
                                    </ui.Form.Item>
                                    <ui.Form.Item label="Tags">
                                        <TagsEditor ss={this.ss} tags={this.tags} />
                                    </ui.Form.Item>
                                    {dataFields}
                                    <ui.Form.Item>
                                        <SaveButton />
                                        <ui.Popconfirm title="Are you sure you want to delete this endpoint?" onConfirm={() => this.deleteEndpoint()}><a style={{ marginLeft: 12 }}>Delete Endpoint</a></ui.Popconfirm>
                                    </ui.Form.Item>
                                </ui.Form>
                            </ui.Tabs.TabPane>}
                        {this.endpoint.type == dtos.EndpointTypes.User && this.editUserMode == dtos.UserModes.ThirdParty && <ui.Tabs.TabPane key="thirdparty" tab="Third-Party">
                            <ThirdParty settings={this.editThirdParty} onChanged={v => this.editThirdParty = v} />
                            <ui.Form style={{ marginTop: 8 }}>
                                <ui.Form.Item>
                                    <SaveButton />
                                </ui.Form.Item>
                            </ui.Form>
                        </ui.Tabs.TabPane>}
                        {this.endpoint.type != dtos.EndpointTypes.Team && this.endpoint.type !== dtos.EndpointTypes.Assistant && this.editUserMode != dtos.UserModes.DataOnly &&
                            <ui.Tabs.TabPane key="flow" tab="Flow">
                                <ui.Form layout="vertical">
                                    {this.editFlowSchedule == dtos.EndpointFlowSchedules.Always && <ui.Row gutter={8}>
                                        <ui.Col span={6}>
                                            <ui.Form.Item label="When">
                                                <ui.Select showSearch filterOption={selectFilter} value={this.editFlowSchedule} onChange={v => this.editFlowSchedule = v}>
                                                    <ui.Select.Option value="Always">Always</ui.Select.Option>
                                                    <ui.Select.Option value="Scheduled">Scheduled</ui.Select.Option>
                                                    <ui.Select.Option value="Simple">Simple</ui.Select.Option>
                                                </ui.Select>
                                            </ui.Form.Item>
                                        </ui.Col>
                                        <ui.Col span={6}>
                                            <ui.Form.Item label="Flow">
                                                <ui.Select showSearch filterOption={selectFilter} value={this.editFlowId} onChange={v => this.changeFlowId(v)}>
                                                    <ui.Select.Option value="">(None)</ui.Select.Option>
                                                    {this.getFlows().map(f => <ui.Select.Option value={f.id} key={f.id}>{f.name} <small style={{ marginLeft: 2 }}>{f.notes}</small></ui.Select.Option>)}
                                                </ui.Select>
                                            </ui.Form.Item>
                                        </ui.Col>
                                    </ui.Row>}
                                    {this.editFlowSchedule == dtos.EndpointFlowSchedules.Simple && <>
                                        <ui.Row>
                                            <ui.Col span={6}>
                                                <ui.Form.Item label="When">
                                                    <ui.Select showSearch filterOption={selectFilter} value={this.editFlowSchedule} onChange={v => this.editFlowSchedule = v}>
                                                        <ui.Select.Option value="Always">Always</ui.Select.Option>
                                                        <ui.Select.Option value="Scheduled">Scheduled</ui.Select.Option>
                                                        <ui.Select.Option value="Simple">Simple</ui.Select.Option>
                                                    </ui.Select>
                                                </ui.Form.Item>
                                            </ui.Col>
                                        </ui.Row>
                                        <ui.Row>
                                            <ui.Col span={18}>
                                                <SimpleScheduleEditor endpointId={this.endpointId} onChanged={s => this.editSchedule = s} />
                                            </ui.Col>
                                        </ui.Row>
                                    </>}
                                    {this.editFlowSchedule == dtos.EndpointFlowSchedules.Scheduled && <ui.Row gutter={8}>
                                        <ui.Col span={6}>
                                            <ui.Form.Item label="When">
                                                <ui.Select showSearch filterOption={selectFilter} value={this.editFlowSchedule} onChange={v => this.editFlowSchedule = v}>
                                                    <ui.Select.Option value="Always">Always</ui.Select.Option>
                                                    <ui.Select.Option value="Scheduled">Scheduled</ui.Select.Option>
                                                    <ui.Select.Option value="Simple">Simple</ui.Select.Option>
                                                </ui.Select>
                                            </ui.Form.Item>
                                        </ui.Col>
                                        <ui.Col span={6}>
                                            <ui.Form.Item label="Schedule">
                                                <ui.Select showSearch filterOption={selectFilter} value={this.editFlowScheduleState} onChange={v => this.setEditFlowScheduleState(v)}>
                                                    <ui.Select.Option value=""><span style={{ fontWeight: this.isStateScheduled("") ? "bold" : "normal" }}>(Default)</span></ui.Select.Option>
                                                    {this.allScheduleStates.map(s => <ui.Select.Option key={s} value={s}><span style={{ fontWeight: this.isStateScheduled(s) ? "bold" : "normal" }}>{s}</span></ui.Select.Option>)}
                                                </ui.Select>
                                            </ui.Form.Item>
                                        </ui.Col>
                                        <ui.Col span={6}>
                                            <ui.Form.Item label="Flow">
                                                <ui.Select showSearch filterOption={selectFilter} value={this.editScheduledFlow.flowId} onChange={v => this.changeScheduledFlowId(v)}>
                                                    <ui.Select.Option value="">(None)</ui.Select.Option>
                                                    {this.getFlows().map(f => <ui.Select.Option value={f.id} key={f.id}>{f.name} <small style={{ marginLeft: 2 }}>{f.notes}</small></ui.Select.Option>)}
                                                </ui.Select>
                                            </ui.Form.Item>
                                        </ui.Col>
                                    </ui.Row>}

                                    {this.editFlowSchedule == dtos.EndpointFlowSchedules.Scheduled && <div>
                                        {this.editScheduledFlow && <FlowParamsEditor accountId={this.endpoint.accountId} customerId={this.endpoint.customerId} params={this.editScheduledFlowParams} values={this.editScheduledFlow.flowParams} />}
                                    </div>}

                                    {this.editFlowSchedule == dtos.EndpointFlowSchedules.Always && <div>

                                        {this.editFlowParamValues && <FlowParamsEditor accountId={this.endpoint.accountId} customerId={this.endpoint.customerId} params={this.editFlowParams} values={this.editFlowParamValues} />}

                                    </div>}
                                    <ui.Form.Item>
                                        <SaveButton />
                                        <ui.Popconfirm title="Are you sure you want to delete this endpoint?" onConfirm={() => this.deleteEndpoint()}><a style={{ marginLeft: 12 }}>Delete Endpoint</a></ui.Popconfirm>
                                    </ui.Form.Item>
                                </ui.Form>
                            </ui.Tabs.TabPane>}


                        {this.editFlowSchedule == dtos.EndpointFlowSchedules.Scheduled && this.endpoint.type == dtos.EndpointTypes.PhoneNumber &&
                            <ui.Tabs.TabPane key="schedule" tab="Schedule" forceRender>
                                <ScheduleEditor schedule={this.schedule} allStatesCallback={states => { this.allScheduleStates = states; this.cleanupScheduledFlows(); }} customerId="" endpointId={this.endpointId} parentCustomerId={this.endpoint.customerId} />
                            </ui.Tabs.TabPane>}

                        {this.endpoint.type == dtos.EndpointTypes.PhoneNumber && !this.endpoint.isVirtualPhoneNumber &&
                            <ui.Tabs.TabPane key="emergency" tab="Emergency">
                                <ui.Form layout="vertical">
                                    <ui.Form.Item style={{ marginBottom: 0 }}>
                                        <ui.Checkbox checked={this.editEmergencyCalling} onChange={ev => this.editEmergencyCalling = ev.target.checked}>Enable Emergency Calling</ui.Checkbox>
                                    </ui.Form.Item>
                                    {this.editEmergencyCalling && <ui.Form.Item label="Emergency Address">
                                        <ui.Select placeholder="Select an emergency address" value={this.editEmergencyAddressSid} onChange={v => this.editEmergencyAddressSid = v} showSearch filterOption={selectFilter}>
                                            <ui.Select.Option value="">(Select an emergency address)</ui.Select.Option>
                                            {this.addresses.map(a => <ui.Select.Option value={a.id} key={a.id}>{a.friendlyName}</ui.Select.Option>)}
                                        </ui.Select>
                                    </ui.Form.Item>}
                                    <ui.Divider style={{ marginBottom: 4 }} />
                                    <p style={{ padding: 0, margin: 0, marginBottom: 16 }}><small>It can take up to 10 minutes before 933/911 test calls function correctly.</small></p>
                                    <ui.Form.Item>
                                        <SaveButton />
                                        <ui.Popconfirm title="Are you sure you want to delete this endpoint?" onConfirm={() => this.deleteEndpoint()}><a style={{ marginLeft: 12 }}>Delete Endpoint</a></ui.Popconfirm>
                                    </ui.Form.Item>
                                </ui.Form>
                            </ui.Tabs.TabPane>}

                        {this.endpoint.type == dtos.EndpointTypes.User && (this.editUserMode == dtos.UserModes.SoftPhone) &&
                            <ui.Tabs.TabPane key="permissions" tab="Permissions">
                                <ui.Form layout="vertical">
                                    <ui.Form.Item label="Manager Role (Dashboard Access)">
                                        <ui.Select value={this.editUserManagerRole} onChange={v => this.editUserManagerRole = v}>
                                            {Object.keys(dtos.UserManagerRoles).map(v => <ui.Select.Option key={v} value={v}>{splitIntoWords(v)}</ui.Select.Option>)}
                                        </ui.Select>
                                        <p style={{ margin: 0, padding: 0, marginBottom: -16, color: "red" }}><small>NOTE: This gives the user dashboard access to manage his customer and child customers.</small></p>
                                    </ui.Form.Item>
                                    {this.editUserManagerRole === dtos.UserManagerRoles.Custom && <ui.Form.Item label="Dashboard Permissions">
                                        {Object.keys(dtos.DashboardPermissions).map(v => <ui.Checkbox key={v} onChange={(ev) => this.toggleDashboardPermission(v, ev.target.checked)} checked={this.editDashboardPermissions.indexOf(v as any) >= 0}>{splitIntoWords(v)}</ui.Checkbox>)}
                                    </ui.Form.Item>}
                                    <ui.Form.Item label="My Fields">
                                        <ui.Select value={this.editUserMyFieldPermissions} onChange={v => this.editUserMyFieldPermissions = v}>
                                            {Object.keys(dtos.UserDataFieldModes).map(v => <ui.Select.Option key={v} value={v}>{splitIntoWords(v)}</ui.Select.Option>)}
                                        </ui.Select>
                                        <p style={{ margin: 0, padding: 0, marginBottom: -16 }}><small>This permission determines if the user will be able to view/edit fields for this user record. <strong>This only applies to custom Endpoint fields with the appropriate User Mode.</strong></small></p>
                                    </ui.Form.Item>
                                    <ui.Form.Item label="Customer Fields">
                                        <ui.Select value={this.editUserCustomerFieldPermissions} onChange={v => this.editUserCustomerFieldPermissions = v}>
                                            {Object.keys(dtos.UserDataFieldModes).map(v => <ui.Select.Option key={v} value={v}>{splitIntoWords(v)}</ui.Select.Option>)}
                                        </ui.Select>
                                        <p style={{ margin: 0, padding: 0, marginBottom: -16 }}><small>This permission determines if the user will be able to view/edit fields for the customer associated with this endpoint. <strong>This only applies to custom Endpoint fields with the appropriate User Mode.</strong></small></p>
                                    </ui.Form.Item>
                                    <ui.Form.Item label="Other User Fields">
                                        <ui.Select value={this.editUserOtherUserFieldPermissions} onChange={v => this.editUserOtherUserFieldPermissions = v}>
                                            {Object.keys(dtos.UserDataFieldModes).map(v => <ui.Select.Option key={v} value={v}>{splitIntoWords(v)}</ui.Select.Option>)}
                                        </ui.Select>
                                        <p style={{ margin: 0, padding: 0, marginBottom: -16 }}><small>This permission determines if the user will be able to view/edit fields for the other User endpoints associated with this endpoint's customer. <strong>This only applies to custom Customer fields with the appropriate User Mode.</strong></small></p>
                                    </ui.Form.Item>
                                    <ui.Form.Item label="Other Endpoint Fields">
                                        <ui.Select value={this.editUserOtherEndpointFieldPermissions} onChange={v => this.editUserOtherEndpointFieldPermissions = v}>
                                            {Object.keys(dtos.UserDataFieldModes).map(v => <ui.Select.Option key={v} value={v}>{splitIntoWords(v)}</ui.Select.Option>)}
                                        </ui.Select>
                                        <p style={{ margin: 0, padding: 0, marginBottom: -16 }}><small>This permission determines if the user will be able to view/edit fields for the other all other endpoints (e.g. Phone Numbers) associated with this endpoint's customer. <strong>This only applies to custom Endpoint fields with the appropriate User Mode.</strong></small></p>
                                    </ui.Form.Item>
                                    <ui.Form.Item>
                                        <SaveButton />
                                        <ui.Popconfirm title="Are you sure you want to delete this endpoint?" onConfirm={() => this.deleteEndpoint()}><a style={{ marginLeft: 12 }}>Delete Endpoint</a></ui.Popconfirm>
                                    </ui.Form.Item>
                                </ui.Form>
                            </ui.Tabs.TabPane>}

                        {this.endpoint.type == dtos.EndpointTypes.User && (this.editUserMode == dtos.UserModes.SoftPhone) &&
                            <ui.Tabs.TabPane key="app" tab="App">
                                <ui.Checkbox checked={this.editOverrideAppSettings} onChange={ev => this.editOverrideAppSettings = ev.target.checked}>Override App Settings</ui.Checkbox>
                                {this.editOverrideAppSettings && <AppSettingsEditor appSettings={this.editAppSettings} onChanged={s => this.editAppSettings = s} />}
                                <ui.Form.Item style={{ marginTop: 16 }}>
                                    <SaveButton />
                                </ui.Form.Item>
                            </ui.Tabs.TabPane>}
                        {this.endpoint.type == dtos.EndpointTypes.User && this.endpoint.userMode == dtos.UserModes.Sip &&
                            <ui.Tabs.TabPane key="sip" tab="SIP">
                                <ui.Form layout="vertical">
                                    <ui.Form.Item label="Emergency Number">
                                        <AutoComplete value={this.editEmergencyNumberId} onChanged={v => this.editEmergencyNumberId = v} placeholder="Select an emergency number" type={dtos.ValueTypes.PhoneNumber} />
                                        <p style={{ margin: 0, padding: 0, marginBottom: -16, marginTop: -8 }}><small><strong>IMPORTANT</strong> Please note that any time you change this setting, <strong>the phone's SIP domain will change</strong> and the phone will need to be reconfigured. User name and password will not change.</small></p>
                                    </ui.Form.Item>
                                    <ui.Form.Item label="SIP Region">
                                        <ui.Select value={this.editSipRegion} onChange={v => this.editSipRegion = v}>
                                            {Object.keys(dtos.TwilioSipRegions).map(r => <ui.Select.Option key={r} value={r}>{splitIntoWords(r)}</ui.Select.Option>)}
                                        </ui.Select>
                                        <p style={{ margin: 0, padding: 0, marginBottom: -16, marginTop: -8 }}><small><strong>NOTE</strong> Changing the SIP region will change your SIP Domain and you will have to reconfigure the phone if already configured.</small></p>

                                    </ui.Form.Item>
                                    <ui.Form.Item label="Domain">
                                        <ui.Input value={this.endpoint.sipDomain} disabled />
                                    </ui.Form.Item>
                                    <ui.Form.Item label="User Name">
                                        <ui.Input value={this.endpoint.sipUserName} disabled />
                                    </ui.Form.Item>
                                    <ui.Form.Item label="Password">
                                        <ui.Input.Password value={this.endpoint.sipPassword} readOnly />
                                        <ui.Popconfirm title="Are you sure you want to reset the SIP password?" onConfirm={() => this.regenerateSipCredentials()}><a>Reset Credentials</a></ui.Popconfirm>
                                    </ui.Form.Item>
                                    <ui.Form.Item>
                                        <SaveButton />
                                        <ui.Popconfirm title="Are you sure you want to delete this endpoint?" onConfirm={() => this.deleteEndpoint()}><a style={{ marginLeft: 12 }}>Delete Endpoint</a></ui.Popconfirm>
                                    </ui.Form.Item>
                                </ui.Form>
                            </ui.Tabs.TabPane>
                        }

                        {this.endpoint.type == dtos.EndpointTypes.User && this.endpoint.userMode == dtos.UserModes.SoftPhone &&
                            <ui.Tabs.TabPane key="contacts" tab="Contacts">
                                <ui.Form layout="vertical">
                                    <ui.Table dataSource={this.editContacts} rowKey="id" pagination={false}>
                                        <ui.Table.Column title="Display Name" dataIndex="displayName" render={(text, rec: dtos.EndpointContact) =>
                                            <ui.Input value={rec.displayName} placeholder="e.g. Patrick Greene" onChange={ev => { rec.displayName = ev.target.value; this.forceUpdate() }} />} />
                                        <ui.Table.Column title="Address" dataIndex="address" render={(text, rec: dtos.EndpointContact) =>
                                            <ui.Input value={rec.address} placeholder="e.g. 8144043093" onChange={ev => { rec.address = ev.target.value; this.forceUpdate() }} />} />
                                        <ui.Table.Column title="" render={(text, rec: dtos.EndpointContact) =>
                                            <ui.Popconfirm title="Remove this contact?" onConfirm={() => this.removeContact(rec)}><ui.Button size="small" danger><i className="fa fa-trash" /></ui.Button></ui.Popconfirm>
                                        } />
                                    </ui.Table>
                                    <ui.Form.Item>
                                        <ui.Button type="primary" onClick={() => this.newContact()}>New Contact</ui.Button>
                                    </ui.Form.Item>
                                    <ui.Form.Item>
                                        <SaveButton />
                                        <ui.Popconfirm title="Are you sure you want to delete this endpoint?" onConfirm={() => this.deleteEndpoint()}><a style={{ marginLeft: 12 }}>Delete Endpoint</a></ui.Popconfirm>
                                    </ui.Form.Item>
                                </ui.Form>
                            </ui.Tabs.TabPane>}
                        {this.endpoint.type != dtos.EndpointTypes.Team && this.endpoint.type !== dtos.EndpointTypes.Assistant && <ui.Tabs.TabPane key="sessions" tab="Sessions">
                            {this.endpoint != null && <Sessions endpointId={this.endpoint.id} accountId={this.endpoint.accountId} />}
                        </ui.Tabs.TabPane>}

                        <ui.Tabs.TabPane key="more" tab="More">
                            <ui.Form layout="vertical">
                                <ui.Collapse defaultActiveKey={[]} accordion>
                                    <ui.Collapse.Panel key="info" header="Info">
                                        <ui.Form.Item label="Reference ID">
                                            <ui.Input value={this.editReferenceId} onChange={ev => this.editReferenceId = ev.target.value} />
                                        </ui.Form.Item>
                                        <ui.Form.Item label="Last Modified">
                                            <ui.Input readOnly value={moment(this.endpoint.dateLastModified).format("LLLL") + ' by ' + this.endpoint.lastModifiedBy} />
                                        </ui.Form.Item>
                                    </ui.Collapse.Panel>
                                    {(this.endpoint.type == dtos.EndpointTypes.PhoneNumber || this.endpoint.type == dtos.EndpointTypes.FaxNumber) &&
                                        <ui.Collapse.Panel key="pn" header="Twilio/Documo">
                                            {this.endpoint.documoId && <ui.Form.Item label="Documo UUID">
                                                <ui.Input disabled value={this.endpoint.documoId} />
                                            </ui.Form.Item>}
                                            {!this.endpoint.documoId && <ui.Form.Item label="Sid">
                                                <ui.Input disabled value={this.endpoint.phoneNumberSid} />
                                            </ui.Form.Item>}
                                            {!this.endpoint.documoId && <ui.Form.Item>
                                                <ui.Button type="default" onClick={() => this.forceUpdateActionUrls()}>Fix URLs</ui.Button>
                                            </ui.Form.Item>}
                                        </ui.Collapse.Panel>}
                                    {this.endpoint.type == dtos.EndpointTypes.User && <ui.Collapse.Panel key="action" header="Action URLs">
                                        <ui.Table dataSource={this.actionUrls} rowKey="id" pagination={false}>
                                            <ui.Table.Column dataIndex="url" title="URL" render={(text, rec: dtos.EndpointActionUrl) =>
                                                <ui.Input placeholder="URL" value={rec.url} onChange={(ev) => { rec.url = ev.target.value; this.forceUpdate() }} />} />
                                            <ui.Table.Column dataIndex="method" title="HTTP Method" render={(text, rec: dtos.EndpointActionUrl) =>
                                                <ui.Select value={rec.method} onChange={v => { rec.method = v; this.forceUpdate() }}>
                                                    <ui.Select.Option value="GET">GET</ui.Select.Option><ui.Select.Option value="POST">POST</ui.Select.Option></ui.Select>} />
                                            <ui.Table.Column dataIndex="id" render={(text, rec: dtos.EndpointActionUrl) =>
                                                <ui.Button.Group>
                                                    <ui.Popconfirm title="Are you sure that you want to remove this action URL? This could break the console if you don't know what you are doing." onConfirm={() => this.removeActionUrl(rec)}><ui.Button danger><i className="fa fa-trash" /></ui.Button></ui.Popconfirm>
                                                </ui.Button.Group>} />
                                        </ui.Table>
                                        <ui.Button.Group style={{ marginTop: 8 }}>
                                            <ui.Button type="primary" onClick={() => this.newActionUrl()}>New Action URL</ui.Button>
                                        </ui.Button.Group>
                                    </ui.Collapse.Panel>}

                                </ui.Collapse>
                            </ui.Form>
                        </ui.Tabs.TabPane>
                    </ui.Tabs>
                }
                {this.showNewCustomer && <NewCustomer visible={this.showNewCustomer} onClosed={() => this.showNewCustomer = false} onCreated={c => this.customerId = c.id} />}

            </div >
        );
    }
}

export default withRouter(EndpointDetails);