import { React, dtos, ui, observer, observable, serviceClient, Observer, splitIntoWords, moveArray, makeObservable } from "../common";
import FlowUtil from "./util";
import FlowValueEditor from "./value-editor";

interface Props {
    accountId: string;
    customerId?: string;
    flowId: string;
    field: dtos.DataField;
    param: dtos.NodeParameter;
    rootType: dtos.DataType;
    depth: number;
    flowParameters?: Array<dtos.FlowParameter>;
    onFieldsChanged?: () => void;
    onTransitionRemoved?: (id: string) => void;
    valueSourceFields: dtos.GetParameterFieldNamesResponse;
    onNewFlowParam?: (param: dtos.NodeParameter, callback: (string) => void) => void;
}

@observer
export default class FlowParamEditor extends React.Component<Props> {
    @observable flows: Array<dtos.FlowInfo> = [];

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    isParameterOfSameType(valueType: dtos.ValueTypes, parameterType: dtos.ValueTypes) {
        if (valueType == dtos.ValueTypes.NotSpecified) return true;
        if (valueType == dtos.ValueTypes.File && parameterType == dtos.ValueTypes.AudioFile) return true;
        return valueType == parameterType;
    }

    getSourceParameters(source: dtos.ValueSources, type: dtos.ValueTypes = dtos.ValueTypes.NotSpecified, outputParams: dtos.DataField[] = null): Array<string> {
        var target = [];
        if (source == dtos.ValueSources.Value || source == dtos.ValueSources.Expression) {
            return [];
        } else if (source == dtos.ValueSources.Flow) {
            for (let field of this.props.flowParameters.filter(fp => this.isParameterOfSameType(type, fp.type))) {
                target.push(field.name);
                if (outputParams != null) outputParams.push(field);
            }
        } else {
            let list = this.props.valueSourceFields[source.toLowerCase()] as Array<dtos.GetParameterField>;
            for (let field of list.filter(f => type == dtos.ValueTypes.NotSpecified || f.valueType == type)) {
                target.push(field.fieldName);
                if (outputParams != null && field.field) outputParams.push(field.field);
            }
        }
        return target;
    }

    async loadFlows() {
        if (this.flows.length > 0) return;
        this.flows = (await serviceClient.get(new dtos.ListFlows({ all: true }))).items.filter(f => f.id != this.props.flowId);
    }

    createField(param: dtos.NodeParameter, field: dtos.DataField) {
        if (field.isOutput) {
            return this.outputField(param, field);
        } else {
            switch (field.type) {
                case dtos.ValueTypes.Transition:
                    return [];
                case dtos.ValueTypes.Struct:
                    return this.structField(param, field);
                case dtos.ValueTypes.List:
                    return this.listField(param, field);
                default:
                    return this.valueField(param, field);
            }
        }
    }

    onFieldsChanged() {
        if (this.props.onFieldsChanged) this.props.onFieldsChanged();
        this.forceUpdate();
    }

    setOutputField(param: dtos.NodeParameter, val: any) {
        if (val == "__NEW__") {
            if (this.props.onNewFlowParam == null) return;

            this.props.onNewFlowParam(param, fn => {
                // this.sourceParameterIds = this.getSourceParameters(param.source, param.type);
                param.value.stringValue = fn;
                this.forceUpdate();
            });
        } else {
            debugger;
            param.value.stringValue = val;
            this.onFieldsChanged();
            this.forceUpdate();
        }
    }

    setReferenceId(param: dtos.NodeParameter, refId: string) {
        if (refId == "__NEW__") {
            if (param.source != dtos.ValueSources.Flow) return;
            if (this.props.onNewFlowParam == null) return;
            this.props.onNewFlowParam(param, fn => {
                // this.sourceParameterIds = this.getSourceParameters(param.source, param.type);
                param.referenceId = fn;
                this.forceUpdate();
            });
        } else {
            param.referenceId = refId;
            this.forceUpdate();
        }
    }

    getParamSelect(param: dtos.NodeParameter) {
        return (
            <ui.Select value={param.referenceId} dropdownMatchSelectWidth={false} onChange={v => this.setReferenceId(param, v)}>
                <ui.Select.Option value="">(None)</ui.Select.Option>
                {this.getSourceParameters(param.source, param.type).map(p => <ui.Select.Option key={p} value={p}><strong>{this.getSourceName(param.source)}:</strong> {p}</ui.Select.Option>)}

                {param.source == dtos.ValueSources.Flow && this.props.onNewFlowParam != null && <ui.Select.Option value="__NEW__"><small><i className="fal fa-plus" /> New Flow Parameter</small></ui.Select.Option>}
            </ui.Select>
        );
    }

    setValueSource(param: dtos.NodeParameter, valueSource: dtos.ValueSources) {
        param.source = valueSource;
        param.referenceId = "";
        this.forceUpdate();
    }

    outputField(param: dtos.NodeParameter, field: dtos.DataField) {
        return (
            <ui.Form.Item key={param.id} label={this.getFieldLabel(field)}>
                <ui.Select dropdownMatchSelectWidth={false} value={param.value.stringValue} onChange={v => this.setOutputField(param, v)}>
                    <ui.Select.Option value="">(None)</ui.Select.Option>
                    {this.props.flowParameters.filter(p => p.type == field.type || field.anyValueType).map(p => <ui.Select.Option key={p.id} value={p.name}><strong>Flow Parameter:</strong> {p.name}</ui.Select.Option>)}
                    {this.props.onNewFlowParam != null && <ui.Select.Option value="__NEW__"><small><i className="fal fa-plus" /> New Flow Parameter</small></ui.Select.Option>}
                </ui.Select>
            </ui.Form.Item>
        );
    }

    structField(param: dtos.NodeParameter, field: dtos.DataField) {
        let structData = param.structParameters;
        let dataType = field.structType;

        const fields = <div className={field.uiHint == dtos.UIHints.InlineForm ? "ant-form ant-form-inline" : ""}>{dataType.fields.filter(f => FlowUtil.shouldShowField(f, structData, dataType.fields)).map(f => <div key={f.id}>{this.createField(structData[f.name], f)}</div>)}</div>;
        return field.uiHint === dtos.UIHints.InlineStruct ? fields : (
            <ui.Collapse key={param.id} style={{ marginBottom: 8 }} defaultActiveKey={[field.id]}>
                <ui.Collapse.Panel header={this.getFieldLabel(field)} key={field.id}>
                    {fields}
                </ui.Collapse.Panel>
            </ui.Collapse>
        );
    }

    listField(param: dtos.NodeParameter, field: dtos.DataField) {
        let list = param.listParameters;
        let dataType = field.listType;

        const addItem = () => {
            let structData = new dtos.NodeParameterMap();
            FlowUtil.initStruct(structData, dataType.fields, this.props.rootType);
            list.push(structData);
            this.forceUpdate();
            this.onFieldsChanged();
        };

        const removeItem = (structData) => {
            let transitions = FlowUtil.getTransitions(structData, dataType);
            for (let t of transitions) {
                if (t.param.value.stringValue && this.props.onTransitionRemoved) {
                    this.props.onTransitionRemoved(t.param.id);
                }
            }

            let idx = list.indexOf(structData);
            list.splice(idx, 1);
            this.forceUpdate();
            this.onFieldsChanged();
        }

        const getMenuItem = (valueSource: dtos.ValueSources) =>
            <ui.Menu.Item onClick={() => this.setValueSource(param, valueSource)}>{this.getSourceName(valueSource)}</ui.Menu.Item>;

        let popupMenu = <ui.Menu>
            {getMenuItem(dtos.ValueSources.Value)}
            <ui.Menu.Divider />
            {getMenuItem(dtos.ValueSources.Session)}
            {getMenuItem(dtos.ValueSources.Flow)}
            {getMenuItem(dtos.ValueSources.Customer)}
            {getMenuItem(dtos.ValueSources.Endpoint)}
            {getMenuItem(dtos.ValueSources.User)}
            {getMenuItem(dtos.ValueSources.System)}
        </ui.Menu>;

        let dropdown = <ui.Dropdown overlay={popupMenu}>
            <a>{this.getSourceName(param.source || dtos.ValueSources.Value)} <i className="fa fa-angle-down" /></a>
        </ui.Dropdown>;


        function LightenDarkenColor(col, amt) {
            var num = parseInt(col, 16);
            var r = (num >> 16) + amt;
            var b = ((num >> 8) & 0x00FF) + amt;
            var g = (num & 0x0000FF) + amt;
            var newColor = g | (b << 8) | (r << 16);
            return newColor.toString(16);
        }
        const bgColor = "#" + LightenDarkenColor("f1f1f1", this.props.depth * -20);

        return (
            <ui.Form.Item key={param.id} label={<ui.Popover placement="left" content={field.disableBinding ? <span>No Binding</span> : dropdown}>{this.getFieldLabel(field)}</ui.Popover>}>
                {param.source != dtos.ValueSources.Value && <div style={{ paddingLeft: 6, paddingRight: 6, paddingBottom: 6 }}>{this.getParamSelect(param)}</div>}
                {list.length > 0 && <ui.List dataSource={list} renderItem={(structData, index) =>
                    <div className={field.uiHint == dtos.UIHints.InlineForm ? "ant-form ant-form-inline" : ""}>
                        <ui.Form.Item className={dataType.fields.length == 1 ? "list-single-field" : ""} key={param.id + "." + index} label="" style={{ marginLeft: 10, marginRight: 10, paddingLeft: 10, paddingRight: 10, backgroundColor: bgColor }}>
                            {dataType.fields.filter(f => FlowUtil.shouldShowField(f, structData, dataType.fields)).map(f => <FlowParamEditor depth={this.props.depth + 1} rootType={this.props.rootType} onNewFlowParam={this.props.onNewFlowParam} onFieldsChanged={() => this.onFieldsChanged()} key={f.id} field={f} param={structData[f.name]} accountId={this.props.accountId} flowId={this.props.flowId} flowParameters={this.props.flowParameters} valueSourceFields={this.props.valueSourceFields} />)}
                            <ui.Row>
                                <ui.Button.Group>
                                    <ui.Popconfirm title="Remove item?" onConfirm={() => removeItem(structData)}><ui.Button danger>Remove</ui.Button></ui.Popconfirm>
                                </ui.Button.Group>
                                <ui.Button.Group style={{ marginLeft: 8 }}>
                                    <ui.Button onClick={() => moveArray(this, list, structData, -1)} disabled={index === 0}><i className="fa fa-arrow-up" /></ui.Button>
                                    <ui.Button onClick={() => moveArray(this, list, structData, +1)} disabled={index >= list.length - 1}><i className="fa fa-arrow-down" /></ui.Button>
                                </ui.Button.Group>
                            </ui.Row>
                        </ui.Form.Item>
                    </div>} />}
                <ui.Button.Group>
                    <ui.Button onClick={addItem}>Add Item</ui.Button>
                </ui.Button.Group>
            </ui.Form.Item >
        );
    }

    getFieldLabel(field: dtos.DataField) {
        var tokens = [];
        if (field.voiceOnly) {
            tokens.push(<span key="voiceOnly">, <strong>Voice</strong></span>);
        }

        if (field.description == null || field.description.length == 0) {
            return <span>{field.isOutput ? <strong>Output </strong> : []} {splitIntoWords(field.name)} <small>({splitIntoWords(field.type)}{tokens})</small></span>;
        }

        return <span><ui.Tooltip title={field.description}><span style={{ borderBottom: "1px dashed #d9d9d9", cursor: "pointer" }}>{field.isOutput ? <strong>Output</strong> : []} {splitIntoWords(field.name)}</span></ui.Tooltip> <small>({splitIntoWords(field.type)}{tokens})</small></span>;
    }

    getSourceName(valueSource: dtos.ValueSources) {
        if (valueSource == dtos.ValueSources.Value) return "Specific Value";
        if (valueSource == dtos.ValueSources.Expression) return "Expression";
        if (valueSource == dtos.ValueSources.Customer) return "Customer Data";
        if (valueSource == dtos.ValueSources.Endpoint) return "Endpoint Data";
        if (valueSource == dtos.ValueSources.Session) return "Session Parameter";
        if (valueSource == dtos.ValueSources.System) return "System Setting";
        if (valueSource == dtos.ValueSources.Flow) return "Flow Parameter";
        if (valueSource == dtos.ValueSources.User) return "User";

        return "Unknown";
    }

    getExpressionMenuItem(param: dtos.NodeParameter, source: dtos.ValueSources, title: string) {
        let fields = this.getSourceParameters(source);
        if (fields.length == 0) return [];
        let onClick = (fieldName) => {
            if (param.expression.length > 0) {
                param.expression += " ";
            }
            param.expression += "{{" + source.toLowerCase() + "." + fieldName + "}}";
            this.forceUpdate();
        };

        let menu = <ui.Menu>{fields.map(f => <ui.Menu.Item onClick={() => onClick(f)} key={f}>{f}</ui.Menu.Item>)}</ui.Menu>;
        return <ui.Dropdown overlay={menu}><a style={{ marginRight: 15 }} className="ant-dropdown-link">{title} <i className="fa fa-angle-down" /></a></ui.Dropdown>;
    }

    getExpressionMenu(param: dtos.NodeParameter) {
        return (
            <div>
                {this.getExpressionMenuItem(param, dtos.ValueSources.System, "System")}
                {this.getExpressionMenuItem(param, dtos.ValueSources.Session, "Session")}
                {this.getExpressionMenuItem(param, dtos.ValueSources.Customer, "Customer")}
                {this.getExpressionMenuItem(param, dtos.ValueSources.Endpoint, "Endpoint")}
                {this.getExpressionMenuItem(param, dtos.ValueSources.Flow, "Flow")}
                {this.getExpressionMenuItem(param, dtos.ValueSources.User, "User")}
            </div>
        );
    }

    getExpressionEditor(param: dtos.NodeParameter, field: dtos.DataField) {
        return (
            <div>
                <ui.Input.TextArea value={param.expression || ""} onChange={ev => { param.expression = ev.target.value; this.forceUpdate(); }} />
            </div>
        );
    }

    valueField(param: dtos.NodeParameter, field: dtos.DataField) {
        const getMenuItem = (valueSource: dtos.ValueSources) =>
            <ui.Menu.Item onClick={() => this.setValueSource(param, valueSource)}>{this.getSourceName(valueSource)}</ui.Menu.Item>;

        let popupMenu = <ui.Menu>
            {getMenuItem(dtos.ValueSources.Value)}
            <ui.Menu.Divider />
            {param.type == dtos.ValueTypes.String && getMenuItem(dtos.ValueSources.Expression)}
            {param.type == dtos.ValueTypes.String && <ui.Menu.Divider />}
            {getMenuItem(dtos.ValueSources.Session)}
            {getMenuItem(dtos.ValueSources.Flow)}
            {getMenuItem(dtos.ValueSources.Customer)}
            {getMenuItem(dtos.ValueSources.Endpoint)}
            {getMenuItem(dtos.ValueSources.User)}
            {getMenuItem(dtos.ValueSources.System)}
        </ui.Menu>;

        let dropdown = <ui.Dropdown overlay={popupMenu}>
            <a>{this.getSourceName(param.source)} <i className="fa fa-angle-down" /></a>
        </ui.Dropdown>;

        let value = <div>
            {param.source == dtos.ValueSources.Value && <FlowValueEditor value={param.value} customerId={this.props.customerId} valueType={param.type} field={field} onFieldChanged={() => this.onFieldsChanged()} accountId={this.props.accountId} />}
            {param.source == dtos.ValueSources.Expression && this.getExpressionEditor(param, field)}
            {param.source != dtos.ValueSources.Value && param.source != dtos.ValueSources.Expression && this.getParamSelect(param)}
        </div>;

        return (
            <ui.Form.Item key={param.id} label={this.getFieldLabel(field)}>
                {field.type != dtos.ValueTypes.Custom && !field.disableBinding && <ui.Popover placement="left" content={dropdown}>
                    {value}
                </ui.Popover>}
                {(field.type == dtos.ValueTypes.Custom || field.disableBinding) && value}
                {param.source == dtos.ValueSources.Expression && this.getExpressionMenu(param)}
            </ui.Form.Item >
        );
    }

    render() {
        let { field, param } = this.props;
        return this.createField(param, field);
    }
}