import { React, dtos, serviceClient, observable, observer, ui, moment, makeObservable } from "../common";
import errorCodes from "./twilio-error-codes";

interface Props {
    accountId: string;
}

@observer
export default class DashboardAlerts extends React.Component<Props> {
    @observable accountId = "";
    @observable startDate = moment().startOf("day");
    @observable endDate = moment().endOf("day");
    @observable alerts: Array<dtos.AlertInfo> = [];
    @observable loading = false;

    constructor(props) {
        super(props);
        makeObservable(this);
    }
    
    componentDidMount() {
        this.accountId = this.props.accountId;
        this.refresh();
    }

    componentDidUpdate(prevProps) {
        if (this.props.accountId != prevProps.accountId) {
            this.accountId = this.props.accountId;
        }
    }

    setDateRange(range) {
        this.startDate = range[0];
        this.endDate = range[1];
    }

    async refresh() {
        try {
            this.loading = true;
            this.alerts = await serviceClient.get(new dtos.ListAlerts({
                accountId: this.accountId,
                startDate: this.startDate.format("YYYY-MM-DD"),
                endDate: this.endDate.format("YYYY-MM-DD")
            }));
        } finally {
            this.loading = false;
        }
    }
    getErrorDescription(rec: dtos.AlertInfo) {
        for (var i = 0; i < errorCodes.length; i++) {
            if (errorCodes[i].code == parseInt(rec.errorCode)) {
                return errorCodes[i].message;
            }
        }
        return "Unknown";
    }

    getErrorContent(rec: dtos.AlertInfo) {
        for (var i = 0; i < errorCodes.length; i++) {
            if (errorCodes[i].code == parseInt(rec.errorCode)) {
                return <div dangerouslySetInnerHTML={{ __html: errorCodes[i].secondary_message }}></div>;
            }
        }
        return <span />;
    }

    render() {
        return (
            <div>
                <ui.Form layout="inline" style={{ marginBottom: 8 }}>
                    <ui.Form.Item>
                        <ui.DatePicker.RangePicker value={[this.startDate, this.endDate]} onChange={ev => this.setDateRange(ev)} />
                    </ui.Form.Item>
                    
                    <ui.Form.Item>
                        <ui.Button.Group>
                            <ui.Button type="primary" onClick={() => this.refresh()}>Search</ui.Button>
                        </ui.Button.Group>
                    </ui.Form.Item>
                </ui.Form>
                <ui.Table dataSource={this.alerts} rowKey="sid" loading={this.loading}>
                    <ui.Table.Column dataIndex="dateGenerated" title="Date" render={(text, rec) => moment(text).format("LLL")} />
                    <ui.Table.Column dataIndex="errorCode" title="Error Code" render={(text, rec: dtos.AlertInfo) => <ui.Popover title={this.getErrorDescription(rec)} content={this.getErrorContent(rec)}><span style={{ cursor:"pointer", borderBottom: "1px dotted black" }}>{text}</span></ui.Popover>} />
                    <ui.Table.Column dataIndex="logLevel" title="Log Level" />
                    <ui.Table.Column dataIndex="moreInfo" title="More Info" render={(text, rec) => text && <a href={text} target="_blank">{text}</a>} />
                    <ui.Table.Column dataIndex="requestUrl" title="URL" render={(text, rec) => <span title={text} style={{ display: "inline-block", width: 300, overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{text}</span>}  />
                    <ui.Table.Column dataIndex="requestMethod" title="Method" />
                    <ui.Table.Column dataIndex="requestVariables" title="Variables" />
                </ui.Table>
            </div>
        );
    }
}